export const initColumns = [
    {
      id: 'no',
      label: 'No.',
      minWidth: 30,
    },
    {
      id: 'image',
      label: 'Image',
      minWidth: 80,
    },
    {
      id: 'title_th',
      label: 'Name (TH)',
      minWidth: 100,
    },
    {
      id: 'title_en',
      label: 'Name (EN)',
      minWidth: 100,
    },
    {
      id: 'instructor_th',
      label: 'Instructor (TH)',
      minWidth: 120,
    },
    {
      id: 'instructor_en',
      label: 'Instructor (EN)',
      minWidth: 120,
    },
    {
      id: 'description_th',
      label: 'Description (TH)',
      minWidth: 150,
    },
    {
      id: 'description_en',
      label: 'Description (EN)',
      minWidth: 150,
    },
    {
      id: 'action',
      label: '',
      minWidth: 80,
    },
  ]
 export const groupDefault = 'DEFAULT'
 export const initPaging = (gn) => {
    return {
    title: '',
    instructor: '',
    page: 1,
    pageSize: 5,
    group_name: gn || groupDefault
}}

 export const txtProps = {
    size: 'small',
    fullWidth: true,
    sx: { backgroundColor: '#fff' },
  }
