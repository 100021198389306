import React, { useState, useEffect } from 'react'
import { useBoUserRedemptionStore } from 'stores/BackOffice/boUserRedemption'
import { fDateTime, fISODateToUTC, fUTCDateToIsoStart } from 'utils/formatTime'
import { rowPerPageOptions } from 'constant/boPagination'

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Button,
  TextField,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import userRedemptionApi from 'api/userRedemptionApi'
import Swal from 'sweetalert2'
import ExportBtn from 'components/ExportBtn'

const initColumn = [
  {
    id: 'no',
    label: 'No.',
    minWidth: 30,
  },
  {
    id: 'rewardNameTH',
    label: 'Reward Name (TH)',
    minWidth: 180,
  },
  {
    id: 'rewardNameEN',
    label: 'Reward Name (EN)',
    minWidth: 180,
  },
  {
    id: 'userName',
    label: 'User Name',
    minWidth: 190,
  },
  {
    id: 'email',
    label: 'User Email',
    minWidth: 200,
  },
  {
    id: 'sent',
    label: 'Sent',
    minWidth: 50,
  },
  {
    id: 'createdAt',
    label: 'Request Date',
    minWidth: 150,
  },
]

const initPaging = {
  start: '',
  end: '',
  page: 1,
  pageSize: 5,
}

const txtProps = {
  size: 'small',
  fullWidth: true,
  sx: { backgroundColor: '#fff' },
}

export default function BORewardRedemtionPage() {
  const [existingSentIds, setExistingSentIds] = useState([])
  const [sentIdValues, setSentIdValues] = useState({})
  const [userRedemptions, pagination, fetchUserRedemptions, batchUpdateSent] =
    useBoUserRedemptionStore((state) => [
      state.userRedemptions,
      state.pagination,
      state.fetchUserRedemptions,
      state.batchUpdateSent,
    ])

  const [paging, setPaging] = useState(initPaging)

  useEffect(() => {
    onFetchUserRedemptions(initPaging)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const sentIds = (userRedemptions || [])
      .filter((ur) => ur.has_sent)
      .map((ur) => ur.id)

    setExistingSentIds(sentIds)

    const values = {}
    for (const ur of userRedemptions) {
      values[ur.id] = ur.has_sent
    }

    setSentIdValues(values)
    return () => {}
  }, [userRedemptions])
  const [exportData, setExportData] = useState([])
  const [userNameSearch, setUserNameSearch] = useState("")
  const [userMailSearch, setUserMailSearch] = useState("")
  const [rewardName, setRewardName] = useState("")
  const [startSearch, setStartSearch] = useState("")
  const [endSearch, setEndSearch] = useState("")
  useEffect(() => {
    onFetchExport()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userNameSearch,userMailSearch,rewardName,startSearch,endSearch,initPaging])
  const onFetchExport = async () => {
    const reqExport = {
      pageSize: 9999
    }
    if(userNameSearch && userNameSearch !== ""){
      reqExport.user_name = userNameSearch
    }
    if(userMailSearch && userMailSearch !== ""){
      reqExport.user_email = userMailSearch
    }
    if(rewardName && rewardName !== ""){
      reqExport.reward_name = rewardName
    }
    if(startSearch && startSearch !== ""){
      reqExport.start = fUTCDateToIsoStart(startSearch)
    }
    if(endSearch && endSearch !== ""){
      reqExport.end = fUTCDateToIsoStart(endSearch)
    }
    const res = await userRedemptionApi.getUserRedemptions(reqExport)
    setExportData(res?.data?.data)
  }
  const onFetchUserRedemptions = async (newPaging) => {
    // showLoading()
    const result = await fetchUserRedemptions(newPaging)
    if (result) {
      setPaging(newPaging)
    } else {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
    // hideLoading()
  }

  const handleCriteriaChange = (name, value) => {
    switch(name){
      case "user_name": setUserNameSearch(value)
                      break;
      case "user_email": setUserMailSearch(value)
                      break;
      case "reward_name": setRewardName(value)
                      break;
      case "start": setStartSearch(value)
                      break;
      case "end": setEndSearch(value)
                      break;
      default: break;
    }
    setPaging({
      ...paging,
      [name]: value,
    })
  }

  const onSearchClick = async () => {
    await onFetchUserRedemptions({
      ...paging,
      page: 1,
    })
  }

  const onClearClick = async () => {
    await onFetchUserRedemptions(initPaging)
  }

  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchUserRedemptions({
      ...paging,
      page: newPage + 1,
    })
  }

  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchUserRedemptions({
      ...paging,
      page: 1,
      pageSize: +e.target.value,
    })
  }

  const onSaveClick = async () => {
    const sentIds = Object.entries(sentIdValues)
      .filter(([_, value]) => value)
      .filter(([id]) => !existingSentIds.includes(id))
      .map(([id]) => parseInt(id))

    // showLoading()

    const response = await batchUpdateSent({
      ids: sentIds,
      has_sent: true,
    })

    if (response) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Update reward sent success',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onFetchUserRedemptions(paging)
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
    // hideLoading()
  }

  return (
    <>
      <Grid container className="mb-20">
        <Grid item md={12}>
          <h2>Reward redemption List</h2>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-16">
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            {...txtProps}
            id="reward-redemption-search-userName"
            label="User Name"
            value={paging.user_name || ''}
            onChange={(e) => handleCriteriaChange('user_name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            {...txtProps}
            id="reward-redemption-search-userEmail"
            label="User Email"
            value={paging.user_email || ''}
            onChange={(e) => handleCriteriaChange('user_email', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            {...txtProps}
            id="reward-redemption-search-rewardName"
            label="Reward Name (TH/EN)"
            value={paging.reward_name || ''}
            onChange={(e) =>
              handleCriteriaChange('reward_name', e.target.value)
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="reward-redemption-select-startDate"
              disableFuture
              inputFormat="dd/MM/yyyy"
              label="Request Start Date"
              value={paging.start || null}
              onChange={(value) => handleCriteriaChange('start', value)}
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="reward-redemption-select-endDate"
              disableFuture
              inputFormat="dd/MM/yyyy"
              label="Request End Date"
              value={paging.end || null}
              onChange={(value) => handleCriteriaChange('end', value)}
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="reward-redemption-btn-search"
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<SearchIcon />}
            onClick={() => onSearchClick()}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="reward-redemption-btn-clear"
            variant="contained"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={() => onClearClick()}
          >
            Clear
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <ExportBtn dataFile={exportData} pageId={'reward-redemption'}></ExportBtn>
        </Grid>
      </Grid>

      <Grid container spacing={0} className="mb-30">
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {initColumn.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {userRedemptions?.length > 0 && (
                <TableBody>
                  {(userRedemptions || []).map((data, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {(pagination.page - 1) * pagination.limit + (i + 1)}
                        </TableCell>
                        <TableCell>{data.reward_name_th}</TableCell>
                        <TableCell>{data.reward_name_en}</TableCell>
                        <TableCell>{data.user_full_name}</TableCell>
                        <TableCell>{data.user_email}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={sentIdValues[data.id] || false}
                            disabled={existingSentIds.includes(data.id)}
                            onChange={({ target }) => {
                              setSentIdValues((prevState) => ({
                                ...prevState,
                                [data.id]: target.checked,
                              }))
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {data.created_at ? (
                            fDateTime(fISODateToUTC(data.created_at))
                          ) : (
                            <Typography
                              variant="h6"
                              style={{ textAlign: 'center' }}
                            >{`-`}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={pagination.total_count}
            rowsPerPage={paging.pageSize}
            page={pagination.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>

      <Grid container direction="row" justifyContent="flex-end">
        <Grid item xs={6} sm={3}>
          <Button
            id="reward-redemption-btn-save"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => onSaveClick()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
