import { useState, useEffect } from 'react'
import { rowPerPageOptions, initPagination } from 'constant/boPagination'
import reservationApi from 'api/inspirationReservationApi'
import { useLoading } from 'components/Loading/context'
import {
  TablePagination,
  TableRow,
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import Swal from 'sweetalert2'
import {
  fDate,
  fDateTime,
  fISODateToUTC,
  fUTCDateToIsoStart,
  fUTCDateToIsoEnd,
} from 'utils/formatTime'
import ExportBtn from 'components/ExportBtn'
import { useBoWhitelistStore } from 'stores/BackOffice/boWhiteList'
import { initPaging, txtProps, initColumns } from './defaultValDyRequest'
const CommonRequestsPage = () => {
  const { showLoading, hideLoading } = useLoading()
  const [paging, setPaging] = useState(initPaging)
  const [data, setData] = useState({
    reservations: [],
    pagination: initPagination,
  })
  let pathname = window.location.pathname;
  pathname = pathname.substring(7)
  pathname = pathname.substring(0,pathname.indexOf("/")).toUpperCase()
  const [ groupList, fetchUserGroupList] = useBoWhitelistStore((state) => [state.groupList, state.fetchUserGroupList])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect( () => {
    setTimeout(()=>{
      onFetchData(initPaging(groupList?.data?.find(e => e.group_name === pathname)?.id))
      return () => {}
    },500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  const [exportData, setExportData] = useState([])
  const [userNameSearch, setUserNameSearch] = useState("")
  const [userMailSearch, setUserMailSearch] = useState("")
  const [ctitileSearch, setCTitleSearch] = useState("")
  const [eDateSearch, setEDateSearch] = useState("")
  const [reqDateStartSearch, setReqDateStartSearch] = useState("")
  const [reqDateEndSearch, setReqDateEndSearch] = useState("")
  useEffect(() => {
    setTimeout(()=>{
      onFetchExport()
      return () => {};
    },500)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userNameSearch,userMailSearch,ctitileSearch,eDateSearch,reqDateStartSearch,reqDateEndSearch,pathname])
  const onFetchExport = async () => {
    const reqExport = {
      status: '', pageSize: 9999,
      group_id: groupList?.data?.find(e => e.group_name === pathname)?.id
    }
    if(userNameSearch && userNameSearch !== ""){
      reqExport.user_name = userNameSearch
    }
    if(userMailSearch && userMailSearch !== ""){
      reqExport.user_email = userMailSearch
    }
    if(ctitileSearch && ctitileSearch !== ""){
      reqExport.course_title = ctitileSearch
    }
    if(eDateSearch && eDateSearch !== ""){
      reqExport.event_date = fUTCDateToIsoStart(eDateSearch)
    }
    if(reqDateStartSearch && reqDateStartSearch !== ""){
      reqExport.request_date_start = fUTCDateToIsoStart(reqDateStartSearch)
    }
    if(reqDateEndSearch && reqDateEndSearch !== ""){
      reqExport.request_date_end = fUTCDateToIsoStart(reqDateEndSearch)
    }
    const res = await reservationApi.getReservations(reqExport)
    setExportData(res?.data?.data)
  }
  const onFetchData = async (newPaging) => {
    showLoading()
    let reservationDatas = []
    let paginationData = initPagination
    await fetchUserGroupList()
    try {
      const response = await reservationApi.getReservations({
        ...newPaging,
        event_date: fUTCDateToIsoStart(newPaging.event_date),
        request_date_start: fUTCDateToIsoStart(newPaging.request_date_start),
        request_date_end: fUTCDateToIsoEnd(newPaging.request_date_end),
      })
      reservationDatas = response.data.data
      paginationData = response.data.pagination
      setPaging(newPaging)
    } catch (ex) {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      setData({
        reservations: reservationDatas,
        pagination: paginationData,
      })
      hideLoading()
    }
  }
  const onCriteriaChange = (name, value) => {
    switch(name){
      case "user_name": setUserNameSearch(value)
                      break;
      case "user_email": setUserMailSearch(value)
                      break;
      case "course_title": setCTitleSearch(value)
                      break;
      case "event_date": setEDateSearch(value)
                      break;
      case "request_date_start": setReqDateStartSearch(value)
                      break;
      case "request_date_end": setReqDateEndSearch(value)
                      break;
      default: break;
    }
    setPaging({
      ...paging,
      [name]: value,
    })
  }
  const onSearchClick = async (e) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: 1,
    })
  }
  const onClearClick = async (e) => {
    e.preventDefault()
    await onFetchData(initPaging)
  }
  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: newPage + 1,
    })
  }
  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: 1,
      pageSize: +e.target.value,
    })
  }
  useEffect(() => {
    setData({
      reservations: [],
      pagination: initPagination,
    })
    return () => {};
  }, []);
  return (<>
      <Grid container className="mb-20">
        <Grid item>
          <h2>{pathname.replaceAll("_"," ")} Request List</h2>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-16">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            id="emi-request-search-userName"
            label="User Name"
            value={paging.user_name || ''}
            onChange={(e) => onCriteriaChange('user_name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            id="emi-request-search-userEmail"
            label="User Email"
            value={paging.user_email || ''}
            onChange={(e) => onCriteriaChange('user_email', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            id="emi-request-search-courseName"
            label="Course Name (TH/EN)"
            value={paging.course_title || ''}
            onChange={(e) => onCriteriaChange('course_title', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="inspiration-request-select-eventDate"
              inputFormat="dd/MM/yyyy"
              label="Event Date"
              value={paging.event_date || null}
              onChange={(value) => onCriteriaChange('event_date', value)}
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="emi-request-select-startDate"
              inputFormat="dd/MM/yyyy"
              label="Request Start Date"
              value={paging.request_date_start || null}
              onChange={(value) =>
                onCriteriaChange('request_date_start', value)
              }
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="emi-request-select-endDate"
              inputFormat="dd/MM/yyyy"
              label="Request End Date"
              value={paging.request_date_end || null}
              onChange={(value) => onCriteriaChange('request_date_end', value)}
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="emi-request-btn-search"
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<SearchIcon />}
            onClick={onSearchClick}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="emi-request-btn-clear"
            variant="contained"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={onClearClick}
          >Clear
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <ExportBtn dataFile={exportData} pageId={'emi-request'} />
        </Grid>
      </Grid>
      <Grid container>
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {initColumns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(data.reservations || []).map((item, i) => {
                  return (
                    <TableRow hover key={i}>
                      <TableCell>
                        {(data.pagination.page - 1) * data.pagination.limit +
                          (i + 1)}
                      </TableCell>
                      <TableCell>{item.course_title_th}</TableCell>
                      <TableCell>{item.course_title_en}</TableCell>
                      <TableCell>
                        {fDate(fISODateToUTC(item.event_date))}
                      </TableCell>
                      <TableCell>{item.event_time}</TableCell>
                      <TableCell>{item.user_full_name}</TableCell>
                      <TableCell>{item.user_email}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>
                        {fDateTime(fISODateToUTC(item.created_at))}
                      </TableCell>
                    </TableRow>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={data.pagination.total_count}
            rowsPerPage={paging.pageSize}
            page={data.pagination.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>
    </>)
}
export default CommonRequestsPage
