import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import  routePaths  from 'routesPath'
import { Grid, Paper, Button, TextField } from '@mui/material'

const errorLabel = {
  image: 'Please fill in Image',
  title_th: 'Please fill in Title TH',
  title_en: 'Please fill in Title EN',
  instructor_th: 'Please fill in Instructor TH',
  instructor_en: 'Please fill in Instructor EN',
  description_th: 'Please fill in Description TH',
  description_en: 'Please fill in Description EN',
}
const groupEmi = 'EMI'
const groupEmiId = 2
const defaultGroupId = 1

const CourseForm = ({ mode, course, onSave }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState({})
  const [inputData, setInputData] = useState({
    image: '',
    title_th: '',
    title_en: '',
    description_th: '',
    description_en: '',
    instructor_th: '',
    instructor_en: '',
  })
  const searchParams = new URLSearchParams(location.search);
  const groupName = searchParams.get('group_name')
  useEffect(() => {
    if (course) {
      setInputData(course)
    }
  }, [course])

  const onInputChange = (key, value) => {
    setInputData({
      ...inputData,
      group_id: groupName === groupEmi ? groupEmiId : defaultGroupId ,
      [key]: value,
    })
  }

  const onSaveClick = async (e) => {
    e.preventDefault()
    if (!validateInput()) {
      onSave(inputData)
    }
  }

  const onCancelClick = (e) => {

    if(searchParams && groupName === groupEmi) {
      e.preventDefault()
      navigate(routePaths.BO_EMI_COURSE)
    } else {
      e.preventDefault()
      navigate(routePaths.BO_INSPIRATION_COURSE)
    }
  }

  const validateInput = () => {
    let isError = false
    let newError = {}

    Object.entries(inputData).forEach((fields) => {
      const errorText = errorLabel[fields[0]]
      if ((!fields[1] || fields[1] === '') && errorText && errorText !== '') {
        newError = {
          ...newError,
          [fields[0]]: errorLabel[fields[0]],
        }
        isError = true
      }
    })

    setError(newError)
    return isError
  }

  return (
    <div data-testid="course-form" mode={`${mode}`} course={`${course}`}>
      <Grid container className="mb-20">
        <Grid item>
          {groupName === groupEmi ?<h2>{mode} Emi Course</h2> :  <h2>{mode} Inspiration Hour Course</h2> }
        </Grid>
      </Grid>

      <Grid container>
        <Paper elevation={3} sx={{ p: 4, width: '1136px', overflow: 'hidden' }}>
          <Grid container spacing={2} className="mb-16">
            <Grid item xs={12} md={12}>
              <TextField
                id="courseform-input-image"
                data-testid="courseform-input-image"
                fullWidth
                size="small"
                label="Image *"
                value={inputData?.image || ''}
                onChange={(e) => onInputChange('image', e.target.value)}
                error={!!error.image}
                helperText={error.image}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-16">
            <Grid item xs={12} md={6}>
              <TextField
                id="courseform-input-titleTH"
                data-testid="courseform-input-titleTH"
                fullWidth
                size="small"
                label="Name (TH) *"
                value={inputData?.title_th || ''}
                onChange={(e) => onInputChange('title_th', e.target.value)}
                error={!!error.title_th}
                helperText={error.title_th}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="courseform-input-titleEN"
                fullWidth
                size="small"
                label="Name (EN) *"
                value={inputData?.title_en || ''}
                onChange={(e) => onInputChange('title_en', e.target.value)}
                error={!!error.title_en}
                helperText={error.title_en}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-16">
            <Grid item xs={12} md={6}>
              <TextField
                id="courseform-input-instructonTH"
                fullWidth
                size="small"
                label="Instructor (TH) *"
                value={inputData?.instructor_th || ''}
                onChange={(e) => onInputChange('instructor_th', e.target.value)}
                error={!!error.instructor_th}
                helperText={error.instructor_th}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="courseform-input-instructorEN"
                fullWidth
                size="small"
                label="Instructor (EN) *"
                value={inputData?.instructor_en || ''}
                onChange={(e) => onInputChange('instructor_en', e.target.value)}
                error={!!error.instructor_en}
                helperText={error.instructor_en}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-30">
            <Grid item xs={12} md={6}>
              <TextField
                id="courseform-input-descriptionTH"
                fullWidth
                size="small"
                label="Description (TH) *"
                multiline
                rows={3}
                value={inputData?.description_th || ''}
                onChange={(e) =>
                  onInputChange('description_th', e.target.value)
                }
                error={!!error.description_th}
                helperText={error.description_th}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="courseform-input-descriptionEN"
                fullWidth
                size="small"
                label="Description (EN) *"
                multiline
                rows={3}
                value={inputData?.description_en || ''}
                onChange={(e) =>
                  onInputChange('description_en', e.target.value)
                }
                error={!!error.description_en}
                helperText={error.description_en}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3}>
              <Button
                id="courseform-btn-save"
                variant="contained"
                fullWidth
                onClick={onSaveClick}
              >
                save
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                id="courseform-btn-cancel"
                variant="outlined"
                fullWidth
                onClick={onCancelClick}
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}

export default CourseForm
