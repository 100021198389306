import React, { useEffect } from 'react'

import FooterLayout from 'layout/Web/FooterLayout'
import {
  WrapperAppLayout,
  WrapperBody
} from 'layout/Web/AppLayout.style'
import { useTranslation } from 'react-i18next'
import { getStorageLang } from 'utils/common'

function AppLayout(props) {
  const { i18n } = useTranslation()
  const lng = getStorageLang();

  useEffect(() => {
    if (i18n) {
      i18n.changeLanguage(lng);
    }
  }, [i18n, lng])

  return (
    <WrapperAppLayout>
      <WrapperBody>
        <div>{props.children}</div>
      </WrapperBody>
      <FooterLayout />
    </WrapperAppLayout>
  )
}

export default AppLayout
