import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import  routePaths  from 'routesPath'
import { useLoading } from 'components/Loading/context'
import {
  Grid,
  Paper,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useBoInspirationWorkshopStore } from 'stores/BackOffice/boInspirationWorkshop'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SessionDialog from './SessionDialog'

const initColumns = [
  {
    id: 'no',
    label: 'No.',
    minWidth: 30,
  },
  {
    id: 'time',
    label: 'Time',
    minWidth: 80,
  },
  {
    id: 'quota',
    label: 'Quota',
    minWidth: 100,
  },
  {
    id: 'action',
    label: '',
    minWidth: 80,
  },
]

const errorLabel = {
  course_id: 'Please fill in Course',
  image: 'Please fill in Image',
  title_th: 'Please fill in Title TH',
  title_en: 'Please fill in Title EN',
  instructor_th: 'Please fill in Instructor TH',
  instructor_en: 'Please fill in Instructor EN',
  points: 'Please fill in Points',
  event_date: 'Please fill in Event Date',
}

const WorkshopForm = ({ mode, onSave }) => {
  const navigate = useNavigate()
  const { showLoading, hideLoading } = useLoading()
  const [workshop, courses, fetchCourses, setWorkshop, deleteSession] =
    useBoInspirationWorkshopStore((state) => [
      state.workshop,
      state.courses,
      state.fetchCourses,
      state.setWorkshop,
      state.deleteSession,
    ])
  const [error, setError] = useState({})
  const [isShowSessionDialog, setIsShowSessionDialog] = useState(false)
  const [selectSession, setSelectSession] = useState(null)

  useEffect(() => {
    onFetchCourses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFetchCourses = async () => {
    showLoading()
    await fetchCourses()
    hideLoading()
  }

  const onInputChange = (key, value) => {
    setWorkshop(key, value)
  }

  const onSaveClick = async (e) => {
    e.preventDefault()
    if (!validateInput()) {
      onSave(workshop)
    }
  }

  const onCancelClick = (e) => {
    e.preventDefault()
    navigate(routePaths.BO_INSPIRATION_WORKSHOP)
  }

  const validateInput = () => {
    let isError = false
    let newError = {}

    Object.entries(workshop).forEach((fields) => {
      const errorText = errorLabel[fields[0]]
      if ((!fields[1] || fields[1] === '') && errorText && errorText !== '') {
        newError = {
          ...newError,
          [fields[0]]: errorLabel[fields[0]],
        }
        isError = true
      }
    })

    setError(newError)
    return isError
  }

  const onAddSessionClick = (e) => {
    e.preventDefault()
    setSelectSession({
      event_time: '',
      quota: '',
    })
    setIsShowSessionDialog(true)
  }

  const onEditSessionClick = (e, session) => {
    e.preventDefault()
    setSelectSession(session)
    setIsShowSessionDialog(true)
  }

  const onDeleteSessionClick = (e, session) => {
    e.preventDefault()
    deleteSession(session)
  }

  return (
    <>
      <Grid container className="mb-20">
        <Grid item>
          <h2>{mode} Inspiration Hour Workshop</h2>
        </Grid>
      </Grid>

      <Grid container>
        <Paper elevation={3} sx={{ p: 4, width: '1136px', overflow: 'hidden' }}>
          <Grid container spacing={2} className="mb-16">
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small" error={!!error.course_id}>
                <InputLabel id="course-label">Course *</InputLabel>
                <Select
                  id="workshop-form-select-course"
                  labelId="course-label"
                  label="Course"
                  value={workshop?.course_id || ''}
                  onChange={(e) => onInputChange('course_id', e.target.value)}
                >
                  {(courses || []).map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {`${item.title_en}, ${item.instructor_en}`}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText>{error.course_id}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-16">
            <Grid item xs={12} md={12}>
              <TextField
                id="workshop-form-input-image"
                fullWidth
                size="small"
                label="Image *"
                value={workshop?.image || ''}
                onChange={(e) => onInputChange('image', e.target.value)}
                error={!!error.image}
                helperText={error.image}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-16">
            <Grid item xs={12} md={6}>
              <TextField
                id="workshop-form-input-nameTH"
                fullWidth
                size="small"
                label="Name (TH) *"
                value={workshop?.title_th || ''}
                onChange={(e) => onInputChange('title_th', e.target.value)}
                error={!!error.title_th}
                helperText={error.title_th}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="workshop-form-input-nameEN"
                fullWidth
                size="small"
                label="Name (EN) *"
                value={workshop?.title_en || ''}
                onChange={(e) => onInputChange('title_en', e.target.value)}
                error={!!error.title_en}
                helperText={error.title_en}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-16">
            <Grid item xs={12} md={6}>
              <TextField
                id="workshop-form-input-descriptionTH"
                fullWidth
                size="small"
                label="Description (TH)"
                multiline
                rows={3}
                value={workshop?.description_th || ''}
                onChange={(e) =>
                  onInputChange('description_th', e.target.value)
                }
                error={!!error.description_th}
                helperText={error.description_th}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="workshop-form-input-descriptionEN"
                fullWidth
                size="small"
                label="Description (EN)"
                multiline
                rows={3}
                value={workshop?.description_en || ''}
                onChange={(e) =>
                  onInputChange('description_en', e.target.value)
                }
                error={!!error.description_en}
                helperText={error.description_en}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-16">
            <Grid item xs={12} md={6}>
              <TextField
                id="workshop-form-input-point"
                fullWidth
                type="number"
                size="small"
                label="Point *"
                value={workshop?.points || ''}
                onChange={(e) => onInputChange('points', +e.target.value)}
                error={!!error.points}
                helperText={error.points}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id="workshop-form-select-eventDate"
                  disablePast
                  inputFormat="dd/MM/yyyy"
                  label="Event Date"
                  value={workshop?.event_date || null}
                  onChange={(value) => onInputChange('event_date', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      error={!!error.event_date}
                      helperText={error.event_date}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-16">
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3}>
              <Button
                id="workshop-form-btn-add-session"
                variant="contained"
                color="info"
                fullWidth
                onClick={onAddSessionClick}
              >
                Add Session
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-30">
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {initColumns.map((column, i) => (
                        <TableCell
                          key={i}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <b>{column.label}</b>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(workshop?.sessions || []).map((item, i) => {
                      return (
                        <TableRow hover key={i}>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{item.event_time}</TableCell>
                          <TableCell>{item.quota}</TableCell>
                          <TableCell>
                            <IconButton
                              color="primary"
                              onClick={(e) => onEditSessionClick(e, item)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={(e) => onDeleteSessionClick(e, item)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3}>
              <Button
                id="workshop-form-btn-save"
                variant="contained"
                fullWidth
                onClick={onSaveClick}
              >
                save
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                id="workshop-form-btn-cancel"
                variant="outlined"
                fullWidth
                onClick={onCancelClick}
              >
                cancel
              </Button>
            </Grid>
          </Grid>

          <SessionDialog
            open={isShowSessionDialog}
            session={selectSession}
            onCloseClick={() => setIsShowSessionDialog(false)}
            onSaveClick={() => setIsShowSessionDialog(false)}
          />
        </Paper>
      </Grid>
    </>
  )
}

export default WorkshopForm
