/* eslint-disable no-unused-vars */
import { useNavigate, useLocation } from 'react-router';
import  routePaths  from 'routesPath'
import { useLoading } from 'components/Loading/context'
import CourseForm from './CommonCourseForm'
import courseApi from 'api/inspirationCourseApi'
import Swal from 'sweetalert2'

const groupEmi = 'EMI'

const AddDynamicCoursePage = () => {
  const history = useNavigate()
  const location = useLocation()
  const { showLoading, hideLoading } = useLoading()
  const searchParams = new URLSearchParams(location.search);
  const groupName = searchParams.get('group_name')
  let pathname = window.location.pathname;
    pathname = pathname.substring(7)
    pathname = pathname.substring(0,pathname.indexOf("/")).toUpperCase()
  const handleSave = async (inputData) => {
    showLoading()
    try {
      const response = await courseApi.createCourse(inputData)
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Add Course success' ,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/bo/dm/'+ pathname.toLowerCase() +'/course'
          }
        })
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      hideLoading()
    }
  }

  return <CourseForm mode="Add" onSave={handleSave} />
}

export default AddDynamicCoursePage
