import { createHttpClient } from './me2weApi'

const endPointGetData = '/inspirations/getCourseList'
const sortDataTXT = 'created_at desc'
class Course {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }
  async getCourses(data) {
    return this.client.post(endPointGetData, {
      title: data.title || '',
      instructor: data.instructor || '',
      page: data.page || 1,
      page_size: data.pageSize || 0,
      sort: data.sort || sortDataTXT,
      group_name: data.group_name || 'DEFAULT'
    })
  }

  async getCourseById(id) {
    return this.client.post('/inspirations/getCourseById', { id })
  }

  async createCourse(data) {
    return this.client.post('/inspirations/createCourse', data)
  }

  async updateCourseById(data) {
    return this.client.post('/inspirations/updateCourseById', data)
  }

  async deleteCourseById(id) {
    return this.client.post(`/inspirations/deleteCourseById`, { id })
  }

  async getEmiCourses(data) {
    return this.client.post(endPointGetData, {
      title: data.title || '',
      instructor: data.instructor || '',
      page: data.page || 1,
      page_size: data.pageSize || 0,
      sort: data.sort || sortDataTXT,
      group_name: data.group_name || 'EMI'
    })
  }

  async getCoursesDynamic(data) {
    return this.client.post(endPointGetData, {
      title: data.title || '',
      instructor: data.instructor || '',
      page: data.page || 1,
      page_size: data.pageSize || 0,
      sort: data.sort || sortDataTXT,
      group_name: data.group_name
    })
  }
}

const courseApi = new Course()
export default courseApi
