import { createHttpClient } from './me2weApi'

class DynamicsMenu {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getMenuLists() {
    return this.client.get('/menu/user/list')
  }
  async getBOMenuLists() {
    return this.client.get('/menu/list')
  }
  async addMainMenu(req) {
    return this.client.post('menu/create/main',{
      is_open: false,
      name: req.menu_name,
      to_page: req.path_str,
      icon: req.icon_name,
      group_name: req.group_name,
      role_permission: "",
      is_divide: true,
      client_side: req.client_side || "BO"
    })
  }

  async addSubMenu(req) {
    return this.client.post('menu/create/sub',{
      menu_id: req.main_menu_id,
      name: req.name,
      to_page: req.path_str,
      icon: "",
      group_name: req.group_name,
      role_permission: ""
    })
  }

  async removeMenu(req){
    return this.client.delete('/menu/del/main/'+ req.id)
  }
  async updateMainMenu(req){
    return this.client.put('menu/update/main/'+ req.id,{
      name: req.title
    })
  }
  async updateSubMenu(req){
    return this.client.put('menu/update/sub/'+ req.id,{
      name: req.title
    })
  }

}

const dynamicsMenuApi = new DynamicsMenu()
export default dynamicsMenuApi
