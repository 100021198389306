import { create } from 'zustand'
import rewardApi from 'api/rewardApi'
import { initPagination } from 'constant/boPagination'

export const useBoRewardStore = create((set, get) => ({
  rewards: [],
  pagination: initPagination,
  fetchRewards: async (params) => {
    let rewardDatas = []
    let paginationData = initPagination
    try {
      const response = await rewardApi.getRewards(params)
      const { data, pagination } = response.data
      rewardDatas = data
      paginationData = pagination
    } catch (error) {
      throw new Error(error)
    } finally {
      set({
        rewards: rewardDatas,
        pagination: paginationData,
      })
    }
  },
  fetchRewardById: async (id) => {
    let resultData = {}
    try {
      const response = await rewardApi.getRewardById(id)
      const { data } = response.data
      resultData = data || {}
    } catch {}
    return resultData
  },
  createReward: async (data) => {
    const response = await rewardApi.createReward(data)
    return response.status === 200
  },
  updateReward: async (data) => {
    const response = await rewardApi.updateReward(data)
    return response.status === 200
  },
  deleteReward: async (id) => {
    try {
      const response = await rewardApi.deleteReward(id)
      return response.status === 200
    } catch (error) {
      return false
    }
  },
}))
