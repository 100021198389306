import React, { useState, useEffect } from 'react'
import  routePaths  from 'routesPath'
import { useLoading } from 'components/Loading/context'
import { rowPerPageOptions, initPagination } from 'constant/boPagination'
import workshopApi from 'api/inspirationWorkshopApi'

import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  TextField,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Swal from 'sweetalert2'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { fDate, fISODateToUTC } from 'utils/formatTime'
import ExportBtn from 'components/ExportBtn'

const initColumns = [
  {
    id: 'no',
    label: 'No.',
    minWidth: 30,
  },
  {
    id: 'image',
    label: 'Image',
    minWidth: 80,
  },
  {
    id: 'title_th',
    label: 'Name (TH)',
    minWidth: 100,
  },
  {
    id: 'title_en',
    label: 'Name (EN)',
    minWidth: 100,
  },
  {
    id: 'event_date',
    label: 'Event Date',
    minWidth: 120,
  },
  {
    id: 'session',
    label: 'Session',
    minWidth: 150,
  },
  {
    id: 'action',
    label: '',
    minWidth: 80,
  },
]

const initPaging = {
  title: '',
  instructor: '',
  page: 1,
  pageSize: 5,
}

const txtProps = {
  size: 'small',
  fullWidth: true,
  sx: { backgroundColor: '#fff' },
}

const EmiWorkshopPage = () => {
  const navigate = useNavigate()
  const { showLoading, hideLoading } = useLoading()
  const [paging, setPaging] = useState(initPaging)
  const [data, setData] = useState({
    workshops: [],
    pagination: initPagination,
  })

  useEffect(() => {
    onFetchData(initPaging)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [exportData, setExportData] = useState([])
  const [nameSearch, setNameSearch] = useState("")
  const [startSearch, setStartSearch] = useState("")
  const [endSearch, setEndSearch] = useState("")
  useEffect(() => {
    onFetchExport()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[nameSearch, startSearch, endSearch])

  const onFetchExport = async () =>{
    const reqExport = {
      pageSize: 9999
    }
    if(nameSearch && nameSearch !== ""){
      reqExport.title = nameSearch
    }
    if(startSearch && startSearch !== ""){
      reqExport.event_date_start = startSearch
    }
    if(endSearch && endSearch !== ""){
      reqExport.event_date_end = endSearch
    }
    const res = await workshopApi.getEmiWorkshops(reqExport)
    setExportData(res?.data?.data)
  }
  const onFetchData = async (newPaging) => {
    showLoading()
    let workshopDatas = []
    let paginationData = initPagination
    try {
      const response = await workshopApi.getEmiWorkshops(newPaging)
      workshopDatas = response.data.data
      paginationData = response.data.pagination
      setPaging(newPaging)
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      setData({
        workshops: workshopDatas,
        pagination: paginationData,
      })
      hideLoading()
    }
  }

  const onAddWorkshopClick = (e) => {
    e.preventDefault()
    navigate(routePaths.BO_EMI_WORKSHOP_ADD)
  }

  const onCriteriaChange = (name, value) => {
   switch(name){
    case "title" : setNameSearch(value)
                  break;
    case "event_date_start": setStartSearch(value)
                  break;
    case "event_date_end": setEndSearch(value)
                  break;
    default: break
   }
    setPaging({
      ...paging,
      [name]: value,
    })
  }

  const onSearchClick = async (e) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: 1,
    })
  }

  const onClearClick = async (e) => {
    e.preventDefault()
    await onFetchData(initPaging)
  }

  const onEditClick = async (e, workshopId) => {
    e.preventDefault()
    navigate(`${routePaths.BO_EMI_WORKSHOP_EDIT}?id=${workshopId}`)
  }

  const onDeleteClick = async (e, workshopId) => {
    e.preventDefault()
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await workshopApi.deleteWorkshopById(workshopId)
          if (response.status === 200) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            ).then(async (res) => {
              if (res.isConfirmed) {
                await onFetchData(initPaging)
              }
            })
          } else {
            throw new Error('failed')
          }
        } catch {
          Swal.fire({
            icon: 'error',
            title: `Error`,
            text: `System error.`,
          })
        }
      }
    })
  }

  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: newPage + 1,
    })
  }

  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: 1,
      pageSize: +e.target.value,
    })
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="mb-20"
      >
        <Grid item>
          <h2>Emi Workshop List</h2>
        </Grid>
        <Grid item md={2}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onAddWorkshopClick}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-16">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            label="Name (TH/EN)"
            value={paging.title || ''}
            onChange={(e) => onCriteriaChange('title', e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="workshop-select-event-startDate"
              inputFormat="dd/MM/yyyy"
              label="Event Start Date"
              value={paging.event_date_start || null}
              onChange={(value) => onCriteriaChange('event_date_start', value)}
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="workshop-select-event-endDate"
              inputFormat="dd/MM/yyyy"
              label="Event End Date"
              value={paging.event_date_end || null}
              onChange={(value) => onCriteriaChange('event_date_end', value)}
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="workshop-btn-search"
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<SearchIcon />}
            onClick={onSearchClick}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="workshop-btn-clear"
            variant="contained"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={onClearClick}
          >
            Clear
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
            <ExportBtn dataFile={exportData} pageId={'emi-workshop'} />
        </Grid>
      </Grid>

      <Grid container>
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {initColumns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(data.workshops || []).map((item, i) => {
                  return (
                    <TableRow hover key={item.id}>
                      <TableCell>
                        {(data.pagination.page - 1) * data.pagination.limit +
                          (i + 1)}
                      </TableCell>
                      <TableCell>
                        <img
                          src={item.image}
                          alt={item.title_en}
                          width="65px"
                        />
                      </TableCell>
                      <TableCell>{item.title_th}</TableCell>
                      <TableCell>{item.title_en}</TableCell>
                      <TableCell>
                        {fDate(fISODateToUTC(item.event_date))}
                      </TableCell>
                      <TableCell>
                        {(item?.sessions || []).map((session) => {
                          return (
                            <div key={session.id}>
                              {session?.event_time}
                              <br />
                            </div>
                          )
                        })}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          id="workshop-btn-edit"
                          color="primary"
                          onClick={(e) => onEditClick(e, item.id)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          id="workshop-btn-delete"
                          color="error"
                          onClick={(e) => onDeleteClick(e, item.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={data.pagination.total_count}
            rowsPerPage={paging.pageSize}
            page={data.pagination.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>
    </>
  )
}

export default EmiWorkshopPage
