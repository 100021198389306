import { createHttpClient } from './me2weApi'

const pathReversionList = '/inspirations/getReservationList'
const sortList = 'created_at desc'
class Reservation {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getReservations(data) {
    return this.client.post(pathReversionList, {
      ...data,
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || sortList,
      group_id: data.group_id || 1
    })
  }
  async getEmiReservations(data) {
    return this.client.post(pathReversionList, {
      ...data,
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || sortList,
      group_id: data.group_id|| 2

    })
  }
  async getLeaderReservations(data) {
    return this.client.post(pathReversionList, {
      ...data,
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || sortList,
      group_id: 4

    })
  }
  async approveScoreReservation(data) {
    return this.client.post("/inspirations/batchApprove", {
      approve: data
    })
  }
}

const booksApi = new Reservation()
export default booksApi
