import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

import authApi from 'api/authApi'
import frontEndApi from 'api/frontEndApi'
import { encrypt, decrypt } from '../utils/common'

export const useAuthStore = create(
  persist(
    (set, get) => ({
      accessToken: '',
      idToken: '',
      imgProfile: '',
      user: {},
      fuel: {},
      fuelLeader: {},
      clearAuth: () =>
        set({
          accessToken: '',
          idToken: '',
          user: {},
        }),
      setAccessToken: (value) =>
        set({
          accessToken: value,
        }),
      setIdToken: (value) =>
        set({
          idToken: value,
        }),
      setUserImg: (imgProfile) =>
        set({
          imgProfile,
        }),
      fetchUserInfo: async () => {
        try {
          const response = await frontEndApi.getUserProfile()
          const { data } = response.data || {}
          const { created_at, updated_at, ...restProps } = data || {}
          set({
            user: {
              ...get().user,
              ...restProps,
            },
          })
        } catch {}
      },
      fetchFuel: async () => {
        try {
          const response = await frontEndApi.getFuel({ group_id: 1})
          const { data } = response.data || {}
          set({
            fuel: data,
          })
        } catch {}
      },
      loginUser: async (username, credential) => {
        try {
          const response = await authApi.login({
            username,
            credential,
          })
          const { data } = response.data || {}
          const { roles, user_group, ...restProps } = data.user || {}
          const { group_name } = user_group
          set({
            accessToken: data.access_token,
            user: {
              ...restProps,
              roles: encrypt(roles),
              group_name: encrypt(group_name),
            },
          })
          return true
        } catch {
          return false
        }
      },
      loginAD: async () => {
        try {
          const response = await authApi.loginWithToken()
          const { data } = response.data || {}
          const { roles, user_group, ...restProps } = data.user || {}
          const { group_name } = user_group
          set({
            user: {
              ...restProps,
              roles: encrypt(roles),
              group_name: encrypt(group_name),
            },
          })
          return true
        } catch {
          return false
        }
      },
      requestUserELearning: async () => {
        const respond = await frontEndApi.requestUserELearning({})
        return respond.status === 200
      },
      hasRole: (value) => {
        const { roles } = get().user || {}
        if (!roles) {
          return false
        }
        const decrypted = decrypt(roles) || ''
        return decrypted.split(',').includes(value)
      },
      hasGroup: (value) => {
        const { group_name } = get().user || {}

        if (!group_name) {
          return false
        }
        const decrypted = decrypt(group_name) || ''
        return decrypted.split(',').includes(value)
      },
      fetchEmi: async () => {
        try {
          const response = await frontEndApi.getFuel({ group_id: 2 })
          const { data } = response.data || {}
          set({
            fuel: data,
          })
        } catch {}
      },
      fetchLeader: async () => {
        try {
          const response = await frontEndApi.getFuel({ group_id: 4 })
          const { data } = response.data || {}
          set({
            fuelLeader: data,
          })
        } catch {}
      },
      updateUserAvatar: async (value) => {
        try {
          await frontEndApi.updateUserAvatar({ avatar: value })
        } catch {}
      },
    }),
    {
      name: 'authStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
