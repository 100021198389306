import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import  routePaths  from 'routesPath'
import { useLoading } from 'components/Loading/context'
import CourseForm from './CourseForm'
import courseApi from 'api/inspirationCourseApi'
import Swal from 'sweetalert2'

const GroupEmi = 'EMI'

const EditCoursePage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const { showLoading, hideLoading } = useLoading()
  const [course, setCourse] = useState(null)
  const groupName = searchParams.get('group_name')

  useEffect(() => {
    const id = +searchParams.get('id')
    if (!id || id <= 0) {
      navigate(routePaths.BO_INSPIRATION_COURSE)
    } else {
      onFetchCourse(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFetchCourse = async (id) => {
    showLoading()
    try {
      const result = await courseApi.getCourseById(id)
      setCourse(result.data.data)
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      hideLoading()
    }
  }

  const handleSave = async (inputData) => {
    showLoading()
    try {
      const result = await courseApi.updateCourseById({
        ...inputData,
        id: +searchParams.get('id'),
      })
      if (result.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: groupName === GroupEmi ? 'Update Emi Course success' : 'Update Inspiration Hour Course success',
        })
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      hideLoading()
    }
  }

  return <CourseForm mode="Edit" course={course} onSave={handleSave} />
}

export default EditCoursePage
