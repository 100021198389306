import { create } from 'zustand'
import bookRequestsApi from 'api/bookRequestsApi'
import { initPagination } from 'constant/boPagination'
import { fUTCDateToIsoStart, fUTCDateToIsoEnd } from 'utils/formatTime'

export const useBoBookRequestStore = create((set, get) => ({
  bookRequests: [],
  pagination: initPagination,
  fetchBookRequests: async (params) => {
    let bookRequestDatas = []
    let paginationData = initPagination
    try {
      const response = await bookRequestsApi.getBookRequests({
        ...params,
        start: fUTCDateToIsoStart(params.start),
        end: fUTCDateToIsoEnd(params.end),
      })
      const { data, pagination } = response.data
      bookRequestDatas = data
      paginationData = pagination
    } catch {
      return false
    } finally {
      set({
        bookRequests: bookRequestDatas,
        pagination: paginationData,
      })
    }
    return true
  },
  batchUpdateBookSent: async (data) => {
    try {
      const response = await bookRequestsApi.batchUpdateBookSent(data)
      return response.status === 200
    } catch (error) {
      return false
    }
  },
}))
