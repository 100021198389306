import styled, { css } from 'styled-components'
import { screen, borderRadius, gutter } from 'styles/variables';

export const WrapperProfileNav = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${gutter.default};
  @media (max-width: ${screen.md.max}) {
    display: none;
  }
`;

export const WrapperNav = styled.div`
  background: ${({ theme }) => theme?.colors?.navbar?.primary};
  padding: ${gutter.small};
  display: grid;
  /* grid-template-columns: auto 1fr auto 130px auto; */
  grid-template-columns: auto 1fr 0.7fr;
  border-radius: ${borderRadius.default};
  grid-gap: ${gutter.small};
  position: relative;
`;

export const LogoApp = styled.img`
 width: 120px;
 margin: auto 0;
`;

export const WrapperFullName = styled.div`
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  display: grid;
`;

export const WrapperMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${gutter.xs};
`;

export const ItemMenu = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  padding: ${gutter.small};
  border-radius: ${borderRadius.default};
  align-items: center;
  align-self: center;
  text-align: center;
  cursor: pointer;
  ${props => props.active && css`
    background: ${({ theme }) => theme?.colors?.navbar?.active};
  `}
`;

export const ImageMenu = styled.img`
  height: 35px;
  width: 35px;
  margin: 0 auto;
`;

export const WrapperImgeUser = styled.div`
  position: relative;
`;

export const ImageUser = styled.img`
  /* position: absolute; */
  width: 100px;
  height: 100px;
  bottom: -50px;
  display: block;
  margin: 0 auto;
  ${props => props.circle && css`
    border-radius: 100%;
  `}
`;

export const WrapperChangeLang = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  color: ${({ theme }) => theme?.colors?.navbar?.text};
  /* position: absolute; */
  /* bottom: -45px; */
  /* display: grid; */
  /* grid-template-columns: 1fr auto; */
  /* left: -240px; */
`;
