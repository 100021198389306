import React from 'react'
import { Button } from '@mui/material'
import IosShare from '@mui/icons-material/IosShare'
import XLSX from 'sheetjs-style'

function ExportBtn(props) {

    const dataFile  = props?.dataFile
    const fileExtension = '.xlsx'

    function exportFile () {
        const ws = XLSX.utils.json_to_sheet(dataFile);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, ws, 'sheet1');
        XLSX.writeFile(workbook, props?.pageId + fileExtension)
    }

    return (
        <Button
            id={`${props.pageId}-btn-export`}
            data-testid={`${props.pageId}-btn-export`}
            variant="contained"
            color="success"
            fullWidth
            startIcon={<IosShare />}
            onClick={() => exportFile()}
        >
        Export
        </Button>
    );
}

export default ExportBtn;
