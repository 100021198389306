

import './epspirit.scss'

const EPSpiritPage = () => {
   return (
    <>
        <div className="is-container">
        <div className="page-header">EP Spirit</div>
        <div>Coming Soon...</div>
        </div>
    </>
   )
}

export default EPSpiritPage
