import CustomButton from 'components/CustomButton'
import HeartPoint from 'components/HeartPoint'
import { useDynamicsMenuStore } from 'stores/dynamicsMenuStore'
import { useNavigate } from 'react-router-dom'
import { useLoading } from 'components/Loading/context'
import React, { useEffect } from 'react'
import { useAuthStore } from 'stores/authStore'

function CustomMenuComponent() {
  const navigate = useNavigate()
  const { showLoading, hideLoading } = useLoading()

  const [fetchEmi, fetchLeader] = useAuthStore((state) => [
    state.fetchEmi,
    state.fetchLeader,
    ])

    const [
      allMenuLists,
      getAllMenuLists
    ] = useDynamicsMenuStore((state) => [
      state.allMenuLists,
      state.getAllMenuLists
    ])

  async function handleGetMenuLists() {
    await getAllMenuLists()
    hideLoading()
  }

  function handleChildren(item) {
    if (item && item.group_name) {
      navigate(`/course/${item.group_name}`)
    }
  }

  useEffect(()=>{
      fetchEmi()
      fetchLeader()
      handleGetMenuLists()
      showLoading()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    allMenuLists && allMenuLists.length > 0
      ? allMenuLists.map((item, index) => (
        // item.sessions === props.sessions && (
          <div key={index}>
            <div
                id={item.id}
                className="w2-box-container"
                // onClick={(e) => onNavigateClick(e, routePaths.EMI)}
            >
                <div className="point-container" style={{ height: '20px' }}>
                    <HeartPoint size="sm" point={item.point} />
                </div>
                <div className="w2-image-container">
                <img
                    alt="customMenu"
                    src={item.img}
                    width="96"
                />
                </div>
                <div className="w2-button-container">
                <CustomButton
                    id="btnCustomMenu"
                    style={{ textTransform: 'none' }}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => handleChildren(item)}
                >
                    {item.name}
                </CustomButton>
                </div>
            </div>
        </div>
      // )
      ))
      : <></>
  )
}

export default CustomMenuComponent;
