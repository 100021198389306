import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Button, Stack, TextField, Paper } from '@mui/material'
import Swal from 'sweetalert2'
import booksApi from 'api/booksApi'
import  routePaths  from 'routesPath'

export default function BOBookFormPage() {
  const [mode, setMode] = useState('Create')
  const [inputData, setInputData] = useState({
    title_th: '',
    title_en: '',
    description_th: '',
    description_en: '',
    points: 0,
    image: '',
  })
  const [error, setError] = useState({})
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      setMode('Update')
      fetchBookById(parseInt(id))
    }
    // eslint-disable-next-line
  }, [])

  const fetchBookById = async (bookId) => {
    try {
      const res = await booksApi.getBookById(bookId)
      const { data } = res.data
      setInputData(data)
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
  }

  const handleInputChange = (name, value) => {
    if (name === 'points') {
      value = value > 999 ? 999 : value
    }
    setInputData({
      ...inputData,
      [name]: value,
    })
  }

  const handleSubmitClick = async () => {
    try {
      if (!validateInput()) {
        let response
        if (mode === 'Create') {
          response = await booksApi.createBook(inputData)
        } else {
          response = await booksApi.updateBook({
            ...inputData,
            id: parseInt(id),
          })
        }
        if (response) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: mode + ' book success',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(routePaths.BO_BOOK)
            }
          })
        } else {
          throw new Error('fail')
        }
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
  }

  const handleCancelClick = () => {
    setInputData({
      title_th: '',
      title_en: '',
      description_th: '',
      description_en: '',
      points: 0,
      image: '',
    })
    navigate(routePaths.BO_BOOK)
  }

  const validateInput = () => {
    let isError = false
    let errorObj = {}
    if (!inputData.title_th) {
      isError = true
      errorObj = {
        ...errorObj,
        title_th: 'Please fill in Name (TH)',
      }
    }
    if (!inputData.title_en) {
      isError = true
      errorObj = {
        ...errorObj,
        title_en: 'Please fill in Name (EN)',
      }
    }
    if (!inputData.image) {
      isError = true
      errorObj = {
        ...errorObj,
        image: 'Please fill in Image Url',
      }
    }
    if (inputData.points <= 0) {
      isError = true
      errorObj = {
        ...errorObj,
        points: 'Points must greater than 0',
      }
    }

    setError(errorObj)

    return isError
  }

  return (
    <>
      <Grid container direction="row">
        <Grid item md={12}>
          <h2>{mode === 'Create' ? 'Add book' : 'Edit book'}</h2>
        </Grid>
      </Grid>

      <Paper elevation={3} sx={{ mt: 3, p: 4 }}>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} md={6}>
            <TextField
              data-testid="bookform-input-nameTH"
              id="bookform-input-nameTH"
              label="Name (TH) *"
              size="small"
              fullWidth
              value={inputData?.title_th}
              onChange={(e) => handleInputChange('title_th', e.target.value)}
              error={error.title_th ? true : false}
              helperText={error.title_th ? error.title_th : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="bookform-input-nameEN"
              label="Name (EN) *"
              size="small"
              fullWidth
              value={inputData?.title_en}
              onChange={(e) => handleInputChange('title_en', e.target.value)}
              error={error.title_en ? true : false}
              helperText={error.title_en ? error.title_en : ''}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              data-testid="bookform-input-descriptionTH"
              id="bookform-input-descriptionTH"
              label="Description (TH)"
              size="small"
              multiline
              rows={3}
              fullWidth
              value={inputData?.description_th}
              onChange={(e) =>
                handleInputChange('description_th', e.target.value)
              }
              error={error.description_th ? true : false}
              helperText={error.description_th ? error.description_th : ''}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              data-testid="bookform-input-descriptionEN"
              id="bookform-input-descriptionEN"
              label="Description (EN)"
              size="small"
              multiline
              rows={3}
              fullWidth
              value={inputData?.description_en}
              onChange={(e) =>
                handleInputChange('description_en', e.target.value)
              }
              error={error.description_en ? true : false}
              helperText={error.description_en ? error.description_en : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              data-testid="bookform-input-point"
              id="bookform-input-point"
              label="Point *"
              size="small"
              fullWidth
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                inputProps: { min: 0, max: 999 },
              }}
              value={inputData?.points || 0}
              onChange={(e) =>
                handleInputChange('points', +e.target.value || 0)
              }
              error={error.points ? true : false}
              helperText={error.points ? error.points : ''}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              data-testid="bookform-input-image"
              id="bookform-input-image"
              label="Image Url *"
              size="small"
              fullWidth
              value={inputData?.image}
              onChange={(e) => handleInputChange('image', e.target.value)}
              error={error.image ? true : false}
              helperText={error.image ? error.image : ''}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Grid item xs={6} md={3}>
                <Button
                  data-testid="bookform-btn-save"
                  id="bookform-btn-save"
                  variant="contained"
                  fullWidth
                  onClick={() => handleSubmitClick()}
                >
                  save
                </Button>
              </Grid>
              <Grid item xs={6} md={3}>
                <Button
                  data-testid="bookform-btn-cancel"
                  id="bookform-btn-cancel"
                  variant="outlined"
                  fullWidth
                  onClick={() => handleCancelClick()}
                >
                  cancel
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
