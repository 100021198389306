import * as React from 'react'
import QRCode from 'react-qr-code'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import  routePaths  from 'routesPath'

import {
  WrapperFooter,
  WrpperBottomNavigationAction
} from 'layout/Web/Footer.style'
import { useAuthStore } from 'stores/authStore'
import NotifyDialog from 'components/NotifyDialog'

function FooterLayout() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [user] = useAuthStore((state) => [state.user])
  const isAdmin = user.role === 'ADMIN'

  const [isShowQrCode, setIsShowQrCode] = useState(false)

  const handleChange = (e, url) => {
    e.preventDefault()
    if (url) {
      navigate(url)
    }
  }

  return (
    <>
      <WrapperFooter
        id="btnNavigation"
        sx={{
          width: '100vw',
        }}
        onChange={handleChange}
      >
        <WrpperBottomNavigationAction
          data-testid="home"
          label={t('homePage')}
          value={routePaths && routePaths.HOME}
          showLabel
          icon={
            <img
              src="/assets/images/menu-home.svg"
              alt="Home"
              width="20"
              style={{ marginBottom: '6px' }}
            />
          }
        />
        <WrpperBottomNavigationAction
          label={t('myProfile')}
          value={routePaths && routePaths.PROFILE}
          showLabel
          icon={
            <img
              src="/assets/images/menu-profile.svg"
              alt="Profile"
              width="20"
              style={{ marginBottom: '6px' }}
            />
          }
        />
        { isAdmin
          ? <WrpperBottomNavigationAction
              label={t('checkin')}
              value={routePaths && routePaths.CHECK_IN}
              showLabel
              icon={
                <img
                  src="/assets/images/check-in.svg"
                  alt="Check In"
                  width="20"
                  style={{ marginBottom: '6px' }}
                />
              }
            />
          : <WrpperBottomNavigationAction
              label={t('contact')}
              value={routePaths && routePaths.CONTACT}
              showLabel
              icon={
                <img
                  src="/assets/images/contact.svg"
                  alt="Contact"
                  width="20"
                  style={{ marginBottom: '6px' }}
                />
              }
            />
        }

        { !isAdmin &&
          <WrpperBottomNavigationAction
            data-testid="qr-code"
            label={t('QR Code')}
            onClick={(e) => setIsShowQrCode(true)}
            value={null}
            showLabel
            icon={
              <img
                src="/assets/images/qr-code.svg"
                alt="Qr Code"
                width="20"
                style={{ marginBottom: '6px' }}
              />
            }
          />
        }

        {/* <WrpperBottomNavigationAction
          label={t('transferPoint')}
          value={routePaths && routePaths.TRANSFER_POINT}
          showLabel
          icon={
            <img
              src="/assets/images/transfer-point.svg"
              alt="Profile"
              width="20"
              style={{ marginBottom: '6px' }}
            />
          }
        /> */}

        { isAdmin &&
          <WrpperBottomNavigationAction
            label={t('admin')}
            value={routePaths && routePaths.BO_HOME}
            showLabel
            icon={
              <img
                src="/assets/images/admin.svg"
                alt="Check In"
                width="20"
                style={{ marginBottom: '6px' }}
              />
            }
          />
        }

        { location?.pathname !== routePaths?.HOME &&
          <WrpperBottomNavigationAction
            label={t('back')}
            showLabel
            onClick={ () => navigate(-1) }
            disabled={location?.pathname === routePaths?.HOME}
            icon={
              <img
                src="/assets/images/menu-back.svg"
                alt="Back"
                width="20"
                style={{ marginBottom: '6px' }}
              />
            }
          />
        }

      { user && isShowQrCode &&
        <NotifyDialog
          type="confirmation"
          bodyText={<QRCode value={user.email} />}
          open={isShowQrCode}
          onCloseDialog={() => setIsShowQrCode(false)}
        />
      }
      </WrapperFooter>
    </>
  )
}

export default FooterLayout
