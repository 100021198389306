import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CustomButton from 'components/CustomButton'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Input from '@mui/base/Input'

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    borderRadius: '10px',
  },
  '& .MuiDialogTitle-root': {},
  '& .MuiDialogContent-root': {
    padding: '6px 16px 16px 16px',
  },
  '& .MuiDialogActions-root': {
    padding: '16px 14px 17px 14px',
  },
}))

export const InputFormGroup = styled('div')(`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`)

const Label = styled('label')(`
    margin-right: 10px;
`)

const StyledInputElement = styled('input')(`
    height: 30px;
    padding: 0px 16px;
    background: #FEFDFE;
    border: 1px solid #C5C5C7;
    border-radius: 34px;
    font-size: 14px;
    line-height: 17px;
  
    &:hover {
      border-color: #007FFF;
    }
  
    &:focus {
      border-color: #007FFF;
      outline: 2px solid #007FFF;
    }
`)
const NotifyDialog = ({
  type, // Require exp: "success" | "warning" | "confirmation",
  title, // Require
  bodyText, // Require
  okText, // Require
  cancelText,
  open, // Require
  onCloseDialog, // Require
  onOkDialogClick, // Require
  onCancelDialogClick,
  iconImgPath,
  withInput,
  inputLabel,
  inputType,
  pattern,
  onChangeInput
}) => {
  let imgPath
  let titleTextColor

  if (type === 'success') {
    imgPath = '/assets/icons/icon-check-circle-outline.svg'
    titleTextColor = '#30CE2C'
  } else if (type === 'warning') {
    imgPath = '/assets/icons/icon-warning-outline.svg'
    titleTextColor = '#FBD000'
  } else {
    imgPath = ''
    titleTextColor = '#494949'
  }

  if (iconImgPath) {
    imgPath = iconImgPath
  }

  return (
    <BootstrapDialog
      onClose={onCloseDialog}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle
        onClose={onCloseDialog}
        sx={{
          fontWeight: '700',
          fontSize: '16px',
          textAlign: 'center',
          paddingBottom: '0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: titleTextColor,
        }}
      >
        {imgPath !== '' && (
          <img
            src={imgPath}
            alt={type}
            width="65"
            style={{ margin: '24px 0px' }}
          />
        )}
        {type === 'error' && (
          <ErrorOutlineIcon
            sx={{ fontSize: '80px', margin: '15px 0' }}
            color="error"
          />
        )}
        {title}
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        {bodyText}
        { withInput && (
          <>
            <InputFormGroup>
              <Label>{inputLabel}:</Label>
              <Input
                type={inputType}
                slots={{ input: StyledInputElement }}
                id='inputDialog'
                pattern={pattern ? pattern : ''}
                onChange={onChangeInput}
              ></Input>
            </InputFormGroup>
          </>
          )
        }
      </DialogContent>
      { (cancelText || okText) && (
        <DialogActions sx={{ justifyContent: 'center' }}>
          {cancelText && (
            <CustomButton
              id="btnCancel"
              color="error"
              variant="outlined"
              fullWidth
              onClick={onCancelDialogClick}
            >
              {cancelText}
            </CustomButton>
          )}
          {okText && (
            <CustomButton
              id="btnOk"
              color="primary"
              variant="contained"
              fullWidth={cancelText ? true : false}
              onClick={onOkDialogClick}
            >
              {okText}
            </CustomButton>
          )}
        </DialogActions>
      )}
    </BootstrapDialog>
  )
}

export default NotifyDialog
