import styled, { css } from 'styled-components';
import { screen, gutter } from 'styles/variables';

export const WrapperProfilePage = styled.div`
  padding: ${gutter.large};
`;

export const WrapperProfileBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${gutter.default};
  @media (max-width: ${screen.sm.max}) {
    grid-template-columns: 1fr;
  }
`;

export const WrapperSectionProfile = styled.div`
  position: relative;
  box-sizing: border-box;
  background: ${({ theme }) => theme?.colors?.profile?.panel};
  backdrop-filter: blur(2px);
  border-radius: 20px;
  border: solid 2px ${({ theme }) => theme?.colors?.profile?.borderColor};
  padding: 50px ${gutter.large};
  text-align: center;
  margin-top: 200px;
  @media (max-width: ${screen.sm.max}) {
    margin-top: 120px;
  }
`;

export const WrapperSectionMenu = styled.div`
  margin-top: 200px;
  @media (max-width: ${screen.sm.max}) {
    margin-top: 0;
  }
`;

export const WrapperProfileImage = styled.div`
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  ${props => props.circle && css`
    border-radius: 100%;
  `}
`;

export const WrapperBoxMenu = styled.div`
  background: ${({ theme }) => theme?.colors?.profile?.panel};
  backdrop-filter: blur(2px);
  border-radius: 20px;
  border: solid 2px ${({ theme }) => theme?.colors?.profile?.borderColor};
  padding: ${gutter.small} ${gutter.large};
  margin-bottom: ${gutter.default};

  ${props => props.cursor && css`
    cursor: pointer;
  `}
`;

export const WrapperItemMenu = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${gutter.default};
  ${props => props.third && css`
    grid-template-columns: auto 1fr auto;
  `}
`;

export const IconMenu = styled.img`
  width: 23px;
  height: 23px;
`;
