import { AES, enc } from 'crypto-js'
import config from '../config'

export const encrypt = (value) =>
  AES.encrypt(value, config.secretKey).toString()

export const decrypt = (encrypted) =>
  AES.decrypt(encrypted, config.secretKey).toString(enc.Utf8)

export const setStorageLang = (lng) => {
  if (typeof window !== 'undefined' && lng) {
    localStorage.setItem('LANG', lng);
  }
}

export const getStorageLang = () => {
  if (typeof window !== 'undefined') {
    const lng = localStorage.getItem('LANG');
    if (lng) {
      return lng;
    }
  }
  return 'th';
}

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return `<a href="${url}" target="_blank">${url}</a>`;
  })
}
