import { useEffect } from 'react'
import { useLoading } from 'components/Loading/context'
import { useNavigate, useSearchParams } from 'react-router-dom'
import  routePaths  from 'routesPath'
import { useBoInspirationWorkshopStore } from 'stores/BackOffice/boInspirationWorkshop'
import workshopApi from 'api/inspirationWorkshopApi'

import Swal from 'sweetalert2'
import WorkshopForm from './WorkshopForm'

const EditWorkshopPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { showLoading, hideLoading } = useLoading()

  const [fetchWorkshop] = useBoInspirationWorkshopStore((state) => [
    state.fetchWorkshop,
  ])

  useEffect(() => {
    const id = +searchParams.get('id')
    if (!id || id <= 0) {
      navigate(routePaths.BO_INSPIRATION_WORKSHOP)
    } else {
      onFetchWorkshop(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFetchWorkshop = async (id) => {
    showLoading()
    const result = await fetchWorkshop(id)
    if (!result) {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `Data not found.`,
      }).then(() => {
        navigate(routePaths.BO_INSPIRATION_WORKSHOP)
      })
    }
    hideLoading()
  }

  const handleSave = async (inputData) => {
    showLoading()
    try {
      const result = await workshopApi.updateWorkshopById({
        ...inputData,
        id: +searchParams.get('id'),
      })
      if (result.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Update Inspiration Hour Workshop success',
        })
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      hideLoading()
    }
  }

  return <WorkshopForm mode="Edit" onSave={handleSave} />
}

export default EditWorkshopPage
