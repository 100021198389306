import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { useAuthStore } from 'stores/authStore'
import { useEffect } from 'react'
import AccmulatePointComponent from 'components/AccmulatePointComponent'

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    borderRadius: '10px',
  },
  '& .MuiDialogTitle-root': {},
  '& .MuiDialogContent-root': {
    padding: '6px 16px 16px 16px',
  },
  '& .MuiDialogActions-root': {
    padding: '16px 14px 17px 14px',
  },
}))

const StatusDialog = ({
  open,
  onCloseDialog
}) => {
  const [user, fetchUserInfo] = useAuthStore((state) => [
    state.user,
    state.fetchUserInfo,
  ])

  useEffect(() => {
    fetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BootstrapDialog
      onClose={onCloseDialog}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle
        onClose={onCloseDialog}
        sx={{
          fontWeight: '700',
          fontSize: '16px',
          textAlign: 'center',
          paddingBottom: '0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <AccmulatePointComponent
          accumulatePoints={user.accumulate_points}
          fullPoints={user.ful_points}
          usedPoints={user.used_points}
          balancePoints={user.balance_points}
          withProfile
        />
      </DialogContent>
    </BootstrapDialog>
  )
}

export default StatusDialog
