import './heart-point.scss'

const HeartPoint = (props) => {
  const { point = 0, className = '', size = 'md' } = props

  return (
    <div {...props} className={`hp-container ${size} ${className}`}>
      <div className={`point ${size}`}>{point}</div>
      <img
        className="heart-img"
        src="/assets/images/heart-green.svg"
        alt="Heart"
      />
    </div>
  )
}

export default HeartPoint
