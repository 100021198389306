import { create } from 'zustand'
import frontEndApi from 'api/frontEndApi'

export const useRewardStore = create((set, get) => ({
  rewards: [],
  clearRewards: () => set({ rewards: [] }),
  fetchRewards: async (params) => {
    try {
      const response = await frontEndApi.getRewards(params)

      if (response.status === 200) {
        if (response.data.data.length) {
          const rewardsData =
            params.page === 1
              ? response.data.data
              : get().rewards.concat(response.data.data)

          set({
            rewards: rewardsData,
          })
          return true
        }
      }
    } catch {}
    return false
  },
  redeemReward: async (rewardId) => {
    try {
      const response = await frontEndApi.redeemReward({ rewardId })
      return response.status === 200
    } catch {
      return false
    }
  },
}))
