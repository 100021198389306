import styled from 'styled-components';
import { gutter } from 'styles/variables';

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme?.colors?.divider?.default};
  margin: ${gutter.default} auto;
  opacity: 0.7;
`;
