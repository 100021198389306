import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Paper } from '@mui/material'
import { useAuthStore } from 'stores/authStore'
import NotifyDialog from 'components/NotifyDialog'
import HeartPoint from 'components/HeartPoint'
import Config from 'config'

import './e-learning.scss'
import ProfileBox from 'components/ProfileBox'
import ProfileNav from 'components/ProfileNav'

const ELearningPage = () => {
  const { t } = useTranslation()
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [isShowSuccessDialog, setIsShowSuccessDialog] = useState(false)
  const [isShowErrorDialog, setIsShowErrorDialog] = useState(false)

  const [requestUserELearning, fuel] = useAuthStore((state) => [
    state.requestUserELearning,
    state.fuel,
  ])

  const onLoginClick = (e) => {
    e.preventDefault()
    window.open(Config.externalUrl.eLearningLogin, '_blank')
  }

  const onNewUserClick = (e) => {
    e.preventDefault()
    setIsShowConfirmDialog(true)
  }

  const onConfirmDialogClick = async (e) => {
    e.preventDefault()
    setIsShowConfirmDialog(false)

    const result = await requestUserELearning()

    if (result) {
      setIsShowSuccessDialog(true)
    } else {
      setIsShowErrorDialog(true)
    }
  }

  return (
    <div className="el-container">
      <ProfileBox />
      <ProfileNav />
      <div className="page-header">{t('eLearning.title')}</div>

      <div className="point-container">
        <div className="label">{t('completion')}</div>
        <div>
          <HeartPoint point={fuel.elearning_points} />
        </div>
      </div>

      <Grid container>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={10}>
          <Paper
            data-testid='login'
            id="btnLogin"
            elevation={0}
            className="btn"
            onClick={onLoginClick}
          >
            {t('login')}
          </Paper>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={10}>
          <Paper
            data-testid='new-user'
            id="btnRequestNewUser"
            elevation={0}
            className="btn"
            onClick={onNewUserClick}
          >
            {t('newUser')}
          </Paper>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={10}>
          <div className="paper">
            <p>
              {t('eLearning.hello')} <b>{t('eLearning.subject')}</b>{' '}
              {t('eLearning.teamMember')}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '16px 0',
                }}
              >
                <img
                  className="heart-img"
                  src="/assets/images/banner-outward-mindset.png"
                  alt="Heart"
                  style={{
                    width: '80%',
                  }}
                />
              </div>
              {t('eLearning.welcome')}{' '}
              <b>
                <i>{t('eLearning.introduction.title')}</i>
              </b>{' '}
              {t('eLearning.introduction.content')}
              <br />
              <br />
              {t('eLearning.instruction.title')}
              <ol>
                <li>
                  {t('eLearning.instruction.line1')}{' '}
                  <a
                    href="https://www.architeck.net/login/index.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.architeck.net/login/index.php
                  </a>
                </li>
                <li>
                  {t('eLearning.instruction.line2')}
                  <ul
                    style={{
                      listStyleType: 'lower-alpha',
                    }}
                  >
                    <li>{t('eLearning.instruction.line2a')}</li>
                    <li>{t('eLearning.instruction.line2b')}</li>
                  </ul>
                </li>
                <li>{t('eLearning.instruction.line3')}</li>
              </ol>
              <b>{t('eLearning.important')}</b>
              {t('eLearning.importantContent')} ({t('eLearning.includingUse')}{' '}
              <b>{t('eLearning.mindsetDiary')}</b> {t('eLearning.andJoin')}{' '}
              <b>{t('eLearning.mindsetCommunitySpace')}</b>)
              <br />
              <br />
              {t('eLearning.dontMiss')}{' '}
              <b>"{t('eLearning.fiveRecommendedModules')}"</b>{' '}
              {t('eLearning.recommendForYou')}
              <ul>
                <li>{t('eLearning.module.one')}</li>
                <li>{t('eLearning.module.two')}</li>
                <li>{t('eLearning.module.three')}</li>
                <li>{t('eLearning.module.four')}</li>
                <li>{t('eLearning.module.five')}</li>
              </ul>
              * {t('eLearning.notice')}
              <br />
              <br />
              {t('eLearning.haveAnyQuestions')}
              &nbsp;
              <a href="mailto:metowe.online@seasiacenter.com">
                metowe.online@seasiacenter.com
              </a>{' '}
              {t('eLearning.inTheCourseAfterward')}{' '}
              <b>{t('eLearning.mindsetCommunitySpace')}</b>
              <br />
              <br />
              {t('eLearning.lastlyEnjoy')} <b>"{t('eLearning.outward')}"</b>{' '}
              {t('eLearning.cantWaitToSee')}
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>

      <NotifyDialog
        type="confirmation"
        title={t('thankForRequestTitle')}
        bodyText={t('thankForRequestBody')}
        okText={t('confirm')}
        cancelText={t('cancel')}
        open={isShowConfirmDialog}
        onCloseDialog={() => setIsShowConfirmDialog(false)}
        onCancelDialogClick={() => setIsShowConfirmDialog(false)}
        onOkDialogClick={onConfirmDialogClick}
      />

      <NotifyDialog
        type="success"
        title={t('requestSentSuccessfully')}
        bodyText={t('pleaseWaitForReply')}
        okText={t('confirm')}
        open={isShowSuccessDialog}
        onCloseDialog={() => setIsShowSuccessDialog(false)}
        onOkDialogClick={() => setIsShowSuccessDialog(false)}
      />

      <NotifyDialog
        type="error"
        title={t('requestSentFail')}
        bodyText={t('systemError')}
        okText={t('confirm')}
        open={isShowErrorDialog}
        onCloseDialog={() => setIsShowErrorDialog(false)}
        onOkDialogClick={() => setIsShowErrorDialog(false)}
      />
    </div>
  )
}

export default ELearningPage
