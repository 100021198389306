import { createContext, useContext, useState } from 'react'
import Loading from './index'

const LoadingContext = createContext({})

const LoadingProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  const showLoading = () => {
    setOpen(true)
  }

  const hideLoading = () => {
    setOpen(false)
  }

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {children}
      <Loading open={open} />
    </LoadingContext.Provider>
  )
}

const useLoading = () => {
  return useContext(LoadingContext)
}

export { LoadingProvider, useLoading }
