import InfiniteScroll from 'react-infinite-scroll-component';
import { styled } from 'styled-components';
import { screen, gutter } from 'styles/variables';

export const WrapperHomePage = styled.div`
  padding: ${gutter.large};
`;

export const WrapperWithGap = styled.div`
  margin-top: 50px;
`;

export const WrapperTransferPoint = styled(InfiniteScroll)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${gutter.default};
  margin: ${gutter.default} 0;
  @media (max-width: ${screen.md.max}) {
    grid-template-columns: 1fr;
  }
`;

export const ItemTransferPoint = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${gutter.default};
  background: ${({ theme }) => theme?.colors?.profile?.panel};
  backdrop-filter: blur(2px);
  border-radius: 20px;
  border: solid 2px ${({ theme }) => theme?.colors?.profile?.borderColor};
  /* margin-bottom: ${gutter.large}; */
  padding: ${gutter.default};
  text-align: center;
`;

export const ImageItem = styled.img`
  width: 100px;
  height: 100px;
`;

export const WrapperItemsDetail = styled.div`
  display: grid;
  grid-template-rows: auto 1fr 1fr;
  align-items: center;
  align-self: center;
  /* margin-bottom: ${gutter.large}; */
`;
