import React, { useState, useEffect } from 'react'
import { useBoBookRequestStore } from 'stores/BackOffice/boBookRequest'
import { fDateTime, fISODateToUTC, fUTCDateToIsoStart } from 'utils/formatTime'
import { rowPerPageOptions } from 'constant/boPagination'

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import bookRequestApi from 'api/bookRequestsApi'
import Swal from 'sweetalert2'
import ExportBtn from 'components/ExportBtn'

const initColumn = [
  {
    id: 'no',
    label: 'No.',
    minWidth: 30,
  },
  {
    id: 'bookNameTH',
    label: 'Book Name (TH)',
    minWidth: 180,
  },
  {
    id: 'bookNameEN',
    label: 'Book Name (EN)',
    minWidth: 180,
  },
  {
    id: 'userName',
    label: 'User Name',
    minWidth: 190,
  },
  {
    id: 'email',
    label: 'User Email',
    minWidth: 200,
  },
  {
    id: 'sent',
    label: 'Sent',
    minWidth: 50,
  },
  {
    id: 'createdAt',
    label: 'Request Date',
    minWidth: 150,
  },
]

const initPaging = {
  username: '',
  userEmail: '',
  bookName: '',
  status: '',
  start: '',
  end: '',
  page: 1,
  pageSize: 10,
}

const txtProps = {
  size: 'small',
  fullWidth: true,
  sx: { backgroundColor: '#fff' },
}

export default function BOBookRequestPage() {
  const [existingSentIds, setExistingSentIds] = useState([])
  const [sentIdValues, setSentIdValues] = useState({})
  const [bookRequests, pagination, fetchBookRequests, batchUpdateBookSent] =
    useBoBookRequestStore((state) => [
      state.bookRequests,
      state.pagination,
      state.fetchBookRequests,
      state.batchUpdateBookSent,
    ])

  const [paging, setPaging] = useState(initPaging)
  const [exportData, setExportData] = useState([])
  const [userNameSearch, setUserNameSearch] = useState("")
  const [userMailSearch, setUserMailSearch] = useState("")
  const [bookName, setBookName] = useState("")
  const [start, setStart] = useState("")
  const [end, setEnd] = useState("")
  const [status, setStatus] = useState("")
  useEffect(() => {
    onFetchBookRequests(initPaging)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onFetchExport()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userNameSearch,userMailSearch,bookName,start,end, status])

  useEffect(() => {
    const sentIds = (bookRequests || [])
      .filter((bq) => bq.has_sent)
      .map((bq) => bq.id)

    setExistingSentIds(sentIds)

    const values = {}
    for (const bq of bookRequests) {
      values[bq.id] = bq.has_sent
    }

    setSentIdValues(values)
  }, [bookRequests])

  const onFetchExport = async () => {
    const reqExport = {
      pageSize: 9999,   status: ''
    }
    if(userNameSearch && userNameSearch !== ""){
      reqExport.username = userNameSearch
    }
    if(userMailSearch && userMailSearch !== ""){
      reqExport.userEmail = userMailSearch
    }
    if(bookName && bookName !== ""){
      reqExport.bookName = bookName
    }
    if(start && start !== ""){
      reqExport.start = fUTCDateToIsoStart(start)
    }
    if(end && end !== ""){
      reqExport.end = fUTCDateToIsoStart(end)
    }
    if(status && status !== ""){
      reqExport.status = status
    }
    const res = await bookRequestApi.getBookRequests(reqExport)
    setExportData(res?.data?.data)
  }

  const onFetchBookRequests = async (newPaging) => {
    // showLoading()
    const result = await fetchBookRequests(newPaging)
    if (result) {
      setPaging(newPaging)
    } else {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
    // hideLoading()
  }

  const onCriteriaChange = (name, value) => {
    switch(name){
      case "username": setUserNameSearch(value)
                      break;
      case "userEmail": setUserMailSearch(value)
                      break;
      case "bookName": setBookName(value)
                      break;
      case "start": setStart(value)
                      break;
      case "end": setEnd(value)
                      break;
      case "status": setStatus(value)
      break;
      default: break;
    }
    setPaging({
      ...paging,
      [name]: value,
    })
  }

  const onSearchClick = async () => {
    await onFetchBookRequests({
      ...paging,
      page: 1,
    })
  }

  const onClearClick = async () => {
    await onFetchBookRequests(initPaging)
  }

  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchBookRequests({
      ...paging,
      page: newPage + 1,
    })
  }

  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchBookRequests({
      ...paging,
      page: 1,
      pageSize: +e.target.value,
    })
  }

  const onSaveClick = async () => {
    // 1. filter only checked
    // 2. filter only not included in existing sent ids
    const sentIds = Object.entries(sentIdValues)
      .filter(([_, value]) => value)
      .filter(([id]) => !existingSentIds.includes(id))
      .map(([id]) => parseInt(id))

    // showLoading()

    const response = await batchUpdateBookSent({
      ids: sentIds,
      has_sent: true,
    })

    if (response) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Update book sent success',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await onFetchBookRequests(paging)
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
    // hideLoading()
  }
  const [dataExport, setDataExport] = useState()
  useEffect(()=>{
    setDataExport( exportData.map((item, index) => {
      return {
        no: index+1,
        booknameTh: item.book.title_th,
        booknameEn: item.book.title_en,
        username: item.user.full_name,
        email: item.user.email,
        created_at: fDateTime(fISODateToUTC(item.created_at))
      }
    }))
  },[exportData])

  return (
    <>
      <Grid container className="mb-20">
        <Grid item md={12}>
          <h2>Book Request List</h2>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-16">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            id="bookrequest-search-userName"
            label="User Name"
            value={paging.username || ''}
            onChange={(e) => onCriteriaChange('username', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            id="bookrequest-search-userEmail"
            label="User Email"
            value={paging.userEmail || ''}
            onChange={(e) => onCriteriaChange('userEmail', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            id="bookrequest-search-bookName"
            label="Book Name (TH/EN)"
            value={paging.bookName || ''}
            onChange={(e) => onCriteriaChange('bookName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth size="small">
            <Select
              id="bookrequest-select-status"
              sx={{ backgroundColor: '#fff' }}
              value={paging.status}
              displayEmpty
              onChange={(e) => onCriteriaChange('status', e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value={'true'}>Sent</MenuItem>
              <MenuItem value={'false'}>Not sent</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="bookrequest-select-startDate"
              disableFuture
              inputFormat="dd/MM/yyyy"
              label="Request Start Date"
              value={paging.start || null}
              onChange={(value) => onCriteriaChange('start', value)}
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="bookrequest-select-endDate"
              disableFuture
              inputFormat="dd/MM/yyyy"
              label="Request End Date"
              value={paging.end || null}
              onChange={(value) => onCriteriaChange('end', value)}
              renderInput={(params) => <TextField {...params} {...txtProps} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="bookrequest-btn-search"
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<SearchIcon />}
            onClick={() => onSearchClick()}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="bookrequest-btn-clear"
            variant="contained"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={() => onClearClick()}
          >
            Clear
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <ExportBtn dataFile={dataExport} pageId={'bookrequest'} />
        </Grid>
      </Grid>

      <Grid container spacing={0} className="mb-30">
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {initColumn.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {bookRequests?.length > 0 && (
                <TableBody>
                  {(bookRequests || []).map((data, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {(pagination.page - 1) * pagination.limit + (i + 1)}
                        </TableCell>
                        <TableCell>{data.book.title_th}</TableCell>
                        <TableCell>{data.book.title_en}</TableCell>
                        <TableCell>{data.user.full_name}</TableCell>
                        <TableCell>{data.user.email}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={sentIdValues[data.id] || false}
                            disabled={existingSentIds.includes(data.id)}
                            onChange={({ target }) => {
                              setSentIdValues((prevState) => ({
                                ...prevState,
                                [data.id]: target.checked,
                              }))
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {data.created_at ? (
                            fDateTime(fISODateToUTC(data.created_at))
                          ) : (
                            <Typography
                              variant="h6"
                              style={{ textAlign: 'center' }}
                            >{`-`}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={pagination.total_count}
            rowsPerPage={paging.pageSize}
            page={pagination.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>

      <Grid container direction="row" justifyContent="flex-end">
        <Grid item xs={6} sm={3}>
          <Button
            id="bookrequest-btn-save"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => onSaveClick()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
