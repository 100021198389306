import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLoading } from 'components/Loading/context'
import CourseForm from './CommonCourseForm'
import courseApi from 'api/inspirationCourseApi'
import Swal from 'sweetalert2'

const EditCoursePage = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const { showLoading, hideLoading } = useLoading()
  const [course, setCourse] = useState(null)
  let pathname = window.location.pathname;
  pathname = pathname.substring(7)
  pathname = pathname.substring(0,pathname.indexOf("/")).toUpperCase()
  const id = +searchParams.get('id')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setTimeout(()=>{
      checkAndLoad()
      return () => {}
    },500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const checkAndLoad = async () => {
    if (!id || id <= 0) {
      window.location.href = '/bo/dm/'+ pathname.toLowerCase() +'/course'
      } else {
      await onFetchCourse(id)
      return ()=>{}
    }
  }
  const onFetchCourse = async (id) => {
    showLoading()
    try {
      const result = await courseApi.getCourseById(id)
      setCourse(result.data.data)
      return 
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      hideLoading()
    }
  }

  const handleSave = async (inputData) => {
    // showLoading()
    try {
      const result = await courseApi.updateCourseById({
        ...inputData,
        id: +searchParams.get('id'),
      })
      if (result.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Update Course success',
        }).then((res)=>{
          if(res.isConfirmed){
            window.location.href = '/bo/dm/'+ pathname.toLowerCase() +'/course'
          }
        })
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      // hideLoading()
    }
  }

  return <CourseForm mode="Edit" course={course} onSave={handleSave} />
}

export default EditCoursePage
