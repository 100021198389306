import variables from 'styles/themes/_variables';

const colors = {
  background: {
    default: '#C5E3F7'
  },
  primary: {
    default: '#1B5BAE',
    secondary: '#9EABBC',
    text: '#fff'
  },
  footer: {
    default: '#1B5BAE',
    text: '#FFF'
  },
  profile: {
    panel: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3)',
    borderColor: '#C3D3FF'
  },
  font: {
    default: '#0B2B92',
    secondary: '#9EABBC'
  },
  divider: {
    default: '#9EABBC'
  },
  accumulatePoint: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3)',
    border: '1px solid #C3D3FF',
    balancePoint: '#F9595F',
    accumulatePoint: '#54C26E'
  },
  navbar: {
    primary: '#1B5BAE',
    active: '#6E9CD9',
    text: '#fff'
  }
}

const button = {
  primary: {
    default: '#1B5BAE'
  }
}

const panel = {
  primary: {
    default: '#fff'
  }
}

const theme = {
  ...variables,
  colors,
  button,
  panel
}

export default theme;
