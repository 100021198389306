import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { LinearProgress,  } from '@mui/material'
import routes  from './routes'
import WebLayout from 'layout/Web/AppLayout'
import NoLayout from 'layout/Web/NoLayout'
import BOMainLayout from 'layout/BackOffice/BoMainLayout'
import { ErrorBoundary } from 'providers/ErrorBoundary'
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/GlobalStyle'
import { checkRenderTheme } from 'application/theme/configTheme'

const theme = checkRenderTheme('default');

const App = () => {
  return (
    <Suspense fallback={<LinearProgress color="inherit" size={50} />}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
          <GlobalStyle />
            <ErrorBoundary>
              <Routes>
                {Array.isArray(routes) && routes?.map(
                  ({ path, element: Element, layout }, index) => {
                    let element
                    if (layout === 'web_layout') {
                      element = <WebLayout>{Element}</WebLayout>
                    } else if (layout === 'bo_layout') {
                      element = <BOMainLayout>{Element}</BOMainLayout>
                    } else if (layout === 'no_layout') {
                      element = <NoLayout>{Element}</NoLayout>
                    } else {
                      element = Element
                    }

                    return <Route key={index} path={path} element={element} />
                  }
                )}
              </Routes>
            </ErrorBoundary>
          </ThemeProvider>
        </BrowserRouter>
      </Suspense>
  )
}

export default App
