import axios from 'axios'
import { useAuthStore } from 'stores/authStore'
import  routePaths  from 'routesPath'
import config from '../config'
import history from '../utils/history'

export function createHttpClient() {
  const instance = axios.create({
    baseURL: config.me2we.apiBaseUrl,
  })

  instance.interceptors.request.use((axiosConfig) => {
    const token = useAuthStore.getState().idToken || ''
    axiosConfig.headers.Authorization = `Bearer ${token}`
    return axiosConfig
  })

  instance.interceptors.response.use(
    (res) => {
      return res
    },
    (err) => {
      console.log('err: ', err)
      const clearAuth = useAuthStore.getState().clearAuth

      const { response } = err

      if (response.data && response.data.error) {
        const { code, message, errors } = response.data.error

        if (response.status === 401) {
          clearAuth()
          history.push(routePaths.LOGIN)
        } else {
          return Promise.reject({
            message: message || '',
            status: response.status,
            code: code || '',
            errors: errors || [],
          })
        }
      }

      return Promise.reject(err)
    }
  )

  return instance
}
