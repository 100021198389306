import { create } from 'zustand'
import whitelistApi from 'api/whitelistRequestApi'
import { initPagination } from 'constant/boPagination'

export const useBoWhitelistStore = create((set, get) => ({
  groupId: 0,
  groupName: "",
  whitelist: [],
  listforadd: [],
  groupList: [],
  pageforadd: initPagination,
  pagination: initPagination,
  fetchUserByGroup: async (params) => {
    let whitelistDatas = []
    let paginationData = initPagination
    try {
      const response = await whitelistApi.getUserByGroup(params)
      const { data, pagination } = response.data
      whitelistDatas = data || []
      paginationData = pagination
    } catch (error) {
      throw new Error(error)
    } finally {
      set({
        whitelist: whitelistDatas,
        pagination: paginationData,
        groupId: params.group_id === null ? 0 : whitelistDatas[0]?.user_group.id,
        groupName: params.group_id === null ? 'All' : whitelistDatas[0]?.user_group.group_name
      })
    }
  },
  fetchUserForAdd: async (params) => {
    let whitelistDatas = []
    let paginationData = initPagination
    try {
      const response = await whitelistApi.getUserByGroup(params)
      const { data, pagination } = response.data
      whitelistDatas = data
      paginationData = pagination
    } catch (error) {
      throw new Error(error)
    } finally {
      set({
        listforadd: whitelistDatas,
        pageforadd: paginationData,
        groupId: params.group_id === null ? 0 : whitelistDatas[0].user_group.id,
        groupName: params.group_id === null ? 'All' : whitelistDatas[0].user_group.group_name
      })
    }
  },
  fetchUserGroupList: async () => {
    let res = {}
    try {
        const response = await whitelistApi.getUserGroupList()
        if( response.status === 200){
            res = response.data
        }
    } catch(err){
        console.log(err)
    } finally {
      set({
        groupList : res
      })
    }
  },
  fetchUserGroupById: async (id) => {
    let resultData = {}
    try {
      const response = await whitelistApi.getUserGroupById(id)
      const { data } = response.data
      resultData = data || {}
    } catch {}
    return resultData
  },
  createUserGroup: async (data) => {
    const response = await whitelistApi.createUserGroup(data)
    return response.status === 200
  },
  updateUserGroup: async (data) => {
    const response = await whitelistApi.updateGroupUserByUserId(data)
    return response.status
  },
  deleteUserGroup: async (id) => {
    try {
      const response = await whitelistApi.deleteUserGroup(id)
      return response.status === 200
    } catch (error) {
      return false
    }
  },
}))
