import { create } from 'zustand'
import booksApi from 'api/booksApi'
import { initPagination } from 'constant/boPagination'

export const useBoBookStore = create((set, get) => ({
  books: [],
  pagination: initPagination,
  fetchBooks: async (params) => {
    let bookDatas = []
    let paginationData = initPagination
    try {
      const response = await booksApi.getBooks(params)
      const { data, pagination } = response.data
      bookDatas = data
      paginationData = pagination
    } catch (error) {
      throw new Error(error)
    } finally {
      set({
        books: bookDatas,
        pagination: paginationData,
      })
    }
  },
  fetchBookById: async (id) => {
    let resultData = {}
    try {
      const response = await booksApi.getBookById(id)
      const { data } = response.data
      resultData = data || {}
    } catch {}
    return resultData
  },
  createBook: async (data) => {
    const response = await booksApi.createBook(data)
    return response.status === 200
  },
  updateBook: async (data) => {
    const response = await booksApi.updateBook(data)
    return response.status === 200
  },
  deleteBook: async (id) => {
    try {
      const response = await booksApi.deleteBook(id)
      return response.status === 200
    } catch (error) {
      return false
    }
  },
}))
