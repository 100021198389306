import React, { useEffect, useState, useRef } from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import Swal from 'sweetalert2'
import { rowPerPageOptions } from 'constant/boPagination'
import { useBoELearningStore } from 'stores/BackOffice/boELearningStore'
import ExportBtn from 'components/ExportBtn'
import eLearningApi from 'api/elearningApi'

const initColumns = [
  {
    id: 'no',
    label: 'No.',
    minWidth: 30,
  },
  {
    id: 'userName',
    label: 'User Name',
    minWidth: 150,
  },
  {
    id: 'email',
    label: 'User Email',
    minWidth: 150,
  },
  {
    id: 'progress',
    label: 'Progess',
    minWidth: 50,
    align: 'center',
  },
]

const initPaging = {
  userName: '',
  userEmail: '',
  page: 1,
  pageSize: 5,
}

const acceptedFiles = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/csv',
]

export default function BOELearningPage() {
  const [eLearnings, pagination, fetchELearnings, importProgress] =
    useBoELearningStore((state) => [
      state.eLearnings,
      state.pagination,
      state.fetchELearnings,
      state.importProgress,
    ])
  const [paging, setPaging] = useState(initPaging)
  const [openDialogImport, setOpenDialogImport] = useState(false)
  const [file, setFile] = useState()
  const inputFileRef = useRef(null)

  useEffect(() => {
    onFetchELearnings(initPaging)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [exportData, setExportData] = useState([])
  const [usernameSearch, setUserNameSearch] = useState("")
  const [userMailSearch, setUserMailSearch] = useState("")
  const onFetchExport = async () => {
    const reqExport = {
      pageSize: 9999
    }
    if(usernameSearch && usernameSearch !== ""){
      reqExport.userName = usernameSearch
    }
    if(userMailSearch && userMailSearch !== ""){
      reqExport.userEmail = userMailSearch
    }
    const res = await eLearningApi.getELearnings(reqExport)
    setExportData(res?.data?.data)
  }
  useEffect(() => {
    onFetchExport()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[usernameSearch,userMailSearch])
  const onFetchELearnings = async (newPaging) => {
    try {
      await fetchELearnings(newPaging)
      setPaging(newPaging)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'System error.',
      })
    }
  }

  const handleCriteriaChange = (name, value) => {
    if(name && name === "userName"){
      setUserNameSearch(value)
    }
    if(name && name === "userEmail"){
      setUserMailSearch(value)
    }
    setPaging({
      ...paging,
      [name]: value,
    })
  }

  const handleSearchClick = async () => {
    await onFetchELearnings({
      ...paging,
      page: 1,
    })
  }

  const handleClearClick = async () => {
    await onFetchELearnings(initPaging)
  }

  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchELearnings({
      ...paging,
      page: newPage + 1,
    })
  }

  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchELearnings({
      ...paging,
      page: 1,
      pageSize: +e.target.value,
    })
  }

  const handleImportClick = () => {
    setOpenDialogImport(true)
  }

  const handleCloseDialogImport = () => {
    setOpenDialogImport(false)
    setFile()
    inputFileRef.current.value = null
  }

  const handleFileChange = (e) => {
    e.preventDefault()
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const validateFile = (fileData) => {
    if (acceptedFiles.indexOf(fileData.type) === -1) {
      Swal.fire({
        type: 'error',
        text: `ไม่รองรับไฟล์ ${fileData.name} รูปแบบไฟล์ที่รองรับ: xlsx, xls, csv`,
      })
    }
  }

  const handleUploadClick = async () => {
    if (!file) {
      return
    }
    validateFile(file)

    const formData = new FormData()
    formData.append('File', file)

    await importElearningProgress(formData)
    handleCloseDialogImport()
    await onFetchELearnings(initPaging)
  }

  const importElearningProgress = async (data) => {
    const result = await importProgress(data)
    if (result) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Import progress success',
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
  }
  const [dataExport, setDataExport] = useState()
  useEffect(()=>{
    setDataExport( exportData.map((item, index) => {
      return {
        no: index+1,
        userName: item.user.full_name,
        email: item.user.email,
        progress: item.progress
      }
    }))
  },[exportData])


  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="mb-20"
      >
        <Grid item>
          <h2>E-Learning List</h2>
        </Grid>
        <Grid item md={2}>
          <Button
            id="elearning-btn-import"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleImportClick()}
          >
            <Typography variant="button">Import Data</Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} md={3}>
          <TextField
            id="elearning-search-userName"
            name="searchUsername"
            label="User Name (TH/EN)"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#fff' }}
            value={paging?.userName || ''}
            onChange={(e) => handleCriteriaChange('userName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="elearning-search-userEmail"
            name="searchEmail"
            label="User Email"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#fff' }}
            value={paging?.userEmail}
            onChange={(e) => handleCriteriaChange('userEmail', e.target.value)}
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <Button
            data-testid="elearning-btn-search"
            id="elearning-btn-search"
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<SearchIcon />}
            onClick={() => handleSearchClick()}
          >
            <Typography variant="button">Search</Typography>
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            id="elearning-btn-clear"
            variant="contained"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={() => handleClearClick()}
          >
            <Typography variant="button">Clear</Typography>
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <ExportBtn dataFile={dataExport} pageId={'elearning'} />
        </Grid>
      </Grid>

      <Grid container>
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {initColumns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      padding={column.disablePadding ? 'none' : 'normal'}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {eLearnings?.length > 0 && (
                <TableBody>
                  {(eLearnings || []).map((data, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {(pagination.page - 1) * pagination.limit + (i + 1)}
                        </TableCell>
                        <TableCell>{data.user.full_name}</TableCell>
                        <TableCell>{data.user.email}</TableCell>
                        <TableCell
                          sx={{ textAlign: 'center' }}
                        >{`${data.progress}%`}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={pagination.total_count}
            rowsPerPage={paging.pageSize}
            page={pagination.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>

      <Dialog
        open={openDialogImport}
        onClose={handleCloseDialogImport}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Import Data</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            flexDirection="column"
            textAlign="center"
            alignItems="center"
            sx={{ mt: 3 }}
          >
            <Grid item md={12}>
              <div
                style={{
                  marginBottom: '8px',
                  borderRadius: '4px',
                  border: '2px dotted #d3d6da',
                  backgroundColor: '#f7f7f8',
                  padding: '24px',
                }}
              >
                <input
                  id="import-file-progress"
                  ref={inputFileRef}
                  type="file"
                  accept={acceptedFiles.join(',')}
                  onChange={handleFileChange}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ py: 3, justifyContent: 'center' }}>
          <Button
            id="elearning-btn-import-ok"
            variant="contained"
            sx={{
              minWidth: '125px',
            }}
            onClick={() => handleUploadClick()}
          >
            Import
          </Button>
          <Button
            id="elearning-btn-import-cancel"
            variant="outlined"
            sx={{
              minWidth: '125px',
            }}
            onClick={() => handleCloseDialogImport()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
