import * as React from 'react'
import { useTranslation } from 'react-i18next'

import './contact.scss'

const contactList = [
  {
    name: 'Nayada Duangwiset',
    position: 'Officer, Enterprise Transformation Support',
    office: 'OET',
    email: 'nayadad@pttep.com',
    work: '',
    imagePath: '/assets/images/nayada-profile.png',
  },
  {
    name: 'Shompoonut Treechot',
    position: 'Agile Coach',
    office: 'OET',
    email: 'ShompoonutT@pttep.com',
    work: '',
    imagePath: '/assets/images/shompoonut-profile.jpg',
  },
  {
    name: 'Phattarakorn Rangsriwong',
    position: 'Analyst',
    office: 'OET',
    email: 'phattarakornr@pttep.com',
    work: '',
    imagePath: '/assets/images/phattarakorn-profile.png',
  },
]

const ContactPage = () => {
  const { t } = useTranslation()

  return (
    <div className="contact-container">
      <div className="page-header mb-20">{t('contact')}</div>
      {contactList.map((data, index) => {
        return (
          <div key={index} className="contact-box-container">
            <div className="contact-profile-img">
              <img src={data.imagePath} alt="ProfileImage" />
            </div>
            <div>
              <table className="contact-table">
                <tbody>
                  <tr>
                    <td className="txt-primary">{t('name')}:</td>
                    <td className="txt-secondary">{data.name}</td>
                  </tr>
                  <tr>
                    <td className="txt-primary">{t('position')}:</td>
                    <td className="txt-secondary">{data.position}</td>
                  </tr>
                  <tr>
                    <td className="txt-primary">{t('office')}:</td>
                    <td className="txt-secondary">{data.office}</td>
                  </tr>
                  <tr>
                    <td className="txt-primary">{t('email')}:</td>
                    <td className="txt-secondary">{data.email}</td>
                  </tr>
                  { data.work !== "" &&
                    <tr>
                      <td className="txt-primary">{t('work')}:</td>
                      <td className="txt-secondary">{data.work}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ContactPage
