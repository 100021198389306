let routePaths = {
    // Web
    ROOT: '/',
    LOGIN: '/login',
    LOGIN_DIRECT: '/login/:page',
    LOGIN_DIRECT2: '/login/:page/:page',
    LOGIN_DIRECT_INS_WORKSHOP: '/login/:page/:page/:workshopId',
    HOME: '/home',
    PROFILE: '/profile',
    BOOKS: '/books',
    ADDFUEL: '/addfuel',
    WHATISMETOWE: '/whatismetowe',
    E_LEARNING: '/elearning',
    INSPIRATIONS: '/inspirations',
    WORKSHOPS: '/inspirations/workshops',
    CONTACT: '/contact',
    PRIZE_COLLECTION: '/prizecollection',
    TRANSFER_POINT: '/point-transfer',
    WETOGETHER: '/wetogether',
    EMI: '/wetogether/emi',
    EMIWORKSHOP: '/wetogether/emi/workshops',
    EPSPIRIT: '/wetogether/epspirit',
    SD: '/wetogether/sd',
    CHECK_IN: '/check-in',
    LEADER_SHIP: '/leadership',
    COMMUNITY: '/community',
    COMMUNITY_BY_ID: '/community/:id',
    DYNAMICS_COURSE_BY_ID: '/course/:course',
    DYNAMICS_WORKSHOP_BY_ID: '/course/workshop/:workshopId',
    // Back Office
    BO_HOME: '/bo',
    // BO_LOGIN: '/bo/login',
    BO_BOOK: '/bo/book',
    BO_BOOK_NEW: '/bo/book/new',
    BO_BOOK_EDIT: '/bo/book/:id/edit',
    BO_BOOK_REQUEST: '/bo/bookrequest',
    BO_ELEARNING: '/bo/elearning',
    BO_INSPIRATION_COURSE: '/bo/inspiration/course',
    BO_INSPIRATION_COURSE_ADD: '/bo/inspiration/course/add',
    BO_INSPIRATION_COURSE_EDIT: '/bo/inspiration/course/edit',
    BO_INSPIRATION_WORKSHOP: '/bo/inspiration/workshop',
    BO_INSPIRATION_WORKSHOP_ADD: '/bo/inspiration/workshop/add',
    BO_INSPIRATION_WORKSHOP_EDIT: '/bo/inspiration/workshop/edit',
    BO_INSPIRATION_REQUEST: '/bo/inspiration/request',
    BO_REWARD: '/bo/reward',
    BO_REWARD_NEW: '/bo/reward/new',
    BO_REWARD_EDIT: '/bo/reward/:id/edit',
    BO_REWARD_REDEMPTION: '/bo/rewardredemption',
    BO_EMI_COURSE: '/bo/emi',
    BO_EMI_WORKSHOP: '/bo/emi/workshop',
    BO_EMI_WORKSHOP_ADD: '/bo/emi/workshop/add',
    BO_EMI_WORKSHOP_EDIT: '/bo/emi/workshop/edit',
    BO_EMI_REQUEST: '/bo/emi/request',
    BO_WHITELIST: '/bo/whitelist',
    BO_WHITELIST_ADD: '/bo/whitelist/add',
    BO_REPORT: '/bo/report',
    BO_POINT_TRANSFER: '/bo/point',
    BO_LEADER_WORKSHOP: '/bo/leader/workshop',
    BO_LEADER_WORKSHOP_ADD: '/bo/leader/workshop/add',
    BO_LEADER_WORKSHOP_EDIT: '/bo/leader/workshop/edit',
    BO_LEADER_REQUEST: '/bo/leader/request',
    BO_COMMUNITY_FORBIDDEN_WORD: '/bo/community/forbidden-word',
    BO_COMMUNITY_HASHTAG: '/bo/community/hashtag',
    BO_DYNAMIC_MENU: '/bo/dm/:menu',
    BO_DYNAMIC_WORKSHOP: '/bo/dm/:menu/workshop',
    BO_DYNAMIC_WORKSHOP_ADD: '/bo/dm/:menu/workshop/add',
    BO_DYNAMIC_WORKSHOP_EDIT: '/bo/dm/:menu/workshop/edit',
    BO_DYNAMIC_REQUEST: '/bo/dm/:menu/request',
    BO_DYNAMIC_COURSE: '/bo/dm/:menu/course',
    BO_DYNAMIC_COURSE_ADD: '/bo/dm/:menu/course/add',
    BO_DYNAMIC_COURSE_EDIT: '/bo/dm/:menu/course/edit',
    //External URL
    NEWNORMAL: '/newnormal',
    VERSION: '/version',
    // Other
    ACCESS_DENIED_403: '/403',
    ALL: '*',
  }
const mockDataRoutePath = {
    BO_SPIRIT_WORKSHOP: '/bo/epspirit/workshop',
    BO_SPIRIT_WORKSHOP_ADD: '/bo/epspirit/workshop/add',
    BO_SPIRIT_WORKSHOP_EDIT: '/bo/epspirit/workshop/edit',
    BO_SPIRIT_REQUEST: '/bo/epspirit/request',
    BO_TEST_WORKSHOP: '/bo/test/workshop',
    BO_TEST_WORKSHOP_ADD: '/bo/test/workshop/add',
    BO_TEST_WORKSHOP_EDIT: '/bo/test/workshop/edit',
    BO_TEST_REQUEST: '/bo/test/request',
  }

  routePaths = {
    ...routePaths,
    ...mockDataRoutePath
  }

  export default routePaths
