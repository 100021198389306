import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthStore } from 'stores/authStore'
import { Grid } from '@mui/material'
import moment from 'moment';

import ProfileBox from 'components/ProfileBox'
import CustomButton from 'components/CustomButton'
import NotifyDialog from 'components/NotifyDialog'
import Loading from 'components/Loading'

import { useUserListStore } from '../../stores/userListStore'
import SearchField from '../../components/SearchField'
import SegmentedControl from '../../components/Segment'
import {
  WrapperHomePage,
  WrapperTransferPoint,
  ItemTransferPoint,
  ImageItem,
  WrapperItemsDetail,
  WrapperWithGap
} from './point-transfer.style'
import { Text } from 'components/Text'
import ProfileNav from 'components/ProfileNav';
import { useLoading } from '../../components/Loading/context'

export default function PointTransferPage() {
  const { t } = useTranslation()
  const { showLoading, hideLoading } = useLoading()

  const [user, fetchUserInfo] = useAuthStore((state) => [
    state.user,
    state.fetchUserInfo,
  ])

  const [userList, clearUserList, fetchUserList, transferToUser, historyList, pointHistoryList] = useUserListStore(
    (state) => [
      state.userList,
      state.clearUserList,
      state.fetchUserList,
      state.transferToUser,
      state.historyList,
      state.pointHistoryList
    ]
  )
  const [selectUser, setSelectUser] = useState(null)
  const [userSearch, setUserSearch] = useState([])

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [isShowSuccessDialog, setIsShowSuccessDialog] = useState(false)
  const [isShowErrorDialog, setIsShowErrorDialog] = useState(false)
  const [msgErrDialog, setMsgErrDialog] = useState('')
  const [segmentVal, setSegmentVal] = useState("transfer");
  const [searchVal, setSearchVal] = useState('');
  const [amountPoint, setAmountPoint] = useState(0)

  useEffect(() => {
    clearUserList()
    setUserSearch([])
    fetchUserList()
    pointHistoryList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSearchUser = async (e) => {
    e.preventDefault()
    setIsShowConfirmDialog(false)
    setSearchVal(e.target.value)
  }

  useEffect( () => {
    const delayDebounceFn = setTimeout( async () => {
      showLoading()
      // Send Axios request here
      if (searchVal.length > 0) {
        const result = await fetchUserList(searchVal);
        if (result) {
          setUserSearch(result)
          hideLoading()
        } else {
          hideLoading()
        }
      } else if (searchVal.length === 0) {
        setUserSearch([])
        hideLoading()
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal])

  const onTransferClick = (e, id) => {
    e.preventDefault()
    setSelectUser(id)
    setIsShowConfirmDialog(true)
  }

  const onConfirmDialogClick = async (e) => {
    e.preventDefault()
    setIsShowConfirmDialog(false)
    if (amountPoint <= user.balance_points) {
      try {
        const result = await transferToUser(selectUser?.id, parseInt(amountPoint))
        if (result.data === 'Success') {
          setIsShowSuccessDialog(true)
          fetchUserInfo()
          pointHistoryList()
        } else {
          console.log(`transferToUser err => ${result.error.message}`)
          setIsShowErrorDialog(true)
          setMsgErrDialog(t('systemError'))
        }
      } catch {
        setIsShowErrorDialog(true)
        setMsgErrDialog(t('systemError'))
      }
    } else {
      setIsShowErrorDialog(true)
      setMsgErrDialog(t('pointErr'))
    }
  }

  const onChangeInput = async (e) => {
    e.preventDefault()
    setAmountPoint(e.target.value)
  }

  return (
    <WrapperHomePage>
      <ProfileBox />
      <ProfileNav />
      <WrapperWithGap>
        <SegmentedControl
          name="group-1"
          callback={(val) => setSegmentVal(val)}
          controlRef={useRef()}
          segments={[
            {
              label: t('transferPoint'),
              value: "transfer",
              ref: useRef()
            },
            {
              label: t('history'),
              value: "history",
              ref: useRef()
            },
          ]}
        />
        { segmentVal === 'transfer' &&
            <SearchField
              id="btnSearch"
              data-testid="btnSearch"
              onChange={onSearchUser}
              placeholder='search_name_friend'
            />
        }

        <WrapperTransferPoint
          dataLength={userList.length}
          loader={<Loading open />}
        >
          {(segmentVal === 'transfer' ? (userSearch || []) : (historyList || [])).map((item, i) => {
            const disableRedeemBtn = user.balance_points === 0
            return (
              <ItemTransferPoint key={i}>
                <ImageItem src={segmentVal === 'transfer' ? '/assets/images/userList.png': '/assets/images/like.png'} className="pc-image" alt="User" />
                <WrapperItemsDetail>
                  <Text bold subtitle>{segmentVal === 'transfer' ? item.full_name : item.receiver_name}</Text>
                  { segmentVal === 'transfer' && ( <Text small>{t('group')}: {item?.user_group?.group_name || '-'}</Text> )}
                  { segmentVal === 'history' && ( <Text small>{item?.point_transfer} {t('point')} </Text> )}
                  { segmentVal === 'history' && ( <Text small>{moment(item?.created_at).format('DD/MM/YYYY HH:mm')} </Text> )}
                  { segmentVal === 'transfer' && (
                    <Grid container>
                      <Grid item xs={12}>
                        <CustomButton
                          id={`btnRedeem-${i}`}
                          data-testid={`btnRedeem-${i}`}
                          color="primary"
                          variant="contained"
                          disabled={disableRedeemBtn}
                          onClick={(e) => onTransferClick(e, item)}
                        >
                          {t('transferPoint')}
                        </CustomButton>
                      </Grid>
                    </Grid>
                  )}
                </WrapperItemsDetail>
              </ItemTransferPoint>
            )
          })}
        </WrapperTransferPoint>
      </WrapperWithGap>
      <NotifyDialog
        id="dialogAskToConfirmRedeem"
        type="confirmation"
        title={t('doYouWant')}
        bodyText={`${t('transferPointToUser')} ${selectUser?.full_name} ?`}
        okText={t('confirm')}
        cancelText={t('cancel')}
        withInput={true}
        inputLabel={t('point')}
        inputType={'number'}
        pattern={'[0-9]*'}
        onChangeInput={onChangeInput}
        // iconImgPath={selectUser?. || '-'}
        open={isShowConfirmDialog}
        onCloseDialog={() => setIsShowConfirmDialog(false)}
        onCancelDialogClick={() => setIsShowConfirmDialog(false)}
        onOkDialogClick={onConfirmDialogClick}
      />

      <NotifyDialog
        id="dialogConfirmRedeem"
        type="success"
        title={t('success')}
        // bodyText={t('success')}
        okText={t('confirm')}
        // iconImgPath={selectReward?.image || '-'}
        open={isShowSuccessDialog}
        onCloseDialog={() => setIsShowSuccessDialog(false)}
        onOkDialogClick={() => setIsShowSuccessDialog(false)}
      />

      <NotifyDialog
        id="dialogError"
        type="error"
        title={t('requestSentFail')}
        bodyText={msgErrDialog}
        okText={t('confirm')}
        open={isShowErrorDialog}
        onCloseDialog={() => setIsShowErrorDialog(false)}
        onOkDialogClick={() => setIsShowErrorDialog(false)}
      />
    </WrapperHomePage>
  )
}
