import heic2any from 'heic2any';

function renderBase64Img(sizeFile, file, setTempBase64, hideLoading) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = (el) => {
    const image = new Image();
    image.src = reader.result;
    image.onload = function() {
      if (image && (image.width > 500 || image.height > 500)) {
        console.log('if > 500')
        const imgData = el.target.result
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const newImg = document.createElement('img')
        newImg.setAttribute('src', imgData)
        newImg.onload = () => {
          const imgSize = (1024 * 1024).toFixed(1)
          const actualWidth = newImg.width
          const actualHeight = newImg.height
          let imgWidth
          let imgHeight

          if (sizeFile < 1.3) {
            console.log('sizeFile < 1.3mb')
            imgWidth = Math.floor(
              actualWidth / (imgSize < 1 ? 1 : imgSize > 2 ? 1 : imgSize)
            )
            imgHeight = Math.floor(
              actualHeight / (imgSize < 1 ? 1 : imgSize > 2 ? 1 : imgSize)
            )
          } else {
            console.log('sizeFile > 1.3mb')
            imgWidth = Math.floor(
              actualWidth / (imgSize < 1 ? 1 : imgSize > 7 ? 6 : imgSize)
            )
            imgHeight = Math.floor(
              actualHeight / (imgSize < 1 ? 1 : imgSize > 7 ? 6 : imgSize)
            )
          }
          canvas.width = imgWidth
          canvas.height = imgHeight
          ctx.drawImage(newImg, 0, 0, imgWidth, imgHeight)
          const imgUrl = canvas.toDataURL('image/webp')
          setTempBase64(imgUrl)
          hideLoading()
        }
      } else {
        setTempBase64(reader.result)
        hideLoading()
      }
    };
  }
}

export default function handleBase64img(e, setTempBase64, setErrImg, showLoading, hideLoading) {
  if (e && e.target.files[0] && setTempBase64) {
    showLoading()
    const file = e.target.files[0]
    const fileNameExt = file.name.substr(file.name.lastIndexOf('.') + 1)
    console.log('file : ', file)
    console.log('fileNameExt : ', fileNameExt)
    const size = (file.size / (1024 * 1024)).toFixed(2)
    // if (parseFloat(size) > 10) {
    //   setErrImg(true)
    //   hideLoading()
    // } else {
    setTempBase64(null)
    if(fileNameExt === 'HEIC' || fileNameExt === 'HEIF') {
      console.log('if === HEIC || HEIF')
      heic2any({
        blob: file,
        toType: 'image/jpg',
      })
      .then(function (resultBlob) {
        console.log('then HEIC2ANY')
        renderBase64Img(parseFloat(size), resultBlob, setTempBase64, hideLoading)
      })
      .catch(function (err) {
          console.log(err.code)
          console.log(err.message)
      });
    } else {
      renderBase64Img(parseFloat(size), file, setTempBase64, hideLoading)
    }
    // }
  }
}
