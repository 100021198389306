import styled from 'styled-components'
import { screen } from 'styles/variables';

export const WrapperAppLayout = styled.main`
  background-image: url('/assets/images/bg-main-layout-2.png');
  background-size: cover;
  margin-bottom: 56px;
`;

export const WrapperBody = styled.div`
  margin: auto;
  min-height: calc(100vh - 56px);
  width: 100vw;
  @media (min-width: ${screen.lg.max}) {
    max-width: 1368px;
  }

  @media (max-width: ${screen.md.max}) {
    max-width: 768px;
  }

  @media (max-width: ${screen.sm.max}) {
    background-image: url('/assets/images/bg-main-layout-mb.png');
    background-size: cover;
  }
`;
