import { create } from 'zustand'
import communityApi from 'api/communityApi'
import { initPagination } from 'constant/boPagination'

export const useBoCommunityStore = create((set, get) => ({
  wordList: [],
  hashtag: [],
  pagination: initPagination,
  hashtagPagination: initPagination,
  fetchWords: async (params) => {
    let wordData = []
    let paginationData = initPagination
    try {
      const response = await communityApi.getWord(params)
      const { data, pagination } = response.data
      wordData = data
      paginationData = pagination
    } catch (error) {
      throw new Error(error)
    } finally {
      set({
        wordList: wordData,
        pagination: paginationData,
      })
    }
  },
  createWord: async (params) => {
    const response = await communityApi.createWord(params)
    return response.data
  },
  deleteWord: async (params) => {
    const response = await communityApi.deleteWord(params)
    return response.data
  },
  fetchHashtag: async (params) => {
    let hashtagData = []
    let paginationData = initPagination
    try {
      const response = await communityApi.getHashtag(params)
      const { data, pagination } = response.data
      hashtagData = data
      paginationData = pagination
    } catch (error) {
      throw new Error(error)
    } finally {
      set({
        hashtag: hashtagData,
        hashtagPagination: paginationData,
      })
    }
  },
  createHashtag: async (params) => {
    const response = await communityApi.createHashtag(params)
    return response.data
  },
  pinHashtag: async (params) => {
    const response = await communityApi.pinHashtag(params)
    return response.data
  },
  unpinHashtag: async (params) => {
    const response = await communityApi.unpinHashtag(params)
    return response.data
  }
}))
