const screen = {
  xxs: {
    max: '349.98px'
  },
  xs: {
    max: '575.98px'
  },
  sm: {
    up: '576px',
    max: '767.98px'
  },
  md: {
    up: '768px',
    max: '991.98px'
  },
  lg: {
    up: '992px',
    max: '1199.98px'
  },
  xl: {
    up: '1200px',
    max: '1399.98px'
  },
  hd: {
    up: '1400px'
  }
};

const gutter = {
  default: '20px',
  xs: '10px',
  small: '15px',
  large: '30px'
}

const borderRadius = {
  default: '14px',
  large: '30px'
}

const zIndex = {
  footer: 999
}

const boxShadow = {
  default: '0px 0px 10px 0px rgba(0,0,0,0.3)'
}

const fontSize = {
 title: '24px',
 subTitle: '18px',
 default: '16px',
 small: '14px',
 xs: '12px'
}

export {
  screen,
  gutter,
  borderRadius,
  zIndex,
  boxShadow,
  fontSize
};
