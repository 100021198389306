export const errorLabel = {
    course_id: 'Please fill in Course',
    image: 'Please fill in Image',
    title_th: 'Please fill in Title TH',
    title_en: 'Please fill in Title EN',
    instructor_th: 'Please fill in Instructor TH',
    instructor_en: 'Please fill in Instructor EN',
    points: 'Please fill in Points',
    event_date: 'Please fill in Event Date',
  }
export const initColumns = [
    {
      id: 'no',
      label: 'No.',
      minWidth: 30,
    },
    {
      id: 'time',
      label: 'Time',
      minWidth: 80,
    },
    {
      id: 'quota',
      label: 'Quota',
      minWidth: 100,
    },
    {
      id: 'action',
      label: '',
      minWidth: 80,
    },
  ]
export  const initColumnsIndex = [
    {
      id: 'no',
      label: 'No.',
      minWidth: 30,
    },
    {
      id: 'image',
      label: 'Image',
      minWidth: 80,
    },
    {
      id: 'title_th',
      label: 'Name (TH)',
      minWidth: 100,
    },
    {
      id: 'title_en',
      label: 'Name (EN)',
      minWidth: 100,
    },
    {
      id: 'event_date',
      label: 'Event Date',
      minWidth: 120,
    },
    {
      id: 'session',
      label: 'Session',
      minWidth: 150,
    },
    {
      id: 'action',
      label: '',
      minWidth: 80,
    },
  ]
export let initPaging = {
  title: '',
  instructor: '',
  page: 1,
  pageSize: 5
}
 export const txtProps = {
    size: 'small',
    fullWidth: true,
    sx: { backgroundColor: '#fff' },
  }
