import React, { useState, useEffect } from 'react'
import { useBoBookStore } from 'stores/BackOffice/boBookStore'
import { rowPerPageOptions } from 'constant/boPagination'
import  routePaths  from 'routesPath'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  TextField,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Swal from 'sweetalert2'
import ExportBtn from 'components/ExportBtn'
import booksApi from 'api/booksApi'

const initColumns = [
  {
    id: 'no',
    label: 'No.',
    minWidth: 30,
  },
  {
    id: 'image',
    label: 'Image',
    minWidth: 80,
  },
  {
    id: 'title_th',
    label: 'Name (TH)',
    minWidth: 100,
  },
  {
    id: 'title_en',
    label: 'Name (EN)',
    minWidth: 100,
  },
  {
    id: 'description_th',
    label: 'Description (TH)',
    minWidth: 150,
  },
  {
    id: 'description_en',
    label: 'Description (EN)',
    minWidth: 150,
  },
  {
    id: 'action',
    label: '',
    minWidth: 80,
  },
]

const initPaging = {
  title: '',
  page: 1,
  pageSize: 10,
}

export default function BOBookPage() {
  const navigate = useNavigate()
  const [books, pagination, fetchBooks, deleteBook] = useBoBookStore(
    (state) => [
      state.books,
      state.pagination,
      state.fetchBooks,
      state.deleteBook,
    ]
  )
  const [paging, setPaging] = useState(initPaging)
  const [exportData, setExportData] = useState()
  useEffect(() => {
    onFetchBooks(initPaging)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [title, setTitle] = useState("")
  useEffect(() => {
    onFetchExport()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[title])

  const onFetchExport = async () => {
    const reqExport = {
      pageSize : 9999
    }
    if(title && title !== ""){
      reqExport.title = title
    }
    const res = await booksApi.getBooks(reqExport)
    setExportData(res?.data?.data)
  }

  const onFetchBooks = async (newPaging) => {
    try {
      await fetchBooks(newPaging)
      setPaging(newPaging)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'System error.',
      })
    }
  }

  const handleCriteriaChange = (value) => {
    setTitle(value)
    setPaging({
      ...paging,
      title: value,
    })
  }

  const handleAddBookClick = () => {
    navigate(routePaths.BO_BOOK_NEW)
  }

  const handleSearchClick = async () => {
    await onFetchBooks({
      ...paging,
      page: 1,
    })
  }

  const handleClearClick = async () => {
    await onFetchBooks(initPaging)
  }

  const handleEditClick = (id) => {
    navigate(`/bo/book/${id}/edit`)
  }

  const handleDeleteClick = async (bookId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteBook(bookId)
        if (response) {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success').then(
            async (res) => {
              if (res.isConfirmed) {
                await onFetchBooks(initPaging)
              }
            }
          )
        } else {
          Swal.fire({
            icon: 'error',
            title: `Error`,
            text: `System error.`,
          })
        }
      }
    })
  }

  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchBooks({
      ...paging,
      page: newPage + 1,
    })
  }

  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchBooks({
      ...paging,
      page: 1,
      pageSize: +e.target.value,
    })
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="mb-20"
      >
        <Grid item>
          <h2>Book List</h2>
        </Grid>
        <Grid item md={2}>
          <Button
            data-testid="booklist-btn-add"
            id="booklist-btn-add"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleAddBookClick()}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} md={6}>
          <TextField
            id="booklist-search-name"
            label="Name (TH/EN)"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#fff' }}
            value={paging.title || ''}
            onChange={(e) => handleCriteriaChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                data-testid="booklist-btn-search"
                id="booklist-btn-search"
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                fullWidth
                onClick={() => handleSearchClick()}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                data-testid="booklist-btn-clear"
                id="booklist-btn-clear"
                variant="contained"
                color="error"
                startIcon={<ClearIcon />}
                fullWidth
                onClick={() => handleClearClick()}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={4}>
              <ExportBtn dataFile={exportData} pageId={'booklist'}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {initColumns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {books?.length > 0 && (
                <TableBody>
                  {(books || []).map((item, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {(pagination.page - 1) * pagination.limit + (i + 1)}
                        </TableCell>
                        <TableCell>
                          <img
                            src={item.image}
                            alt={item.title_en}
                            width="65px"
                          />
                        </TableCell>
                        <TableCell>{item.title_th}</TableCell>
                        <TableCell>{item.title_en}</TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.description_th}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {' '}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.description_en}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            data-testid={`booklist-btn-edit-${i}`}
                            id={`booklist-btn-edit-${i}`}
                            color="primary"
                            onClick={() => handleEditClick(item.id)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            data-testid={`booklist-btn-delete-${i}`}
                            id={`booklist-btn-delete-${i}`}
                            color="error"
                            onClick={() => handleDeleteClick(item.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={pagination.total_count}
            rowsPerPage={paging.pageSize}
            page={pagination.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>
    </>
  )
}
