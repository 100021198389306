import React from 'react'
import { Button, Paper } from '@mui/material'
import styled from 'styled-components';
import { Text } from 'components/Text';
import { useBoReportStore } from 'stores/BackOffice/boReportStore';
import IosShare from '@mui/icons-material/IosShare'
import XLSX from 'sheetjs-style'

const WrapperList = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  border-bottom: 1px solid #dfdfdf;
  align-items: center;
  align-self: center;
  padding: 10px;
  &:nth-child(even) {
    background-color: #dfdfdf;
  }
`;

function ReportPage(props) {
  const [
    userReport,
    pointReport
  ] = useBoReportStore((state) => [
    state.userReport,
    state.pointReport
  ])

  const listData = [
    {
      key: 'USERS_REPORT',
      label: 'Users Report',
      fn: userReport
    },
    {
      key: 'POINT_REPORT',
      label: 'Point Report',
      fn: pointReport
    }
  ]

  async function handleExport(type, fn) {
    if (type && fn) {
      const response = await fn()
      const fileExtension = '.xlsx'
      if (response && response.data) {
        const ws = XLSX.utils.json_to_sheet(response.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, ws, 'sheet1');
        XLSX.writeFile(workbook, type + fileExtension)
      }
    }
  }

  return (
    <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
      { listData && listData.map((item, index) => (
        <WrapperList key={index}>
          <Text>{item.label}</Text>
          <Button
            id={`${item.key}-btn-export`}
            data-testid={`${item.key}-btn-export`}
            variant="contained"
            color="success"
            fullWidth
            startIcon={<IosShare />}
            onClick={() => handleExport(item.key, item.fn)}
          >
            Export
          </Button>
        </WrapperList>
      ))}
    </Paper>
  );
}

export default ReportPage;
