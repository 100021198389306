import { format, getTime, formatDistanceToNow } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const UTM_DATE_FORMAT_PATTERN = 'yyyyMMdd'
export const ISO_DATE_FORMAT_PATTERN = 'yyyy-MM-dd'
export const ISO_DATE_TIME_FORMAT_PATTERN = 'yyyy-MM-dd HH:mm:ss'

export function fDate(date) {
  return format(date, 'dd MMMM yyyy')
}

export function fTime(date) {
  return format(date, 'HH:mm')
}

export function fDateTime(date) {
  return format(date, 'dd MMM yyyy p')
}

export function fTimestamp(date) {
  return getTime(date)
}

export function fDateTimeSuffix(date) {
  return format(date, 'dd/MM/yyyy hh:mm p')
}

export function fToNow(date) {
  return formatDistanceToNow(date, {
    addSuffix: true,
  })
}

export function formatISODateTime(value) {
  return value ? format(value, ISO_DATE_TIME_FORMAT_PATTERN) : ''
}

// -------------------------------------
// We use UTC for handle datetime in WEB
// -------------------------------------

// Use when fetch datetime from API and convert to UTC
export function fISODateToUTC(date) {
  if (date && date !== '') {
    return utcToZonedTime(date, 'Etc/GMT+0')
  } else {
    return ''
  }
}

export function fUTCDateToIso(date) {
  return date ? new Date(date).toISOString() : ''
}

export function fUTCDateToIsoStart(date) {
  return date ? format(date, ISO_DATE_FORMAT_PATTERN) + 'T00:00:00Z' : ''
}

export function fUTCDateToIsoEnd(date) {
  return date ? format(date, ISO_DATE_FORMAT_PATTERN) + 'T23:59:59Z' : ''
}
