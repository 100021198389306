import React, { useState, useEffect } from 'react'
import QRCode from 'react-qr-code'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuthStore } from 'stores/authStore'
import { useMsal } from '@azure/msal-react'

import CustomButton from 'components/CustomButton'
import NotifyDialog from 'components/NotifyDialog'

import  routePaths  from 'routesPath'
import {
  WrapperProfilePage,
  WrapperProfileBox,
  WrapperSectionProfile,
  WrapperSectionMenu,
  WrapperProfileImage,
  ProfileImage,
  WrapperBoxMenu,
  WrapperItemMenu,
  IconMenu
} from 'pages/Profile/profile.style';
import { Text } from 'components/Text'
import { Divider } from 'components/Divider'
import AccmulatePointComponent from 'components/AccmulatePointComponent'
import SelectLangComponent from 'components/SelectLangComponent'

const ProfilePage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { instance } = useMsal()

  const [clearAuth, user, imgProfile, fetchUserInfo] = useAuthStore((state) => [
    state.clearAuth,
    state.user,
    state.imgProfile,
    state.fetchUserInfo,
  ])
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [isShowQrCode, setIsShowQrCode] = useState(false)

  useEffect(() => {
    fetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLogoutClick = (e) => {
    e.preventDefault()
    setIsShowConfirmDialog(true)
  }

  const onConfirmDialogClick = async (e) => {
    e.preventDefault()
    await instance.logout({
      onRedirectNavigate: (url) => {
        return false
      },
    })
    clearAuth()
    navigate(routePaths.LOGIN)
  }

  const onNavigateClick = (e, url, isExternal) => {
    e.preventDefault()
    if (isExternal) {
      window.open(url, '_blank')
    } else {
      navigate(url)
    }
  }

  return (
    user &&
    <WrapperProfilePage>
      <WrapperProfileBox>
        <WrapperSectionProfile>
          <WrapperProfileImage>
            <ProfileImage
              alt='Profile'
              src={ imgProfile ? imgProfile : `/assets/images/default-profile-img.png` }
              circle={imgProfile}
            />
          </WrapperProfileImage>
          <Text title={1}>{user.full_name}</Text>
          <Text subtitle={1} secondary={1}>{user.email}</Text>
          <Divider />
          <AccmulatePointComponent
            accumulatePoints={user.accumulate_points}
            fullPoints={user.ful_points}
            usedPoints={user.used_points}
            balancePoints={user.balance_points}
          />
        </WrapperSectionProfile>
        <WrapperSectionMenu>
          <WrapperBoxMenu
            cursor={1}
            onClick={(e) => setIsShowQrCode(true)}>
            <WrapperItemMenu>
              <IconMenu
                alt='Qr Code'
                src='/assets/images/qr-code.svg'
              />
              <Text>{t('Qr Code')}</Text>
            </WrapperItemMenu>
          </WrapperBoxMenu>

          <WrapperBoxMenu
            cursor={1}
            onClick={(e) => onNavigateClick(e, routePaths.TRANSFER_POINT)}>
            <WrapperItemMenu>
              <IconMenu
                alt='Transfer Point'
                src='/assets/images/transfer-point.svg'
              />
              <Text>{t('transferPoint')}</Text>
            </WrapperItemMenu>
          </WrapperBoxMenu>

          <WrapperBoxMenu>
            <WrapperItemMenu third={1}>
              <IconMenu
                alt='Change Language'
                src='/assets/images/change-language.svg'
              />
              <Text>{t('changeLanguage')}</Text>
              <SelectLangComponent />
            </WrapperItemMenu>
          </WrapperBoxMenu>

          <WrapperBoxMenu
            cursor={1}
            onClick={(e) => onNavigateClick(e, routePaths.WHATISMETOWE)}>
            <WrapperItemMenu>
              <IconMenu
                alt='Change Language'
                src='/assets/images/info-me-to-we.svg'
              />
              <Text>{t('whatIsMeToWeOutbox')}</Text>
            </WrapperItemMenu>
          </WrapperBoxMenu>

          <WrapperBoxMenu
            cursor={1}
            onClick={(e) => onNavigateClick(e, routePaths.CONTACT)}>
            <WrapperItemMenu>
              <IconMenu
                alt='Change Language'
                src='/assets/images/contact-we.svg'
              />
              <Text>{t('contact')}</Text>
            </WrapperItemMenu>
          </WrapperBoxMenu>

          <CustomButton
            id="btnLogout"
            color="primary"
            variant="contained"
            onClick={onLogoutClick}
            center={1}
          >
            {t('logout')}
          </CustomButton>
        </WrapperSectionMenu>
      </WrapperProfileBox>

      { user && isShowQrCode &&
        <NotifyDialog
          type="confirmation"
          bodyText={<QRCode value={user.email} />}
          open={isShowQrCode}
          onCloseDialog={() => setIsShowQrCode(false)}
        />
      }

      <NotifyDialog
        type="confirmation"
        title={t('doYouWant')}
        bodyText={t('logout')}
        okText={t('confirm')}
        cancelText={t('cancel')}
        open={isShowConfirmDialog}
        onCloseDialog={() => setIsShowConfirmDialog(false)}
        onCancelDialogClick={() => setIsShowConfirmDialog(false)}
        onOkDialogClick={onConfirmDialogClick}
      />
    </WrapperProfilePage>
  )
}

export default ProfilePage
