import styled, { css } from 'styled-components';
import { gutter, fontSize, screen } from 'styles/variables';
import {
  Button
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PinIcon from '@mui/icons-material/LocationOn'
import UnpinIcon from '@mui/icons-material/LocationOff'
import { ModeComment, ThumbUp } from '@mui/icons-material';

export const WrapperCommunityPage = styled.div`
  padding: ${gutter.large};
  .hashtag {
    background: #FFF4C9;
  }
`;

export const WrapperCommunitySection = styled.div`
  margin: ${gutter.default} auto;
  background-color: #ECF6FC;
  padding: 20px 10px;
  border-radius: 10px;

  ${props => props.withallpost && css`
    width: -webkit-fill-available;
  `}
`;

export const WrapperCreatePost = styled.div`
  background: #fff;
  backdrop-filter: blur(2px);
  border-radius: 7.5px;
  padding: ${gutter.default};
`;

export const WrapperUserPost = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: ${gutter.small};
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;

export const WrapperInputPost = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
`;

export const InputPost = styled.textarea`
  margin: ${gutter.xs} auto;
  width: 100%;
  resize: none;
  border: none;
  background: #f6f7f9;
  outline: none;
  min-height: 100px;
  padding: 10px;
`;

export const InputComment = styled.input`
  /* width: 100%; */
  padding: ${gutter.xs};
  background: none;
  border-radius: 2px;
  border: 1px solid ${({ theme}) => theme?.colors?.primary?.default};
  outline: none;
`;

export const WrapperOptionPost = styled.div`
  padding: ${gutter.xs} 0;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const WrapperButton = styled(Button)`
  margin-top: ${gutter.small};
`;

export const WrapperAllPost = styled.div``;

export const ItemPost = styled.div`
  margin-top: ${gutter.large};

  background: #fff;
  backdrop-filter: blur(2px);
  border-radius: 7.5px;
  padding: ${gutter.default};

  ${props => props.comment && css`
    background: #f6f7f9 !important;
  `}

  ${props => props.withmobile && css`
    height: fit-content !important;
  `}
`;

export const WrapperTextPost = styled.div`
  margin-top: ${gutter.default};
  word-break: break-word;
`;

export const WrapperOptionComment = styled.div`
  margin-top: ${gutter.large};
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: ${gutter.xs};
  text-align: center;
  align-self: center;
  align-items: center;

  border-top: 1px solid #C7D5E6;
  border-bottom: 1px solid #C7D5E6;

  font-size: ${fontSize.small};
  
  color: #9EABBC;

  button {
    display: grid;
    grid-column: 1/3;
  }
  ${props => props.withcomment && css`
    grid-template-columns: 1fr;
  `}
`;

const AttBtn = styled.div`
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 0px;
  /* &:hover {
    background: ${({ theme }) => theme?.colors?.primary?.default};
    color: ${({ theme }) => theme?.colors?.primary?.text};
    transition: 0.3s;
  } */

  ${props => props.active && css`
    background: ${({ theme }) => theme?.colors?.primary?.default};
    color: ${({ theme }) => theme?.colors?.primary?.text};
    transition: 0.3s;
  `}
`
export const BtnLike = styled(AttBtn)`
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  grid-gap: 10px;
`;

export const BtnComment = styled(AttBtn)`
  align-items: center;
  align-content: center;
  /* display: flex; */
  justify-content: center;
  grid-gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
`;

export const WrapperAddImg = styled.div`
  display: flex;
  justify-self: left;
  background: #EBF1F4;
  padding: 5px 15px;
  border-radius: 10px;
  grid-gap: 10px;
  align-items: center;
  color: #1B5BAE;
  img {
    display: block;
  }
`;

export const WrapperImgAddImg = styled.label``;

export const InputAddImg = styled.input`
  outline: 0;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  display: none;
`;

export const AddImg = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const WrapperSectionComment = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: ${gutter.small};
  margin-top: ${gutter.small};
  align-items: center;
  align-self: center;
`;

export const WrapperImgComment = styled.img`
  width: 100px;
  margin-top: ${gutter.default};
  position: relative;
`;

const iconSize = `
  font-size: 16px;
  display: block;
  margin: 5px auto 0 auto;
`;

export const WrapperRemove = styled(DeleteIcon)`
  cursor: pointer;
  ${iconSize};
  ${props => props.withremoveimage && css`
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    border-radius: 100%;
  `}
`;

export const WrapperEdit = styled(EditIcon)`
  cursor: pointer;
  ${iconSize};
`;

export const WrapperPin = styled(PinIcon)`
  cursor: pointer;
  ${iconSize};
`;

export const WrapperUnpin = styled(UnpinIcon)`
  cursor: pointer;
  ${iconSize};
`;

export const WrapperLike = styled(ThumbUp)`
  cursor: pointer;
  ${iconSize};
`;

export const WrapperComment = styled(ModeComment)`
  cursor: pointer;
  ${iconSize};
`;

export const WrapperHandleActionByUser = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${gutter.small};

  ${props => props.withmobile && css`
    grid-template-columns: 1fr;
  `}
`;

export const WrapperEditAction = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  grid-gap: ${gutter.small};
`;

export const WrapperUploadImagePost = styled.div`
  /* grid-gap: 10px; */
  /* overflow: scroll; */
  /* display: flex; */
  /* max-width: 335px; */
  /* @media (max-width: ${screen.md.max}) {
    max-width: 435px;
  }
  @media (max-width: ${screen.md.up}) {
    max-width: 120px;
  } */

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  img {
    max-height: 100px;
    width: 100%;
  }
  @media (max-width: ${screen.md.up}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ImageUpload = styled.img`
  /* width: 100px; */
  /* margin-left: 20px; */
`;

export const AddMoreImage = styled.label`
  width: 100px;
  font-size: 36px;
  border: 1px solid ${({ theme}) => theme?.colors?.primary?.default};
  display: grid;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const WrapperMoreImage = styled.div`
  /* display: flex; */
`;

export const WrapperItemImageUpload = styled.div`
  position: relative;
`;

export const WrapperItemEditComment = styled.div`
  display: grid;
  margin: ${gutter.default} 0;
`;

export const WrapperImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${gutter.small};
  margin-top: ${gutter.small};

  ${props => props.withoutgap && css`
    @media (max-width: ${screen.md.max}) {
      grid-gap: 2px;
    }
  `}
`;

export const WrapperImagePost = styled.div`
  height: 300px;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${props => props.item === 1 && css`
    display: grid;
    grid-column: 1/3;
  `}

  @media (min-width: ${screen.sm.up}) {
    height: 250px !important;
  }

  @media (max-width: ${screen.sm.max}) {
    ${props => props.item !== 1 && css`
      height: 130px;
      img {
        width: 100%;
      }
    `}
  }
`;

export const ImagePost = styled.img`
  display: grid;
  margin: 0 auto;
  height: 100%;
`;

export const ImageComment = styled.img`
  width: 100%;
  margin-top: ${gutter.small};
`;

export const WrapperPost = styled.div`
  display: flex;
  justify-self: right;
  background: #EBF1F4;
  padding: 5px 15px;
  border-radius: 10px;
  grid-gap: 10px;
  align-items: center;
  color: #1B5BAE;

  img {
    display: block;
  }

  ${props => props.withimage && css`
    margin-top: ${gutter.default};
    justify-self: center;
    padding: ${gutter.xs};

    &:active {
      background: #b9bdc0
    }

    img {
      margin: 0 auto;
    }
  `}
`;

export const PostImage = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const IconLike = styled.img``;

export const WrapperTotalLike = styled.div`
  margin: ${gutter.default} 0;
  color: #9EABBC;
  font-size: ${fontSize.small};
`;

export const WrapperTextLike = styled.label`
  cursor: pointer;
  ${props => props.active && css`
    color: #F9595F;
  `}
`;

export const WrapperText = styled.div`
  ${props => !props.withexpand && css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  `}

  ${props => props.withmobile && css`
    width: 250px !important;
    -webkit-line-clamp: 3;
    font-size: ${fontSize.small};
  `}

  ${props => props.withcomment && css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  `}
`;

export const WrapperSeemore = styled.div`
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
`;

export const CommunityLandScapeLogo = styled.img`
  margin: 0 auto ${gutter.default} auto;
  display: none;
  ${props => props.withmobile && css`
    @media (max-width: ${screen.md.max}) {
      display: block;
    }
  `}

  ${props => props.withdesktop && css`
    display: block;
    @media (max-width: ${screen.md.max}) {
      display: none;
    }
  `}
`;

export const Label = styled.label`
  font-size: ${fontSize.small};
  cursor: pointer;
`;

export const WrapperBodyCommunity = styled.div`
  display: grid;

  ${props => props.withpin && css`
    grid-template-columns: 30% 70%;
    grid-gap: ${gutter.small};
  `}

  @media (max-width: ${screen.md.max}) {
    grid-template-columns: 1fr;
  }
`;

export const WrapperPinPost = styled.div`
  margin: ${gutter.default} 0;
  background-color: #ECF6FC;
  padding: 20px 10px;
  border-radius: 10px;
  height: fit-content;

  @media (max-width: ${screen.md.max}) {
    display: none;
  }
`;

export const WrapperHeaderSection = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${gutter.xs};
  margin-bottom: ${gutter.small};

  ${props => props.withpin && css`
    margin-bottom: 0;
  `}
`;

export const HeaderIcon = styled.img``;

export const WrapperHighlightHashtag = styled.div`
  margin-top: 30px;
`;

export const WrapperHighlightHashtagItem = styled.div`
  margin-top: ${gutter.small};
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
`;

export const HighlightHashtagItem = styled.div`
  padding: 5px 15px;
  border-radius: 15px;
  background: #d1e6f2;
  font-size: ${fontSize.small};
  cursor: pointer;
  
  ${props => props.active && css`
    background: #FFF4C9;
    font-weight: bold;
  `}
`;

export const WrapperPinPostWithMobile = styled.div`
  display: none;
  margin-top: ${gutter.default};
  @media (max-width: ${screen.md.max}) {
    display: block;
  }
`;

export const PinPostWithMobileItem = styled.div`
  display: flex;
  overflow: scroll;
  grid-gap: 10px;
  max-width: 76.5vw;
  @media (max-width: ${screen.md.up}) {
    max-width: calc(85vw - 30px);
  }
`;

