import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInspirationStore } from 'stores/inspirationStore'
import { useNavigate } from 'react-router-dom'
import  routePaths  from 'routesPath'
import InfiniteScroll from 'react-infinite-scroll-component'
import frontEndApi from 'api/frontEndApi'
import CustomButton from 'components/CustomButton'
import Loading from 'components/Loading'

import ProfileBox from 'components/ProfileBox'
import ProfileNav from 'components/ProfileNav'
import './inspiration.scss'

const InspirationPage = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [courses, clearCourses, fetchInspirationCourses] = useInspirationStore(
    (state) => [
      state.courses,
      state.clearCourses,
      state.fetchInspirationCourses
    ]
  )
  const [listCourse, setListCourse] = useState()
  const [hasMoreData, setHasMoreData] = useState(true)
  const [paging, setPaging] = useState({
    page: 0,
    pageSize: 10,
  })

  useEffect(() => {
    clearCourses()
    onFetchInspirationCourses(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dataForSort()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onFetchInspirationCourses = async (isClearPage = false) => {
    const newPaging = {
      ...paging,
      page: isClearPage ? 1 : paging.page + 1,
      sort: 'created_at desc',
    }
    const result = await fetchInspirationCourses(newPaging)
    setHasMoreData(result)
    setPaging(newPaging)
  }
  const dataForSort = async () => {
    let arrSort = []
    const res = await frontEndApi.getInspirationCourses({page: 1})
    const course = res.data.data || []
    for(let i=0; i<course.length; i++){
      const params = {
        courseId: course[i].id,
        sort: 'event_date: asc'
      }
      const { data } = await frontEndApi.getInspirationWorkshopList(params)
      const arrO = []
      data.data.workshops.map((v) => arrO.push(v.event_date))
      var maxDate = arrO.slice(-1)[0];
      arrSort.push({
        id: course[i].id,
        event_date: maxDate,
        "created_at": course[i].created_at,
        "updated_at": course[i].updated_at,
        "image": course[i].image,
        "title_th": course[i].title_th,
        "title_en": course[i].title_en,
        "description_th": course[i].description_th,
        "description_en": course[i].description_en,
        "instructor_th": course[i].instructor_th,
        "instructor_en": course[i].instructor_en,
        "group_id": course[i].group_id,
        "active": course[i].active,
        "user_group": course[i].user_group
      })
    }
    arrSort = arrSort.sort((a,b) => new Date(b.event_date) - new Date(a.event_date))
    let arrSetA = []
    const arrSetB = []
    const DN = new Date();
    arrSort.forEach((val) => {
      if(new Date(val.event_date) > DN) {
        arrSetA.push({
          id: val.id,
          event_date: val.event_date,
          "created_at": val.created_at,
          "updated_at": val.updated_at,
          "image": val.image,
          "title_th": val.title_th,
          "title_en": val.title_en,
          "description_th": val.description_th,
          "description_en": val.description_en,
          "instructor_th": val.instructor_th,
          "instructor_en": val.instructor_en,
          "group_id": val.group_id,
          "active": val.active,
          "user_group": val.user_group
        })
      } else {
        arrSetB.push({
          id: val.id,
          event_date: val.event_date,
          "created_at": val.created_at,
          "updated_at": val.updated_at,
          "image": val.image,
          "title_th": val.title_th,
          "title_en": val.title_en,
          "description_th": val.description_th,
          "description_en": val.description_en,
          "instructor_th": val.instructor_th,
          "instructor_en": val.instructor_en,
          "group_id": val.group_id,
          "active": val.active,
          "user_group": val.user_group
        })
      }
    })
    arrSetA = arrSetA.sort((a,b) => new Date(a.event_date) - new Date(b.event_date))
    const mergeArr = [...arrSetA, ...arrSetB]
    setListCourse(mergeArr)
  }
  const onMoreClick = (e, id) => {
    e.preventDefault()
    navigate(`${routePaths.WORKSHOPS}?id=${id}`)
  }
  return (
    <div className="is-container">
      <ProfileBox />
      <ProfileNav />
      <div className="page-header">{ t('inspirationHours') }</div>
      <div className='c-label-sl'>{t('oneSession')}</div>
      <InfiniteScroll
        className='wrapper-inspiration'
        dataLength={courses.length}
        next={onFetchInspirationCourses}
        hasMore={hasMoreData}
        loader={<Loading open />}
      >
        {(listCourse || []).map((item, i) => {
          const revert = i % 2 === 0 ? '' : 'revert'
          return (
            <div key={i} className="card-container">
              <div className={`card-body ${revert}`}>
                <div className="card-img">
                  <img src={item.image} alt={item.title_en} />
                </div>
                <div className="card-content">
                  <div className="c-title">
                    {item[`title_${i18n.language}`]}
                  </div>
                  <div className="c-name">
                    {item[`instructor_${i18n.language}`]}
                  </div>
                  <div className="c-detail">
                    <p>{item[`description_${i18n.language}`]}</p>
                  </div>
                </div>
              </div>
              <div className="card-action">
                <CustomButton
                  id="btnMore"
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={(e) => onMoreClick(e, item.id)}
                >
                  {t('more')}
                </CustomButton>
              </div>
            </div>
          )
        })}
      </InfiniteScroll>
    </div>
  )
}

export default InspirationPage
