import { createHttpClient } from './me2weApi'

class BookRequests {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getBookRequests(data) {
    const params = {
      user_name: data.username || '',
      user_email: data.userEmail || '',
      book_name: data.bookName || '',
      status: data.status || false,
      start: data.start || '',
      end: data.end || '',
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || 'created_at desc',
    }

    if (typeof data.status === 'string') {
      if (data.status) {
        params.status = data.status === 'true'
      } else {
        delete params.status
      }
    }

    return this.client.post('/book-requests/getList', params)
  }

  async requestBook(data) {
    return this.client.post('/book-requests/requestBook', {
      book_id: data.bookId,
    })
  }

  async batchUpdateBookSent(data) {
    return this.client.post('/book-requests/batchUpdateBookSent', data)
  }
}

const bookRequestApi = new BookRequests()
export default bookRequestApi
