import { create } from 'zustand'
import eLearningApi from 'api/elearningApi'
import { initPagination } from 'constant/boPagination'

export const useBoELearningStore = create((set, get) => ({
  eLearnings: [],
  pagination: initPagination,
  fetchELearnings: async (params) => {
    let eLearningDatas = []
    let paginationData = initPagination
    try {
      const response = await eLearningApi.getELearnings(params)
      const { data, pagination } = response.data
      eLearningDatas = data
      paginationData = pagination
    } catch (error) {
      throw new Error(error)
    } finally {
      set({
        eLearnings: eLearningDatas,
        pagination: paginationData,
      })
    }
  },
  importProgress: async (data) => {
    const response = await eLearningApi.importProgress(data)
    return response.status === 200
  },
}))
