import { createHttpClient } from './me2weApi'

class ELearning {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getELearnings(data) {
    return this.client.post('/elearning-progress/getList', {
      user_name: data.userName || '',
      user_email: data.userEmail || '',
      page: data.page || 1,
      page_size: data.pageSize || 10,
      sort: data.sort || 'created_at desc',
    })
  }

  async importProgress(data) {
    return this.client.post('/elearning-progress/importProgress', data)
  }
}

const eLearningApi = new ELearning()
export default eLearningApi
