import { styled } from 'styled-components';
import { screen, boxShadow, gutter } from 'styles/variables';

export const WrapperHomePage = styled.div`
  padding: ${gutter.large};
`;

export const WrapperContentHomePage = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${gutter.default};

  @media (max-width: ${screen.md.max}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

export const WrapperBoxMenuHomePage = styled.div`
  margin: ${gutter.default} 0 ${gutter.large} 0;
`;

export const MenuList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${gutter.small};
  margin-top: ${gutter.default};

  background: ${({ theme }) => theme?.colors?.profile?.panel};
  backdrop-filter: blur(2px);
  border-radius: 20px;
  border: solid 2px ${({ theme }) => theme?.colors?.profile?.borderColor};
  padding: ${gutter.default};
  @media (max-width: ${screen.md.max}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ItemMenuHomePage = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: ${gutter.xs};

  background: ${({ theme }) => theme?.colors?.profile?.panel};
  backdrop-filter: blur(2px);
  border-radius: 20px;
  border: solid 2px ${({ theme }) => theme?.colors?.profile?.borderColor};

  cursor: pointer;
  padding: ${gutter.xs};
  
  button {
    text-transform: none;
  }
`;

export const ImageMenuHomePage = styled.img`
  display: block;
  margin: auto;
  width: 100%;

  @media (max-width: ${screen.sm.max}) {
    width: 60%;
  }

`;

export const WrapperHighlight = styled.div`
  margin: ${gutter.default} 0 ${gutter.large} 0;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: ${gutter.default};
`;

export const WrapperTitleHighlight = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${gutter.xs};
  align-self: center;
  align-items: center;
`;

export const Highlight = styled.iframe`
  /* width: 100%; */
  /* height: 400px; */
  width: 640px;
  height: 480px;
  background: #fff;
  box-shadow: ${boxShadow.default};
  @media (max-width: ${screen.sm.max}) {
    height: 420px;
    width: 100%;
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 520px;
  background: #fff;
  box-shadow: ${boxShadow.default};
  @media (max-width: ${screen.sm.max}) {
    width: 100%;
    height: 100%;
  }
`;

export const Source = styled.source`
`;

export const ImgHighlight = styled.img`
  width: 18px;
  height: 18px;
`;
