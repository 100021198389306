import { createHttpClient } from './me2weApi'

class UserRedemption {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getUserRedemptions(data) {
    const params = {
      ...data,
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || 'created_at desc',
    }
    return this.client.post('/user-redemptions/getList', params)
  }

  async batchUpdateSent(data) {
    return this.client.post('/user-redemptions/batchUpdateSent', data)
  }
}

const userRedemptionApi = new UserRedemption()
export default userRedemptionApi
