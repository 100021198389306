import { useLoading } from 'components/Loading/context'
import { useNavigate } from 'react-router-dom'
import  routePaths  from 'routesPath'
import { useBoInspirationWorkshopStore } from 'stores/BackOffice/boInspirationWorkshop'
import workshopApi from 'api/inspirationWorkshopApi'

import Swal from 'sweetalert2'
import WorkshopForm from './WorkshopForm'
import { useEffect } from 'react'

const AddWorkshopPage = () => {
  const navigate = useNavigate()
  const { showLoading, hideLoading } = useLoading()

  const [clearWorkshop] = useBoInspirationWorkshopStore((state) => [
    state.clearWorkshop,
  ])

  useEffect(() => {
    clearWorkshop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (workshop) => {
    showLoading()
    try {
      const result = await workshopApi.createWorkshop(workshop)

      if (result.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Add Inspiration Hour Workshop success',
        }).then((res) => {
          if (res.isConfirmed) {
            navigate(routePaths.BO_INSPIRATION_WORKSHOP)
          }
        })
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      hideLoading()
    }
  }

  return <WorkshopForm mode="Add" onSave={handleSave} />
}

export default AddWorkshopPage
