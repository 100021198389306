import { createHttpClient } from './me2weApi'

const defaultSort = 'created_at asc'
const defaultGroup = 'DEFAULT'
const emiGroup = 'EMI'
const pathWorkshopList = '/frontend/getInspirationWorkshopList'
class FrontEnd {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getUserProfile() {
    return this.client.post('/frontend/getProfile')
  }

  async getFuel(data) {
    return this.client.post('/frontend/getFuel', {
      group_id: data.group_id || 1
    })
  }

  async getBooks(data) {
    return this.client.post('/frontend/getBookList', {
      title: data.title || '',
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || defaultSort,
    })
  }

  async getRewards(data) {
    return this.client.post('/frontend/getRewardList', {
      name: data.title || '',
      page: data.page || 1,
      page_size: data.pageSize,
      active: data.active || true,
      sort: data.sort || defaultSort,
    })
  }

  async getInspirationCourses(data) {
    return this.client.post('/frontend/getInspirationCourseList', {
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || defaultSort,
      group_name: data.groupName || defaultGroup
    })
  }

  async getInspirationWorkshopList(data) {
    return this.client.post(pathWorkshopList, {
      course_id: data.courseId,
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || defaultSort,
    })
  }

  async requestBook(data) {
    return this.client.post('/frontend/requestBook', {
      book_id: data.bookId,
    })
  }

  async requestUserELearning(data) {
    return this.client.post('/frontend/requestElearningUser', data)
  }

  async redeemReward(data) {
    return this.client.post('/frontend/redeemReward', {
      reward_id: data.rewardId,
    })
  }

  async reserveInspiration(data) {
    return this.client.post('/frontend/reserveInspiration', {
      course_id: data.courseId,
      workshop_id: data.workshopId,
      session_id: data.sessionId,
    })
  }

  async cancelInspiration(id) {
    return this.client.post('/frontend/cancelReserveInspiration', {
      id,
    })
  }

  async getEmiCourses(data) {
    return this.client.post('/frontend/getInspirationCourseList', {
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || defaultSort,
      group_name: emiGroup
    })
  }

  async getEmiWorkshopList(data) {
    return this.client.post(pathWorkshopList, {
      course_id: data.courseId,
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || defaultSort,
      group_name: emiGroup
    })
  }

  async transferPointToUser(data) {
    return this.client.post('/frontend/transfer/point', data)
  }

  async pointHistory() {
    return this.client.post('/frontend/transfer/history', {})
  }

  async checkInWorkShop(data) {
    return this.client.post('/frontend/checkin', {
      email: data.email,
      sessionId: data.sessionId,
      point: data.point,
      type: data.type
    })
  }

  async getNewWorkshopList(data) {
    return this.client.post(pathWorkshopList, {
      course_id: data.courseId,
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || defaultSort,
      group_name: 'Leadership'
    })
  }

  async getAllWorkShop(data) {
    return this.client.post('/frontend/getInspirationWorkshopListAll', {
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || defaultSort
    })
  }

  async updateUserAvatar(data) {
    return this.client.post('/users/updateUserAvatar', {
      avatar: data.avatar
    })
  }
}

const frontEndApi = new FrontEnd()
export default frontEndApi
