import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import BrandLogo from 'components/BrandLogo'

import  routePaths  from 'routesPath'
import { useIsAuthenticated } from '@azure/msal-react'

import './landing.scss'

const LandingPage = (props) => {
  const navigate = useNavigate()
  const isAuthenticated = useIsAuthenticated()

  const [count, setCount] = useState(1)

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCount((prevCount) => prevCount - 1)
  //   }, 1000)

  //   return () => {
  //     clearInterval(interval)
  //     if (count === 0) {
  //       navigate(routePaths.LOGIN)
  //     }
  //   }
  // }, [count, navigate])

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1)
    }, 1000)

    return () => {
      clearInterval(interval)
      if (count === 0) {
        if (isAuthenticated) {
          window.location.href = routePaths.HOME;
        } else {
          navigate(routePaths.LOGIN)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, navigate])

  return (
    <div className="ld-container">
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#146EAB',
          position: 'absolute',
          top: '50%',
          left: '48%',
          animationDuration: '750ms',
        }}
        size={35}
        thickness={4}
      />
      <img
        className="main-logo"
        src="/assets/images/me-to-we-logo.png"
        alt="Me to We logo"
      />

      <BrandLogo position="left" />
    </div>
  )
}

export default LandingPage
