import ProfileBox from 'components/ProfileBox'
import './wetogether.scss'
import ProfileNav from 'components/ProfileNav'
import CustomMenuComponent from 'components/DynamicMenu/CustomMenuComponent'

export default function WeTogetherPage() {
    return (
        <>
            <div className="we2-container">
                <ProfileBox />
                <ProfileNav />
                <br />
                <div>We Together</div>
                <div className="w2-menu-container wrapper-grid">
                    {/* <div>
                        <div
                            id="btnContainerEmi"
                            className="w2-box-container"
                            onClick={(e) => onNavigateClick(e, routePaths.EMI)}
                        >
                            <div className="point-container">
                                <HeartPoint size="sm" point={fuel.inspiration_points} />
                            </div>
                            <div className="w2-image-container">
                            <img
                                alt="emi"
                                src="/assets/images/EMI.png"
                                width="96"
                            />
                            </div>
                            <div className="w2-button-container">
                            <CustomButton
                                id="btnEmi"
                                style={{ textTransform: 'none' }}
                                color="primary"
                                variant="contained"
                                fullWidth
                                onClick={(e) => onNavigateClick(e, routePaths.EMI)}
                            >
                                {t('EMI')}
                            </CustomButton>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            id="btnContainerWeTogetherLeader"
                            className="w2-box-container"
                            onClick={(e) => onNavigateClick(e, routePaths.LEADER_SHIP)}
                        >
                            <div className="point-container">
                                <HeartPoint size="sm" point={fuelLeader.inspiration_points} />
                            </div>
                            <div className="w2-image-container">
                            <img
                                alt="We Together"
                                src="/assets/images/leadership-2.jpg"
                                width="96"
                            />
                            </div>
                            <div className="w2-button-container">
                            <CustomButton
                                id="btnWeTogether"
                                style={{ textTransform: 'none' }}
                                color="primary"
                                variant="contained"
                                fullWidth
                                onClick={(e) => onNavigateClick(e, routePaths.LEADER_SHIP)}
                            >
                                {t('ENTERPRISE Talk')}
                            </CustomButton>
                            </div>
                        </div>
                    </div> */}

                    <CustomMenuComponent sessions={2} />

                    {/* <Grid item xs={0.5}>
                        <></>
                    </Grid> */}
                    {/* <Grid item xs={3.5}>
                        <div
                            id="btnContainerWeTogether"
                            className="w2-box-container"
                            onClick={(e) => onNavigateClick(e, routePaths.EPSPIRIT)}
                        >
                            <div className="w2-image-container">
                            <img
                                alt="We Together"
                                src="/assets/images/EPSpirit.png"
                                width="96"
                            />
                            </div>
                            <div className="w2-button-container">
                            <CustomButton
                                id="btnWeTogether"
                                style={{ textTransform: 'none' }}
                                color="primary"
                                variant="contained"
                                fullWidth
                                onClick={(e) => onNavigateClick(e, routePaths.EPSPIRIT)}
                            >
                                {t('EP Spirit')}
                            </CustomButton>
                            </div>
                        </div>
                    </Grid> */}
                    {/* <Grid item xs={0.5}>
                        <></>
                    </Grid> */}
                    {/* <Grid item xs={3.5}>
                        <div
                            id="btnContainerWeTogether"
                            className="w2-box-container"
                            onClick={(e) => onNavigateClick(e, routePaths.SD)}
                        >
                            <div className="w2-image-container">
                            <img
                                alt="We Together"
                                src="/assets/images/SD.png"
                                width="96"
                            />
                            </div>
                            <div className="w2-button-container">
                            <CustomButton
                                id="btnWeTogether"
                                style={{ textTransform: 'none' }}
                                color="primary"
                                variant="contained"
                                fullWidth
                                onClick={(e) => onNavigateClick(e, routePaths.SD)}
                            >
                                {t('SD')}
                            </CustomButton>
                            </div>
                        </div>
                    </Grid> */}
                </div>
            </div>
        </>
    )
}
