import Axios from 'axios'
import { graphConfig } from './authConfig'
import { Buffer } from 'buffer'

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  return Axios.get(graphConfig.graphPhotoEndpoint, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then((response) => response)
    .catch((error) => console.log(error))
}

export async function callMsPhotoGraph(accessToken) {
  return Axios.get(graphConfig.graphPhotoEndpoint, {
    headers: { Authorization: `Bearer ${accessToken}` },
    // responseType: 'blob',
    responseType: 'arraybuffer',
  })
    .then((o) => {
      const imageType = 'image/jpeg'
      const imageBytes = Buffer.from(o.data).toString('base64')

      return `data:${imageType};base64,${imageBytes}`
    })
    .catch((error) => console.log(error))
}
