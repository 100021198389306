import * as React from 'react'

import './no-layout.scss'

const NoLayout = (props) => {
  return (
    <>
      <div className="nl-container">
        <div className="reponsive-body">
          <div className="body">{props.children}</div>
        </div>
      </div>
    </>
  )
}

export default NoLayout
