import { create } from 'zustand'
import dynamicsMenuApi from 'api/dynamicsMenuApi'
import { arrTemplate } from './templatDynamicValue'

const successtxt = "Success"

export const useBoMenuStore = create((set, get) => ({
    list: [],
    fetchMenu: async () => {
        let lists = []
        try {
          const response = await dynamicsMenuApi.getBOMenuLists()
          const { data } = response.data
          lists = data?.map((e) => {
            const el = ''
            let subMenu = e?.children?.filter((_,index) => index !== 1 && index !== 2 && index !== 4 && index !== 5)
            subMenu = subMenu.map((v) => {
              return {
                id: v.id,
                name: v.name.substr(v.group_name.length),
                to: v.to_page,
                group_name: v.group_name,
              }
            })
            subMenu = subMenu.sort((a,b) => a.id - b.id)
            return{
                id: e.id,
                open: e.is_open,
                name: e.name,
                to: "",
                icon: el,
                group_name: e.group_name,
                role_permission: e.role_permission,
                isDevide: e.is_divide,
                childen: subMenu
            }
          })
        } catch (error) {
          throw new Error(error)
        } finally {
          set({
            list: lists,
          })
        }
      },
      addNewMenu: async (req) => {
        const groupN = req.groupName
        const iconImg = req.icon || ""
        const groupNLower = groupN.toLowerCase()
        const titleN = groupN.replaceAll("_"," ")
        try {
          await dynamicsMenuApi.addMainMenu({
            menu_name: titleN,
            path_str: "/" + groupNLower,
            icon_name: iconImg,
            group_name: groupN,
            client_side: "user"
          })
          const response = await dynamicsMenuApi.addMainMenu({
            menu_name: titleN,
            path_str: "",
            icon_name: "",
            group_name: groupN,
          })
          const { data } = response?.data
          if(data && data === successtxt){
            try{
              const res2 = await dynamicsMenuApi.getBOMenuLists()
              const ML = res2?.data?.data
              const dataProcess = ML.filter(e => e.client_side === "BO")
              const listM = dataProcess.sort((a,b) => b.id - a.id)
              const lastedMenuId = listM[0]?.id
              const arrVal = arrTemplate(titleN,groupNLower)
              for(let i=0; i< arrVal.length;i++){
                await dynamicsMenuApi.addSubMenu({
                  main_menu_id: lastedMenuId,
                  name: arrVal[i].title,
                  path_str: arrVal[i].path_str.toString(),
                  group_name: groupN,
                })
              }
              return
            }catch(err){
              throw new Error(err)
            }
          }
          return
        } catch (error) {
          throw new Error(error)
        }
      },
      removeMenu: async (req) => {
        await dynamicsMenuApi.removeMenu({id: req.id})
      }
}))
