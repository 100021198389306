import React, { useEffect, useReducer, useState } from 'react'
import { useLoading } from 'components/Loading/context'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'stores/authStore'
import { useCommunityStore } from 'stores/community'
import ProfileBox from 'components/ProfileBox'
import ProfileNav from 'components/ProfileNav'
import Loading from 'components/Loading'
import NotifyDialog from 'components/NotifyDialog'
import {
  WrapperCommunityPage,
  WrapperCommunitySection,
  WrapperCreatePost,
  WrapperUserPost,
  Avatar,
  InputPost,
  WrapperOptionPost,
  WrapperButton,
  WrapperAllPost,
  ItemPost,
  WrapperTextPost,
  WrapperOptionComment,
  BtnLike,
  BtnComment,
  WrapperAddImg,
  WrapperImgAddImg,
  InputAddImg,
  AddImg,
  WrapperHandleActionByUser,
  WrapperEditAction,
  WrapperEdit,
  WrapperRemove,
  WrapperUploadImagePost,
  ImageUpload,
  AddMoreImage,
  WrapperMoreImage,
  WrapperItemImageUpload,
  WrapperImageGrid,
  ImagePost,
  WrapperComment,
  WrapperInputPost,
  WrapperPost,
  PostImage,
  IconLike,
  WrapperTotalLike,
  WrapperTextLike,
  WrapperImagePost,
  WrapperText,
  WrapperSeemore,
  CommunityLandScapeLogo,
  Label,
  WrapperPin,
  WrapperBodyCommunity,
  WrapperPinPost,
  WrapperUnpin,
  WrapperHeaderSection,
  HeaderIcon,
  WrapperHighlightHashtag,
  WrapperHighlightHashtagItem,
  HighlightHashtagItem,
  WrapperPinPostWithMobile,
  PinPostWithMobileItem
} from 'pages/Community/community.style';
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import { Text } from 'components/Text'
import handleBase64img from 'utils/handleBase64img'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { urlify } from 'utils/common';

function CommunityPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showLoading, hideLoading } = useLoading()

  const [user, imgProfile] = useAuthStore((state) => [
    state.user,
    state.imgProfile
  ])

  const [
    allPost,
    clearAllPost,
    getAllPost,
    createPost,
    editPost,
    deletePost,
    likePost,
    unLikePost,
    allPostWithPin,
    getAllPostWithPin,
    pinPost,
    unPinPost,
    highlightHashtag,
    getHighlightHashtag
  ] = useCommunityStore((state) => [
    state.allPost,
    state.clearAllPost,
    state.getAllPost,
    state.createPost,
    state.editPost,
    state.deletePost,
    state.likePost,
    state.unLikePost,
    state.allPostWithPin,
    state.getAllPostWithPin,
    state.pinPost,
    state.unPinPost,
    state.highlightHashtag,
    state.getHighlightHashtag
  ])

  const [paging, setPaging] = useState({
    page: 0,
    pageSize: 5,
  })

  const [inputPost, setInputPost] = useState({
    post: ''
  });

  const [inputEditPost, setInputEditPost] = useState({
    post: ''
  });
  const [itemEdit, setItemEdit] = useState()

  const [tempBase64, setTempBase64] = useState()
  const [base64img, setBase64img] = useState([])
  const [errImg, setErrImg] = useState()

  const [editTempBase64, setEditTempBase64] = useState()
  const [editBase64img, setEditBase64img] = useState([])
  const [hasMoreData, setHasMoreData] = useState(true)
  const [showLightBox, setShowLightBox] = useState(false)
  const [dataImageFullScreen, setDataImageFullScreen] = useState()
  const [isShowRemove, setIsShowRemove] = useState()
  const [expandText, setExpandText] = useState()
  const [postKey, setPostKey] = useState()
  const [textHashtag, setTextHashtag] = useState()
  const [postWithPin, setPostWithPin] = useState()
  const [postLists, setPostLists] = useState([])

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    clearAllPost()
    handleGetPost(true)
    handleGetAllPostWithPin()
    handleGetHighlightHashtag()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInputPost(e, type) {
    e.preventDefault()
    const { name, value } = e.target
    if (type === 'post') {
      setInputPost({
        ...inputPost,
        [name]: value
      })
    } else if (type === 'edit') {
      setInputEditPost({
        ...inputEditPost,
        [name]: value
      })
    }
  }

  async function handleGetHighlightHashtag() {
    await getHighlightHashtag()
  }

  async function handleGetAllPostWithPin() {
    await getAllPostWithPin({ page: 1 })
  }

  async function handleGetPost(isClearPage = false) {
    const newPaging = {
      ...paging,
      page: isClearPage ? 1 : paging.page + 1,
      key: postKey,
      value: textHashtag
    }
    const result = await getAllPost(newPaging)
    setHasMoreData(result)
    setPaging(newPaging)
    hideLoading()
  }


  async function handlePost() {
    if (inputPost) {
      showLoading()
      const data = {
        text: inputPost.post,
        images: base64img
      }
      const result = await createPost(data)
      if (result) {
        setBase64img([])
        setInputPost({
          post: ''
        })
        handleGetPost(true)
      }
    }
  }

  function handleComment(item) {
    if (item) {
      navigate(`/community/${item.id}`)
    }
  }

  function handleEdit(item) {
    if (item) {
      setItemEdit(null)
      setInputEditPost(null)
      setEditBase64img([])
      setItemEdit(item)
      setInputEditPost({
        post: item.txt
      })

      if (item.images && item.images.length > 0) {
        setEditBase64img(item.images);
      }
    }
  }

  async function handleSubmitEditPost() {
    if (itemEdit && itemEdit.id) {
      const data = {
        id: itemEdit.id,
        text: inputEditPost.post,
        images: editBase64img
      }
      const result = await editPost(data)
      if (result) {
        setEditBase64img(null)
        setItemEdit(null)
        setInputEditPost({
          post: ''
        })
        handleGetPost(true)
        // handleGetAllPostWithPin()
      }
    }
  }

  async function handleDeletePost(payload) {
    const { item, type, arr } = payload

    if (item && item.id) {
      const tempArr = arr
      const filterItem = tempArr && tempArr.length > 0 && tempArr.filter(el => el.id !== item.id)
      if (type === 'PIN') {
        setPostWithPin(filterItem)
      } else if (type === 'POST') {
        setPostLists(filterItem)
      }

      const data = {
        id: item.id,
      }
      const result = await deletePost(data)
      if (result) {
        // handleGetPost(true)
        // handleGetAllPostWithPin()
        setIsShowRemove(false)
      }
    }
  }

  async function handleClickLike(item, type, arr) {
    if (item && item.id) {
      const tempArr = arr
      const findItem = tempArr && tempArr.length > 0 && tempArr.find(el => el.id === item.id)
      findItem.permission.unlike = !findItem.permission.unlike;
      if (type === 'PIN') {
        setPostWithPin(tempArr)
      } else if (type === 'POST') {
        setPostLists(tempArr)
      }

      const data = {
        id: item.id,
      }

      if (findItem.permission.unlike) {
        await likePost(data)
      } else {
        await unLikePost(data)
      }
    }
  }

  function handleRemoveImgArr(item, arr, setDataToArr) {
    const idx = base64img.indexOf(item)
    arr.splice(idx, 1)
    setDataToArr(arr)
    forceUpdate()
  }

  function handleCancelEdit() {
    setItemEdit(null)
    handleGetPost(true)
    handleGetAllPostWithPin()
  }

  async function handlePinPost(item) {
    if (item && item.id) {
      const data = {
        id: item.id,
      }
      let result
      if (item.permission.pin) {
        result = await pinPost(data)
      } else {
        result = await unPinPost(data)
      }
      if (result) {
        handleGetPost(true)
        handleGetAllPostWithPin()
      }
    }
  }

  function handleClickHashtag(hashtag) {
    if (hashtag) {
      setPostKey('HASHTAG')
      setTextHashtag(hashtag)
    }
  }

  function handleTextPost(text) {
    if (text) {
      return (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(
          urlify(text).replace(/#(\S*)/g, '<a class="hashtag" href="#$1" >#$1</a>'), {ALLOWED_TAGS: ['a'], ADD_ATTR: ['target'] }
        ) }} />
      )
    }
  }

  async function handleClickLogo() {
    navigate('/community')
    setTextHashtag('')
    setPostKey('')
  }

  useEffect(() => {
    if (window.location.hash) {
      setPostKey('HASHTAG')
      setTextHashtag(decodeURIComponent(window.location.hash))
      // navigate(`/community${window.location.hash}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash])

  useEffect(() => {
    // if (postKey && textHashtag) {
    handleGetPost(true)
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postKey, textHashtag]);

  useEffect(() => {
    if (allPostWithPin) {
      setPostWithPin(allPostWithPin)
    }
  }, [allPostWithPin])

  useEffect(() => {
    if (allPost) {
      setPostLists(allPost)
    }
  }, [allPost])

  function handleRenderComment(itemPost) {
    return (
      <>
        { itemPost.comment && itemPost.comment.comments && itemPost.comment.comments.length > 0 && itemPost.comment.comments.map((itemComment, indexComment) => (
          <ItemPost key={indexComment} comment={1}>
            <WrapperUserPost>
              <Avatar src={itemComment.user.avatar} alt='Avatar' />
              <div>
                <Text small={1}>{itemComment.user.name}</Text>
                <Text secondary={1} xs={1}>{moment(itemComment.created_at).format('DD/MM/YYYY HH:mm')}</Text>
              </div>
            </WrapperUserPost>
            <WrapperTextPost>
              <WrapperText withcomment={1}>
                { handleTextPost(itemComment.txt) }
              </WrapperText>
              <WrapperImageGrid withcomment={1}>
                { handleRenderItemPost(itemComment) }
              </WrapperImageGrid>
            </WrapperTextPost>
          </ItemPost>
        ))}
      </>
    )
  }

  function handleRenderItemPost(itemPost) {
    return (
      <>
        { itemPost.images && itemPost.images.length > 0 && itemPost.images.map((itemPostImg, indexPostImg) => (
          <WrapperImagePost key={indexPostImg} item={itemPost?.images?.length}>
            <ImagePost
              src={itemPostImg}
              onClick={() => {
                setShowLightBox(true)
                setDataImageFullScreen(itemPostImg)
              }}
            />
          </WrapperImagePost>
        ))}
      </>
    )
  }
  function handleRenderPinPost(props) {
    const { withMobile } = props;
    return (
      <>
        { postWithPin && postWithPin.length > 0 && postWithPin.map((itemPost, indexPost) => (
          <ItemPost key={indexPost} withmobile={withMobile}>
            <WrapperUserPost>
              <Avatar src={itemPost.user.avatar} alt='Avatar' />
              <div>
                <Text small={1}>{itemPost.user.name}</Text>
                <Text secondary={1} xs={1}>{moment(itemPost.created_at).format('DD/MM/YYYY HH:mm')}</Text>
              </div>
              <WrapperHandleActionByUser withmobile={withMobile}>
                { itemPost.permission && itemPost.permission.pin
                  ? <WrapperPin data-testid='handle-edit-pin' onClick={() => handlePinPost(itemPost)} />
                  : <WrapperUnpin data-testid='handle-edit-pin' onClick={() => handlePinPost(itemPost)} />
                }
                { !withMobile && itemPost.permission && itemPost.permission.edit && <WrapperEdit data-testid='handle-edit-post' onClick={() => handleEdit(itemPost)} /> }
                { !withMobile && itemPost.permission && itemPost.permission.delete &&
                  <WrapperRemove data-testid='handle-delete-post' onClick={() => setIsShowRemove({ item: itemPost, type: 'PIN', arr: postWithPin})}/>
                }
              </WrapperHandleActionByUser>
            </WrapperUserPost>
            <WrapperTextPost>
              { itemEdit && itemEdit.id === itemPost.id
                ? <>
                    <WrapperInputPost>
                      <InputPost
                        data-testid='edit-input'
                        name='post'
                        value={inputEditPost && inputEditPost.post}
                        onChange={(e) => onChangeInputPost(e, 'edit')}
                        placeholder={`${t('what_are_you_thinking')} ${user.full_name}`}
                      />
                    </WrapperInputPost>

                    <WrapperMoreImage>
                      { editBase64img.length < 4 &&
                        <AddMoreImage htmlFor='moreFileUpload'>+</AddMoreImage>
                      }
                      <WrapperUploadImagePost>
                        <InputAddImg
                          type='file'
                          id='moreFileUpload'
                          accept='.png, .jpg, .heic, .heif'
                          onChange={(e) => handleBase64img(e, setEditTempBase64, setErrImg, showLoading, hideLoading)}
                        />
                        { editBase64img.map((itemImg, indexImg) => (
                          <WrapperItemImageUpload key={indexImg}>
                            <ImageUpload src={itemImg} />
                            <WrapperRemove withremoveimage={1} onClick={() => handleRemoveImgArr(itemImg, editBase64img, setEditBase64img)} />
                          </WrapperItemImageUpload>
                        ))}
                      </WrapperUploadImagePost>
                    </WrapperMoreImage>
                  </>
                : <>
                  <WrapperText withexpand={expandText === indexPost} withmobile={withMobile}>
                    { handleTextPost(itemPost.txt) }
                  </WrapperText>
                  { itemPost.txt && itemPost.txt.length > 500 && expandText !== indexPost &&
                    <WrapperSeemore onClick={() => setExpandText(indexPost)}>{t('see_more')}</WrapperSeemore>
                  }
                    <WrapperImageGrid withoutgap={1}>
                      { handleRenderItemPost(itemPost) }
                    </WrapperImageGrid>
                  </>
              }
            </WrapperTextPost>
            { itemEdit && itemEdit.id === itemPost.id
              ? <WrapperEditAction>
                  <WrapperButton
                    id='edit-button'
                    data-testid='edit-button'
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitEditPost()}
                  >
                    {t('edit')}
                  </WrapperButton>
                  <WrapperButton
                    data-testid='cancel-edit-button'
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => handleCancelEdit()}
                  >
                    {t('cancel')}
                  </WrapperButton>
                </WrapperEditAction>
              : <>
                  { itemPost.total_like > 0 &&
                    <WrapperTotalLike>
                      <IconLike src='/assets/images/like-community-fill.svg' alt='Like'/> { itemPost.total_like }
                    </WrapperTotalLike>
                  }
                  <WrapperOptionComment>
                    <BtnLike
                      data-testid='handle-like-post'
                      onClick={() => handleClickLike(itemPost, 'PIN', postWithPin)}
                      >
                        { itemPost.permission && itemPost.permission.unlike
                          ? <><IconLike src='/assets/images/like-community-fill.svg' alt='Like'/>
                              <WrapperTextLike active={itemPost.permission && itemPost.permission.unlike}>{t('like')}</WrapperTextLike>
                            </>
                          : <><IconLike src='/assets/images/like-community.svg' alt='Like'/>
                              <WrapperTextLike>
                                {t('like')}
                              </WrapperTextLike>
                            </>
                        }
                    </BtnLike>
                    <BtnComment data-testid='go-to-comment' onClick={() => handleComment(itemPost)}>
                      <WrapperComment /> {t('comment')}
                    </BtnComment>
                  </WrapperOptionComment>
                </>
            }
          </ItemPost>
        ))}
      </>
    )
  }

  function handleRenderPost() {
    return (
      <InfiniteScroll
        dataLength={postLists && postLists.length}
        next={handleGetPost}
        hasMore={hasMoreData}
        loader={<Loading open />}
      >
        { postLists && postLists.length > 0 && postLists.map((itemPost, indexPost) => (
          <ItemPost key={indexPost}>
            <WrapperUserPost>
              <Avatar src={itemPost.user.avatar} alt='Avatar' />
              <div>
                <Text small={1}>{itemPost.user.name}</Text>
                <Text secondary={1} xs={1}>{moment(itemPost.created_at).format('DD/MM/YYYY HH:mm')}</Text>
              </div>
              <WrapperHandleActionByUser>
                { itemPost.permission && itemPost.permission.pin
                  ? <WrapperPin data-testid='handle-edit-pin' onClick={() => handlePinPost(itemPost)} />
                  : <WrapperUnpin data-testid='handle-edit-pin' onClick={() => handlePinPost(itemPost)} />
                }
                { itemPost.permission && itemPost.permission.edit && <WrapperEdit data-testid='handle-edit-post' onClick={() => handleEdit(itemPost)} /> }
                { itemPost.permission && itemPost.permission.delete &&
                  <WrapperRemove data-testid='handle-delete-post' onClick={() => setIsShowRemove({item: itemPost, type: 'POST', arr: allPost})}/>
                }
              </WrapperHandleActionByUser>
            </WrapperUserPost>
            <WrapperTextPost>
              { itemEdit && itemEdit.id === itemPost.id
                ? <>
                    <WrapperInputPost>
                      <InputPost
                        data-testid='edit-input'
                        name='post'
                        value={inputEditPost && inputEditPost.post}
                        onChange={(e) => onChangeInputPost(e, 'edit')}
                        placeholder={`${t('what_are_you_thinking')} ${user.full_name}`}
                      />
                    </WrapperInputPost>

                    <WrapperMoreImage>
                      { editBase64img.length < 4 &&
                        <AddMoreImage htmlFor='moreFileUpload'>+</AddMoreImage>
                      }
                      <WrapperUploadImagePost>
                        <InputAddImg
                          type='file'
                          id='moreFileUpload'
                          accept='.png, .jpg, .heic, .heif'
                          onChange={(e) => handleBase64img(e, setEditTempBase64, setErrImg, showLoading, hideLoading)}
                        />
                        { editBase64img.map((itemImg, indexImg) => (
                          <WrapperItemImageUpload key={indexImg}>
                            <ImageUpload src={itemImg} />
                            <WrapperRemove withremoveimage={1} onClick={() => handleRemoveImgArr(itemImg, editBase64img, setEditBase64img)} />
                          </WrapperItemImageUpload>
                        ))}
                      </WrapperUploadImagePost>
                    </WrapperMoreImage>
                  </>
                : <>
                  <WrapperText withexpand={expandText === indexPost}>
                    { handleTextPost(itemPost.txt) }
                  </WrapperText>
                  { itemPost.txt && itemPost.txt.length > 500 && expandText !== indexPost &&
                    <WrapperSeemore onClick={() => setExpandText(indexPost)}>{t('see_more')}</WrapperSeemore>
                  }
                    <WrapperImageGrid withoutgap={1}>
                      { handleRenderItemPost(itemPost) }
                    </WrapperImageGrid>
                  </>
              }
            </WrapperTextPost>
            { itemEdit && itemEdit.id === itemPost.id
              ? <WrapperEditAction>
                  <WrapperButton
                    id='edit-button'
                    data-testid='edit-button'
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitEditPost()}
                  >
                    {t('edit')}
                  </WrapperButton>
                  <WrapperButton
                    data-testid='cancel-edit-button'
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => handleCancelEdit()}
                  >
                    {t('cancel')}
                  </WrapperButton>
                </WrapperEditAction>
              : <>
                  { itemPost.total_like > 0 &&
                    <WrapperTotalLike>
                      <IconLike src='/assets/images/like-community-fill.svg' alt='Like'/> { itemPost.total_like }
                    </WrapperTotalLike>
                  }
                  <WrapperOptionComment>
                    <BtnLike
                      data-testid='handle-like-post'
                      onClick={() => handleClickLike(itemPost, 'POST', allPost)}
                      >
                        { itemPost.permission && itemPost.permission.unlike
                          ? <><IconLike src='/assets/images/like-community-fill.svg' alt='Like'/>
                              <WrapperTextLike active={itemPost.permission && itemPost.permission.unlike}>{t('like')}</WrapperTextLike>
                            </>
                          : <><IconLike src='/assets/images/like-community.svg' alt='Like'/>
                              <WrapperTextLike>
                                {t('like')}
                              </WrapperTextLike>
                            </>
                        }
                    </BtnLike>
                    <BtnComment data-testid='go-to-comment' onClick={() => handleComment(itemPost)}>
                      <WrapperComment /> {t('comment')}
                    </BtnComment>
                  </WrapperOptionComment>
                  { handleRenderComment(itemPost) }
                </>
            }
          </ItemPost>
        ))}
      </InfiniteScroll>
    )
  }

  useEffect(() => {
    if (tempBase64) {
      setBase64img(current => [...current, tempBase64])
    }
  }, [tempBase64])

  useEffect(() => {
    if (editTempBase64) {
      setEditBase64img(current => [...current, editTempBase64])
    }
  }, [editTempBase64])

  return (
    <WrapperCommunityPage>
      <ProfileBox />
      <ProfileNav />
      <WrapperBodyCommunity withpin={allPostWithPin && allPostWithPin.length > 0}>
        { allPostWithPin && allPostWithPin.length > 0 &&
          <WrapperPinPost>
            <CommunityLandScapeLogo
              onClick={() => handleClickLogo()}
              withdesktop={1}
              src="/assets/images/community-landScape-logo.svg"
              alt="community"
            />
            <WrapperHeaderSection withpin={1}>
              <HeaderIcon src='/assets/images/header-pin-icon.svg' />
              <Text>#{t('pin')}</Text>
            </WrapperHeaderSection>
            { allPostWithPin && handleRenderPinPost({ withMobile: false }) }
          </WrapperPinPost>
        }
        <WrapperCommunitySection data-testid='community' withallpost={1}>
          <CommunityLandScapeLogo
            onClick={() => handleClickLogo()}
            withmobile={1}
            src="/assets/images/community-landScape-logo.svg"
            alt="community"
          />
          <WrapperCreatePost>
            <WrapperHeaderSection>
              <HeaderIcon src='/assets/images/moment-icon.svg' />
              <Text>#{t('moment')}</Text>
            </WrapperHeaderSection>
            <WrapperUserPost>
              <Avatar src={imgProfile} alt='Avatar' />
              <div>
                <Text small={1}>{user.full_name}</Text>
                <Text secondary={1} xs={1}>{user.email}</Text>
              </div>
            </WrapperUserPost>
            <WrapperInputPost>
              <InputPost
                data-testid='post-input'
                name='post'
                value={inputPost && inputPost.post}
                onChange={(e) => onChangeInputPost(e, 'post')}
                placeholder={`${t('what_are_you_thinking')} ${user.full_name}`}
              />
            </WrapperInputPost>
            { base64img && base64img.length > 0
              ? <WrapperMoreImage>
                  { base64img.length < 4 &&
                    <AddMoreImage htmlFor='moreFileUpload'>+</AddMoreImage>
                  }
                  <WrapperUploadImagePost>
                    <InputAddImg
                      type='file'
                      id='moreFileUpload'
                      accept='.png, .jpg, .heic, .heif'
                      onChange={(e) => handleBase64img(e, setTempBase64, setErrImg, showLoading, hideLoading)}
                    />
                    { base64img.map((itemImg, indexImg) => (
                      <WrapperItemImageUpload key={indexImg}>
                        <ImageUpload src={itemImg} />
                        <WrapperRemove withremoveimage={1} data-testid='remove-img' onClick={() => handleRemoveImgArr(itemImg, base64img, setBase64img)} />
                      </WrapperItemImageUpload>
                    ))}
                  </WrapperUploadImagePost>
                </WrapperMoreImage>
              : <WrapperOptionPost>
                  <WrapperAddImg>
                    <WrapperImgAddImg htmlFor='fileUpload'><AddImg src="/assets/images/add-img-community.svg" alt='Add Img' /></WrapperImgAddImg>
                    <InputAddImg
                      type='file'
                      id='fileUpload'
                      accept='.png, .jpg, .heic, .heif'
                      onChange={(e) => handleBase64img(e, setTempBase64, setErrImg, showLoading, hideLoading)}
                    /><Label xs htmlFor='fileUpload'>{t('attached_picture')}</Label>
                  </WrapperAddImg>
                  <WrapperPost>
                    <PostImage
                      id='imgPost'
                      src="/assets/images/post-community.svg"
                      alt='Post Community'
                      data-testid='create-post'
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handlePost()}
                    /><Label xs htmlFor='imgPost' onClick={() => handlePost()}>{t('post')}</Label>
                  </WrapperPost>
                </WrapperOptionPost>
            }
            { base64img && base64img.length > 0 &&
              <WrapperPost onClick={() => handlePost()} withimage={1}>
                <PostImage
                  src="/assets/images/post-community.svg"
                  alt='Post Community'
                  data-testid='create-post'
                  variant="contained"
                  color="primary"
                  fullWidth
                />
              </WrapperPost>
            }
          </WrapperCreatePost>

          { allPostWithPin && allPostWithPin.length > 0 &&
            <WrapperPinPostWithMobile>
              <WrapperHeaderSection withpin={1}>
                <HeaderIcon src='/assets/images/header-pin-icon.svg' />
                <Text>#{t('pin')}</Text>
              </WrapperHeaderSection>
              <PinPostWithMobileItem>
              { allPostWithPin && handleRenderPinPost({ withMobile: true }) }
              </PinPostWithMobileItem>
            </WrapperPinPostWithMobile>
          }

          { highlightHashtag && highlightHashtag.length > 0 &&
            <WrapperHighlightHashtag>
              <WrapperHeaderSection withpin={1}>
                <HeaderIcon src='/assets/images/highlight-hashtag-icon.svg' />
                <Text>#{t('highlight_hashtag')}</Text>
              </WrapperHeaderSection>
              <WrapperHighlightHashtagItem>
                { highlightHashtag.map((item, index) => (
                  <HighlightHashtagItem
                    key={index}
                    onClick={() => handleClickHashtag(item.tag)}
                    active={textHashtag && textHashtag === item.tag}
                  >
                    { item.tag }
                  </HighlightHashtagItem>
                ))}
              </WrapperHighlightHashtagItem>
            </WrapperHighlightHashtag>
          }

          <WrapperAllPost>
            { allPost && handleRenderPost() }
          </WrapperAllPost>
        </WrapperCommunitySection>
      </WrapperBodyCommunity>

      <NotifyDialog
        type="error"
        bodyText={t('err_img_community')}
        okText={t('ok_text')}
        open={errImg}
        onCloseDialog={() => setErrImg(false)}
        onOkDialogClick={() => setErrImg(false)}
      />


      <NotifyDialog
        id="idRemove"
        type="confirmation"
        bodyText={t('do_you_want_remove')}
        okText={t('confirm')}
        cancelText={t('cancel')}
        open={isShowRemove}
        onCloseDialog={() => setIsShowRemove(null)}
        onCancelDialogClick={() => setIsShowRemove(null)}
        onOkDialogClick={() => handleDeletePost(isShowRemove)}
      />

      { showLightBox &&
        <Lightbox
          mainSrc={dataImageFullScreen}
          onCloseRequest={() => {
            setShowLightBox(false)
            setDataImageFullScreen(null)
          }}
          reactModalProps={{
            overlay: {
              zIndex: 100
            }
          }}
        />
      }
    </WrapperCommunityPage>
  )
}

export default CommunityPage;
