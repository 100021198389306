import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Button, Stack, TextField, Paper, Switch, FormControlLabel } from '@mui/material'
import Swal from 'sweetalert2'
import  routePaths  from 'routesPath'
import { useBoRewardStore } from 'stores/BackOffice/boRewardStore'

export default function BORewardFormPage() {
  const [mode, setMode] = useState('Create')
  const [inputData, setInputData] = useState({
    name_th: '',
    name_en: '',
    points: 0,
    image: '',
    active: false
  })
  const [error, setError] = useState({})
  const [fetchRewardById, createReward, updateReward] = useBoRewardStore(
    (state) => [state.fetchRewardById, state.createReward, state.updateReward]
  )
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      setMode('Update')
      onFetchRewardById(parseInt(id))
    }
    // eslint-disable-next-line
  }, [])

  const onFetchRewardById = async (rewardId) => {
    try {
      const result = await fetchRewardById(rewardId)
      setInputData(result)
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
  }

  const handleInputChange = (name, value) => {
    if (name === 'points') {
      value = value > 999 ? 999 : value
    }
    setInputData({
      ...inputData,
      [name]: value,
    })
  }

  const handleSubmitClick = async () => {
    try {
      if (!validateInput()) {
        let result
        if (mode === 'Create') {
          result = await createReward(inputData)
        } else {
          result = await updateReward({ ...inputData, id: parseInt(id) })
        }

        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: mode + ' reward success',
          }).then((res) => {
            if (res.isConfirmed) {
              navigate(routePaths.BO_REWARD)
            }
          })
        } else {
          throw new Error('fail')
        }
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    }
  }

  const handleCancelClick = () => {
    navigate(routePaths.BO_REWARD)
  }

  const validateInput = () => {
    let isError = false
    let errorObj = {}
    if (!inputData.name_th) {
      isError = true
      errorObj = {
        ...errorObj,
        name_th: 'Please fill in Name (TH)',
      }
    }
    if (!inputData.name_en) {
      isError = true
      errorObj = {
        ...errorObj,
        name_en: 'Please fill in Name (EN)',
      }
    }
    if (!inputData.image) {
      isError = true
      errorObj = {
        ...errorObj,
        image: 'Please fill in Image Url',
      }
    }
    if (inputData.points <= 0) {
      isError = true
      errorObj = {
        ...errorObj,
        points: 'Points must greater than 0',
      }
    }
    setError(errorObj)
    return isError
  }

  return (
    <>
      <Grid container direction="row">
        <Grid item md={12}>
          <h2>{mode === 'Create' ? 'Add reward' : 'Edit reward'}</h2>
        </Grid>
      </Grid>

      <Paper elevation={3} sx={{ mt: 3, p: 4 }}>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12} md={6}>
            <TextField
              data-testid="rewardform-input-nameTH"
              id="rewardform-input-nameTH"
              label="Name (TH) *"
              size="small"
              fullWidth
              value={inputData?.name_th}
              onChange={(e) => handleInputChange('name_th', e.target.value)}
              error={error.name_th ? true : false}
              helperText={error.name_th ? error.name_th : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              data-testid="rewardform-input-nameEN"
              id="rewardform-input-nameEN"
              label="Name (EN) *"
              size="small"
              fullWidth
              value={inputData?.name_en}
              onChange={(e) => handleInputChange('name_en', e.target.value)}
              error={error.name_en ? true : false}
              helperText={error.name_en ? error.name_en : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              data-testid="rewardform-input-point"
              id="rewardform-input-point"
              label="Point *"
              size="small"
              fullWidth
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                inputProps: { min: 0, max: 999 },
              }}
              value={inputData?.points || 0}
              onChange={(e) =>
                handleInputChange('points', +e.target.value || 0)
              }
              error={error.points ? true : false}
              helperText={error.points ? error.points : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={<Switch
                data-testid="rewardform-switch-active"
                id="rewardform-switch-active"
                checked={inputData?.active}
                onChange={() => handleInputChange('active', !inputData.active)}
              />}
              label="Active"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              data-testid="rewardform-input-imageUrl"
              id="rewardform-input-imageUrl"
              label="Image Url *"
              size="small"
              fullWidth
              value={inputData?.image}
              onChange={(e) => handleInputChange('image', e.target.value)}
              error={error.image ? true : false}
              helperText={error.image ? error.image : ''}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Grid item xs={6} md={3}>
                <Button
                  data-testid="rewardform-btn-save"
                  id="rewardform-btn-save"
                  variant="contained"
                  fullWidth
                  onClick={() => handleSubmitClick()}
                >
                  save
                </Button>
              </Grid>
              <Grid item xs={6} md={3}>
                <Button
                  data-testid="rewardform-btn-cancel"
                  id="rewardform-btn-cancel"
                  variant="outlined"
                  fullWidth
                  onClick={() => handleCancelClick()}
                >
                  cancel
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
