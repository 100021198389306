import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuthStore } from 'stores/authStore'
import { useTranslation } from 'react-i18next'
import { useInspirationStore } from 'stores/inspirationStore'
import CustomButton from 'components/CustomButton'
import Loading from 'components/Loading'
import  routePaths  from 'routesPath'
import InfiniteScroll from 'react-infinite-scroll-component'
import ProfileBox from 'components/ProfileBox'
import ProfileNav from 'components/ProfileNav'
import './course.scss'

const DynamicsMenuById = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { course } = useParams()
  const [hasGroup] = useAuthStore((state) => [state.hasGroup])
  const [courses, clearCourses, fetchInspirationCourses] = useInspirationStore(
    (state) => [
      state.courses,
      state.clearCourses,
      state.fetchInspirationCourses
    ]
  )
  const [hasMoreData, setHasMoreData] = useState(true)
  const [paging, setPaging] = useState({
    page: 0,
    pageSize: 10,
  })

  const onFetchInspirationCourses = async (isClearPage = false) => {
    const newPaging = {
      ...paging,
      page: isClearPage ? 1 : paging.page + 1,
      sort: 'created_at desc',
      groupName: course
    }
    const result = await fetchInspirationCourses(newPaging)
    setHasMoreData(result)
    setPaging(newPaging)
  }

  const onMoreClick = (e, id) => {
    e.preventDefault()
    navigate(`/course/workshop/${id}`)
  }

  useEffect(() => {
    if (course) {
      if (course === 'DEFAULT') {
        clearCourses()
        onFetchInspirationCourses(true)
      } else {
        const permission = hasGroup(course)
        if (permission) {
          clearCourses()
          onFetchInspirationCourses(true)
        } else {
          navigate(routePaths.ACCESS_DENIED_403)
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course])

  return (
    <div className="is-container">
      <ProfileBox />
      <ProfileNav />
      <div className="page-header">{ t('course') }</div>
      {/* <div className='c-label-sl'>{t('oneSession')}</div> */}
      <InfiniteScroll
        className='wrapper-inspiration'
        dataLength={courses.length}
        next={onFetchInspirationCourses}
        hasMore={hasMoreData}
        loader={<Loading open />}
      >
        {courses && courses.length > 0 && courses.map((item, i) => {
          const revert = i % 2 === 0 ? '' : 'revert'
          return (
            <div key={i} className="card-container">
              <div className={`card-body ${revert}`}>
                <div className="card-img">
                  <img src={item.image} alt={item.title_en} />
                </div>
                <div className="card-content">
                  <div className="c-title">
                    {item[`title_${i18n.language}`]}
                  </div>
                  <div className="c-name">
                    {item[`instructor_${i18n.language}`]}
                  </div>
                  <div className="c-detail">
                    <p>{item[`description_${i18n.language}`]}</p>
                  </div>
                </div>
              </div>
              <div className="card-action">
                <CustomButton
                  id="btnMore"
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={(e) => onMoreClick(e, item.id)}
                >
                  {t('more')}
                </CustomButton>
              </div>
            </div>
          )
        })}
      </InfiniteScroll>
    </div>
  )
}

export default DynamicsMenuById
