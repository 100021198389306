import React, { createElement, useEffect, useState } from 'react'
import SideBar from './SideBar'
import TopBar from './TopBar'
import dynamicsMenuApi from 'api/dynamicsMenuApi'
import './main-layout.scss'

export default function BOMainLayout({ children, enableSideBar = false }) {
  const [sidebarIsOpen, setSidebarOpen] = useState(enableSideBar)
  const toggleSidebar = (e) => {
    e.preventDefault()
    setSidebarOpen(!sidebarIsOpen)
  }
  const [dataList, setDataList] = useState()
  const onMenuItemClick = () => {
    setSidebarOpen(!sidebarIsOpen)
  }
  useEffect(()=>{
    fetchMenuList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchMenuList = () => {
    dynamicsMenuApi.getMenuLists().then((res)=>{
      const resdata = res?.data?.data.map((e) => {
        const el = createElement(e.icon)
        return{
          id: e.id,
          open: e.is_open,
          name: e.name,
          to: e.to_page,
          icon: el,
          group_name: e.group_name,
          role_permission: e.role_permission,
          isDevide: e.is_didive,
          childen: e.childen
        }
      })
      setDataList(resdata)
    })
  }

  return (
    <div className="bo-root">
      <TopBar toggleSidebar={toggleSidebar} />
      <SideBar
        id="menuSidebar"
        toggle={toggleSidebar}
        onMenuItemClick={onMenuItemClick}
        isOpen={sidebarIsOpen}
        dataList={dataList}
      />
      <div className="bo-content-container">
        <div className="bo-body-container">{children}</div>
      </div>
    </div>
  )
}
