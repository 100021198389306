import { useEffect } from 'react'
import { useLoading } from 'components/Loading/context'
import { useBoInspirationWorkshopStore } from 'stores/BackOffice/boInspirationWorkshop'
import workshopApi from 'api/inspirationWorkshopApi'
import Swal from 'sweetalert2'
import WorkshopForm from './CommonWorkshopForm'

const AddCommonWorkshopPage = () => {
  const { showLoading, hideLoading } = useLoading()

  const [clearWorkshop] = useBoInspirationWorkshopStore((state) => [
    state.clearWorkshop,
  ])
  let pathname = window.location.pathname;
  pathname = pathname.substring(7)
  pathname = pathname.substring(0,pathname.indexOf("/")).toUpperCase()
  useEffect(() => {
    clearWorkshop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (workshop) => {
    showLoading()
    try {
      const result = await workshopApi.createWorkshop(workshop)

      if (result.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Add Workshop success',
        }).then((res) => {
          if (res.isConfirmed) {
            window.location.href = '/bo/dm/'+ pathname.toLowerCase() +'/workshop'
          }
        })
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      hideLoading()
    }
  }

  return <WorkshopForm mode="Add" onSave={handleSave} />
}

export default AddCommonWorkshopPage
