import React, { Fragment, useEffect, useState } from 'react'
import { useInspirationStore } from 'stores/inspirationStore'
import { Html5QrcodeScanner } from 'html5-qrcode'
import { fDate, fISODateToUTC } from 'utils/formatTime'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import ProfileNav from 'components/ProfileNav'
import ProfileBox from 'components/ProfileBox'
import Loading from 'components/Loading'
import HeartPoint from 'components/HeartPoint'
import CustomButton from 'components/CustomButton'
import NotifyDialog from 'components/NotifyDialog'

import {
  WrapperCheckInPage,
  HaderPage,
  WrapperSpecialPoint,
  WrapperButtonSpecialPoint,
  InputSpecialPoint
} from 'pages/Check-in/check-in.style'

function CheckInPage() {
  const { t, i18n } = useTranslation()
  const [hasMoreData, setHasMoreData] = useState(true)
  const [paging, setPaging] = useState({
    page: 0,
    pageSize: 10,
  })

  const [selectedCheckIn, setSelectedCheckIn] = useState(false)
  const [selectedSession, setSelectedSession] = useState()
  const [responseCheckIn, setResponseCheckIn] = useState()
  const [onChangeSpecialPoint, setOnChangeSpecialPoint] = useState()

  let scanner

  const [
    workshops,
    clearWorkshops,
    getAllWorkShop,
    checkInWorkShop
  ] = useInspirationStore((state) => [
    state.workshops,
    state.clearWorkshops,
    state.getAllWorkShop,
    state.checkInWorkShop
  ])

  useEffect(() => {
    clearWorkshops()
    handleGetWorkshop(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChangeSpecialPoint(e) {
    const { name, value } = e.target
    setOnChangeSpecialPoint({
      ...onChangeSpecialPoint,
      [name]: value
    })
  }

  async function handleGetWorkshop(isClearPage = false) {
    const newPaging = {
      ...paging,
      page: isClearPage ? 1 : paging.page + 1,
      sort: 'created_at desc',
    }
    const result = await getAllWorkShop(newPaging)
    setHasMoreData(result)
    setPaging(newPaging)
  }

  function handleCheckIn(e, workshop, sessionItem, type) {
    e.preventDefault()
    if (type === 'QA') {
      setSelectedSession({
        point: parseInt(onChangeSpecialPoint.specialPoint, 10),
        type: 'QA'
      });
    } else {
      setSelectedSession({
        sessionId: sessionItem.id,
        point: workshop.points,
        type: 'CHECKIN'
      });
    }
    setSelectedCheckIn(true)
  }

  function onClearCamera() {
    scanner.clear().then(_ => {
      setSelectedCheckIn(false);
    }).catch(error => {
      console.log('error : ', error);
    })
  }

  function renderWorkshop() {
    return (
      workshops && workshops.length > 0 && workshops.map((item, i) => {
        return (
          <div key={i} className="card-container">
            <div className="card-img">
              <img src={item.image} alt="Workshop A1" />
            </div>
            <div className="card-content">
              <div className="c-title-container">
                <div className="c-title">
                  {item[`title_${i18n.language}`]}
                </div>
                <HeartPoint point={item.points} size="sm" />
              </div>
              <div className="c-detail">
                <p>{item[`description_${i18n.language}`]}</p>
              </div>
              <div className="c-sub-title">
                {fDate(fISODateToUTC(item.event_date))}
              </div>

              {item && item.sessions && item.sessions.length > 0 && item.sessions.map((sessionItem, rIndex) => {
                return (
                  <div key={rIndex} className="c-period">
                    <div className="c-time-round">
                      {`${sessionItem.event_time} ${t('limit')} `}
                      <b>{`(${sessionItem.total_reserved}/${sessionItem.quota})`}</b>
                    </div>
                    <CustomButton
                      data-testid='check-in'
                      id="btnCheckIn"
                      variant="contained"
                      color={sessionItem.reserved ? 'error' : 'primary'}
                      onClick={(e) => handleCheckIn(e, item, sessionItem, 'CHECKIN')}
                    >
                      {t('check-in')}
                    </CustomButton>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })
    )
  }

  useEffect(() => {
    if (selectedCheckIn) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scanner = new Html5QrcodeScanner('reader', {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
        // videoConstraints: { facingMode: { exact: 'environment' } },
      });
      let isScanning = true;
      scanner.render(success, error,);
      async function success(string) {
        if (isScanning && selectedSession) {
          scanner.clear();
          isScanning = false;
          const data = {
            email: string,
            sessionId: selectedSession.sessionId,
            point: selectedSession.point,
            type: selectedSession.type
          }

          const result = await checkInWorkShop(data)
          if (result) {
            setResponseCheckIn(result)
            onClearCamera()
            handleGetWorkshop()
          }
        }
      }
      function error(err) {
        console.warn(err);
      }
      document.body.style.overflow = "hidden";
      return () => (document.body.style.overflow = "scroll");
    }
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckIn]);

  return (
    <WrapperCheckInPage>
      <ProfileBox />
      <ProfileNav />
      { workshops &&
        <>
          <HaderPage data-testid='course'>{t('course')}</HaderPage>
          <form onSubmit={(e) => handleCheckIn(e, '', '', 'QA')}>
            <WrapperSpecialPoint>
              <InputSpecialPoint
                type="number"
                name="specialPoint"
                label="Special Point"
                size="small"
                min='1'
                placeholder='Special Point'
                required
                onChange={(e) => handleChangeSpecialPoint(e)}
              />
              <WrapperButtonSpecialPoint>
                <CustomButton
                  data-testid='check-in'
                  id="btnCheckIn"
                  variant="contained"
                  color='primary'
                  type='submit'
                >
                  {t('check-in')}
                </CustomButton>
              </WrapperButtonSpecialPoint>
            </WrapperSpecialPoint>
          </form>
          <div className="ws-container">
            <InfiniteScroll
              dataLength={workshops && workshops.length}
              next={handleGetWorkshop}
              hasMore={hasMoreData}
              loader={<Loading open />}
            >
              { renderWorkshop() }
            </InfiniteScroll>

            { selectedCheckIn
            ? <Fragment>
                <div id="reader" data-testid='camera'></div>
                <CustomButton
                  id="btnDisableCamera"
                  variant="contained"
                  color={'primary'}
                  onClick={() => onClearCamera()}
                >
                  {t('close')}
                </CustomButton>
              </Fragment>
            : '' }

            <div id="all-dialog">
              <NotifyDialog
                type="success"
                bodyText={responseCheckIn?.data}
                okText={t('confirm')}
                open={responseCheckIn?.data}
                onCloseDialog={() => setResponseCheckIn(false)}
                onOkDialogClick={() => setResponseCheckIn(false)}
              />

              <NotifyDialog
                type="error"
                title={t('requestSentFail')}
                bodyText={responseCheckIn?.error?.message || t('systemError')}
                okText={t('confirm')}
                open={responseCheckIn?.error}
                onCloseDialog={() => setResponseCheckIn(false)}
                onOkDialogClick={() => setResponseCheckIn(false)}
              />
            </div>
          </div>
        </>
      }
    </WrapperCheckInPage>
  )
}

export default CheckInPage;
