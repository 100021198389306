import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthStore } from 'stores/authStore'
import {
  WrapperProfileBox,
  WrapperProfileImage,
  ProfileImage,
  WrapperFullName,
  WrapperAccmulatePointComponent
} from 'components/ProfileBox/profile.style'
import { Text } from 'components/Text'
import SelectLangComponent from 'components/SelectLangComponent'
import AccmulatePointComponent from 'components/AccmulatePointComponent'

const ProfileBox = () => {
  const { t } = useTranslation()
  const [user, imgProfile, fetchUserInfo] = useAuthStore((state) => [
    state.user,
    state.imgProfile,
    state.fetchUserInfo,
  ])

  useEffect(() => {
    fetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const firstName =
    user?.full_name?.split(' ')?.length > 0
      ? user?.full_name?.split(' ')[0]
      : ''
  const lastName = user?.full_name?.substring(user?.full_name?.indexOf(' ') + 1)

  return (
    <WrapperProfileBox>
      <WrapperProfileImage>
        <ProfileImage
          alt='Profile'
          src={ imgProfile ? imgProfile : `/assets/images/default-profile-img.png` }
          circle={imgProfile}
        />
        <SelectLangComponent />
      </WrapperProfileImage>
      <WrapperFullName>
        <Text subtitle={1} withprofile={1}>{t('welcome')}!</Text>
        <Text title={1} withprofile={1}>{firstName} {lastName}</Text>
      </WrapperFullName>
      <WrapperAccmulatePointComponent>
        <AccmulatePointComponent
          accumulatePoints={user.accumulate_points}
          fullPoints={user.ful_points}
          usedPoints={user.used_points}
          balancePoints={user.balance_points}
          withProfile
        />
      </WrapperAccmulatePointComponent>
    </WrapperProfileBox>
  )
}

export default ProfileBox
