import { createHttpClient } from './me2weApi'

class Community {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async userReport(req) {
    return this.client.get('/report/export/users')
  }

  async pointReport(req) {
    return this.client.get('/report/export/point/assigned')
  }
}

const reportApi = new Community()
export default reportApi
