import { createHttpClient } from './me2weApi'

class WhitelistRequest {
    constructor() {
      if (!this.client) {
        this.client = createHttpClient()
      }
    }

    async getUserByGroup(data){
        return this.client.post('/users/getList', data ? {
            page: data.page ,
            page_size: data.page_size,
            group_id: data.group_id,
            group_name: data.group_name,
            full_name: data.full_name
        } : {})
    }

    async updateGroupUserByUserId(data){
        return this.client.post('/users/updateGroupUserByUserId', {
            id: data.id ,
            group_id : data.group_id
        })
    }

    async getUserGroupList(){
        return this.client.post('/users/getUserGroupList')
    }

    async getUserGroupById(data){
        return this.client.post('/users/getUserGroupById', {
            id: data.id || '',
        })
    }

    async createUserGroup(data){
        return this.client.post('/users/createUserGroup', {
            group_name: data.group_name,
        })
    }

    async updateUserGroup(data){
        return this.client.post('/users/updateUserGroup', {
            id: data.id ,
            group_name: data.group_name
        })
    }

    async deleteUserGroup(data){
        return this.client.post('/users/deleteUserGroup', {
            id: data.id,
        })
    }

    async pointAuditLogs(data){
        return this.client.post('/users/point/logs', data ? {
            page: data.page ,
            page_size: data.page_size,
            full_name: data.full_name
        } : {})
    }

    async addPointToUser(data){
        return this.client.post('/users/point/add', data)
    }
}

const whitelistRequestApi = new WhitelistRequest()

export default whitelistRequestApi
