import { createHttpClient } from './me2weApi'

class Books {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getBooks(data) {
    return this.client.post('/books/getList', {
      title: data.title || '',
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || 'created_at desc',
    })
  }

  async getBookById(id) {
    return this.client.post('/books/getById', { id })
  }

  async createBook(data) {
    return this.client.post('/books/create', data)
  }

  async updateBook(data) {
    return this.client.post('/books/updateById', data)
  }

  async deleteBook(id) {
    return this.client.post(`/books/deleteById`, { id })
  }
}

const booksApi = new Books()
export default booksApi
