import { useEffect } from 'react'

const RedirectPage = ({ url }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])

  return (
    <div style={{ padding: '0px 26px' }}>
      <h5>Redirecting...</h5>
    </div>
  )
}

export default RedirectPage
