import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Paper,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import PencilIcon from '@mui/icons-material/Edit'
import ModalAddGroup from './modalAddGroup'
import whitelistRequestApi from 'api/whitelistRequestApi'
import Swal from 'sweetalert2'
import  routePaths  from 'routesPath'
import { useNavigate } from 'react-router-dom'
import { useBoMenuStore } from 'stores/BackOffice/boMenuStore'
import { useDynamicsMenuStore } from 'stores/dynamicsMenuStore'
import Loading from 'components/Loading'

const initColumn = [
  {
    id: 'no',
    label: 'No.',
    align: 'center',
    minWidth: 100,
  },
  {
    label: '',
    minWidth: 50
  },
  {
    id: 'group_name',
    label: 'Group',
    minWidth: 120,
  },
  {
    id: 'del',
    minWidth: 50
  }
]

export default function BOWhitelistFormPage() {
  const navigate = useNavigate()
  const [groupList, setGroupList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [mode, setMode] = useState("ADD")
  const [objEdt, setObjEdt] = useState({})
  const [ removeMenu, list, fetchMenu ] = useBoMenuStore((state) => [state.removeMenu,state.list,state.fetchMenu])
  const [ allMenuLists, getAllMenuLists ] = useDynamicsMenuStore((state) => [state.allMenuLists,state.getAllMenuLists])
  const [waiting, setWaiting] = useState(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchData()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isModalOpen])
  const fetchData = async () => {
    const res = await whitelistRequestApi.getUserGroupList()
    setGroupList(res.data)
  }
  const onDeleteClick = async (id,titleN) => {
    Swal.fire({
      icon: 'info',
      text: 'Do you want to remove this group menu',
      showCancelButton: true,
      cancelButtonText: 'Cancel'
    }).then(async (e) =>{
      if(e.isConfirmed){
        setWaiting(true)
        const req = {
          id: id
        }
        await fetchMenu()
        await getAllMenuLists()
        const menuUSERId =  allMenuLists.find(x => x.group_name === titleN)?.id
        const menuBOId = list?.find(v => v.group_name === titleN)?.id
        const arrListId = [menuBOId, menuUSERId]
        const res = await whitelistRequestApi.deleteUserGroup(req)
        for(let i=0;i<arrListId.length;i++){
          await removeMenu({ id : arrListId[i]})
        }
        setWaiting(false)
        if(res.status === 200) {
          Swal.fire({
            icon: 'success',
            text: 'Delete group & menu success'
          }).then(async () =>{
          await fetchData()
          })
        }
      }
    })

  }
  const onEditClick = async(id,titleN) => {
    setMode("EDIT")
    const selectList = list.find(e => e.group_name === titleN )
    setObjEdt(selectList)
    setIsModalOpen(true)
  }
  const onAddClick = async(flag) => {
    setMode("ADD")
    setIsModalOpen(flag)
  }
  const backToMain = () => {
    navigate(routePaths.BO_WHITELIST)
  }
  return (
    <>
    <Loading open={waiting} />
    <ModalAddGroup open={isModalOpen} mode={mode} dataEdit={objEdt} handleClose={() => setIsModalOpen(false)}/>
      <Grid container direction="row">
        <Grid item md={12}>
          <h2>Group Menu We Together</h2>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item md={4}></Grid>
        <Grid item md={4}></Grid>
        <Grid item md={4}>
          <Button
            id="group-btn-add"
            variant="contained"
            color="success"
            fullWidth
            onClick={() => onAddClick(true)}>
              Add New Group Menu
          </Button>
        </Grid>
      </Grid>
      <Paper elevation={3} sx={{ mt: 3, p: 4 }}>
        <Grid  container spacing={2} direction="row">
          <Grid item md={12} xs={12}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {initColumn.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(groupList.data || []).map((data, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell align='center'>
                          {i + 1 }
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>{data.group_name}</TableCell>
                        <TableCell>
                          <IconButton
                            id="whitelist-course-btn-delete"
                            color="primary"
                            onClick={(e) => onEditClick(data.id,data.group_name)}
                          >
                            <PencilIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            id="whitelist-course-btn-delete"
                            color="error"
                            onClick={(e) => onDeleteClick(data.id,data.group_name)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
                </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
      <Button onClick={() => backToMain()}>Back</Button>
    </>
  )
}
