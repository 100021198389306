import { create } from 'zustand'
import reportApi from 'api/reportApi'

export const useBoReportStore = create((set, get) => ({
  userReport: async (params) => {
    const response = await reportApi.userReport(params)
    return response.data
  },
  pointReport: async (params) => {
    const response = await reportApi.pointReport(params)
    return response.data
  },
}))
