import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import Input from '@mui/base/Input'

import './search-field.scss'

const StyledInputElement = styled('input')(
  ({ theme }) => `
    height: 30px;
    padding: 0px 16px;
    background: #FEFDFE;
    border: 1px solid #C5C5C7;
    border-radius: 34px;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  
    &:hover {
      border-color: #007FFF;
    }
  
    &:focus {
      border-color: #007FFF;
      outline: 2px solid #007FFF;
    }
  `
)

const SearchInput = forwardRef(function CustomSearchInput(props, ref) {
  return <Input slots={{ input: StyledInputElement }} {...props} ref={ref} />
})

const SearchField = (props) => {
  const { onClick, onChange, placeholder } = props
  const { t } = useTranslation()

  return (
    <div className="sf-container">
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={9}>
          <SearchInput
            id="txtSearchInput"
            aria-label="Search input"
            placeholder={placeholder ? t(placeholder) : t('searchHere')}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={1}>
          <img
            id="btnSearch"
            src="assets/images/search-icon.svg"
            alt="search-icon"
            style={{ float: 'right' }}
            onClick={onClick}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchField
