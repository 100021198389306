import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ProfileBox from 'components/ProfileBox'
import CustomButton from 'components/CustomButton'
import  routePaths  from 'routesPath'

import Config from 'config'

import {
  WrapperHomePage,
  WrapperBoxMenuHomePage,
  ItemMenuHomePage,
  ImageMenuHomePage,
  WrapperHighlight,
  WrapperTitleHighlight,
  ImgHighlight,
  WrapperContentHomePage,
  MenuList,
  Video,
  Source
} from 'pages/Home/home.style'

import { Text } from 'components/Text'
import ProfileNav from 'components/ProfileNav'

const inspirationImgElement = {
  alt: 'Inspiration',
  src: 'new-inspiration.svg'
}

const inspirationBtnElement = {
  id: 'btnInspirations',
  label: 'Inspiration Hours'
}

const communityBtnElement = {
  id: 'btnCommunity',
  label: 'Community'
}

const bookImgElement = {
  alt: 'Books',
  src: 'new-book.svg'
}
const bookBtnElement = {
  id: 'btnBooks',
  label: 'get_book'
}

const addFuelImgElement = {
  alt: 'Add Fuel',
  src: 'add-fuel.png'
}
const addFuelBtnElement = {
  id: 'btnAddFuel',
  label: 'addFuel'
}

const weTogetherImgElement = {
  alt: 'We Together',
  src: 'new-we-together.svg'
}

const weTogetherBtnElement = {
  id: 'btnWeTogether',
  label: 'We Together'
}

const whatIsImgElement = {
  alt: 'What is me to We outbox',
  src: 'what-is-box.png'
}

const whatIsBtnElement = {
  id: 'btnWhatIsMeToWe',
  label: 'whatIsMeToWeOutbox'
}

const prizeCollectionImgElement = {
  alt: 'Prize collection',
  src: 'new-reward.svg'
}
const prizeCollectionBtnElement = {
  id: 'btnPrizeCollection',
  label: 'prizeCollection'
}

const contactImgElement = {
  alt: 'Contact',
  src: 'contact.png'
}

const communityImgElement = {
  alt: 'Community',
  src: 'community-menu-home.png'
}

const contactBtnElement = {
  id: 'btnContact',
  label: 'contact'
}

function HomePage() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onNavigateClick = (e, url, isExternal) => {
    e.preventDefault()
    if (isExternal) {
      window.open(url, '_blank')
    } else {
      navigate(url)
    }
  }

  const menuLists = [
    {
      element: {
        id: 'btnContainerInspirations',
        routePaths: (e) => onNavigateClick(e, routePaths.INSPIRATIONS),
        isActive: true,
        img: inspirationImgElement,
        btn: inspirationBtnElement
      },
    },
    {
      element: {
        id: 'btnContainerCommunity',
        routePaths: (e) => onNavigateClick(e, routePaths.COMMUNITY),
        isActive: true,
        img: communityImgElement,
        btn: communityBtnElement
      },
    },
    // {
    //   element: {
    //     id: 'btnContainerELearning',
    //     routePaths: (e) => onNavigateClick(e, routePaths.E_LEARNING),
    //     isActive: true,
    //     img: elearningImgElement,
    //     btn: elearningBtnElement
    //   },
    // },
    {
      element: {
        id: 'btnContainerBooks',
        routePaths: (e) => onNavigateClick(e, routePaths.BOOKS),
        isActive: true,
        img: bookImgElement,
        btn: bookBtnElement
      },
    },
    {
      element: {
        id: 'btnContainerAddFuel',
        routePaths: (e) => onNavigateClick(e, routePaths.ADDFUEL),
        isActive: false,
        img: addFuelImgElement,
        btn: addFuelBtnElement
      },
    },
    {
      element: {
        id: 'btnContainerWeTogether',
        routePaths: (e) => onNavigateClick(e, routePaths.WETOGETHER),
        isActive: true,
        img: weTogetherImgElement,
        btn: weTogetherBtnElement
      },
    },
    {
      element: {
        id: 'btnContainerWhatIsMeToWe',
        routePaths: (e) => onNavigateClick(e, routePaths.WHATISMETOWE),
        isActive: false,
        img: whatIsImgElement,
        btn: whatIsBtnElement
      },
    },
    {
      element: {
        id: 'btnContainerPrizeCollection',
        routePaths: (e) => onNavigateClick(e, routePaths.PRIZE_COLLECTION),
        isActive: true,
        img: prizeCollectionImgElement,
        btn: prizeCollectionBtnElement
      },
    },
    {
      element: {
        id: 'btnContainerNewNormal',
        routePaths: (e) => onNavigateClick(e, Config.externalUrl.newNormal, true),
        isActive: false,
        img: prizeCollectionImgElement,
        btn: prizeCollectionBtnElement
      },
    },
    {
      element: {
        id: 'btnContainerContact',
        routePaths: (e) => onNavigateClick(e, routePaths.CONTACT),
        isActive: false,
        img: contactImgElement,
        btn: contactBtnElement
      },
    }
    // {
    //   element: {
    //     id: 'btnContainerTransfer',
    //     routePaths: (e) => onNavigateClick(e, routePaths.TRANSFER_POINT),
    //     isActive: true,
    //     img: transferImgElement,
    //     btn: transferBtnElement
    //   },
    // }
  ];

  return (
    <>
      <WrapperHomePage>
        <ProfileBox />
        <ProfileNav />
        <WrapperContentHomePage>
          <WrapperHighlight>
            <WrapperTitleHighlight>
              <ImgHighlight alt='Highlight' src='/assets/images/highlight.svg' />
              <Text title={1}>{t('highlight')}</Text>
            </WrapperTitleHighlight>
             <Video controls>
              <Source src='/assets/video/me-to-we.mp4' type='video/mp4' />
            </Video>
          </WrapperHighlight>
          <WrapperBoxMenuHomePage>
            <WrapperTitleHighlight>
              <ImgHighlight alt='Menu' src='/assets/images/menu.svg' />
              <Text title={1}>{t('menu')}</Text>
            </WrapperTitleHighlight>
            <MenuList>
              { menuLists.map((item, index) => {
                const el = item.element;
                const img = el.img;
                const btn = el.btn;
                return (
                  el.isActive &&
                  <ItemMenuHomePage
                    key={index}
                    id={el.id}
                    onClick={el.routePaths}
                  >
                    <ImageMenuHomePage
                      alt={img.alt}
                      src={`/assets/images/${img.src}`}
                    />
                    <CustomButton
                      id={btn.id}
                      color='primary'
                      variant='contained'
                      fullWidth
                    >
                      { t(btn.label) }
                    </CustomButton>
                  </ItemMenuHomePage>
              )})}
            </MenuList>
          </WrapperBoxMenuHomePage>
        </WrapperContentHomePage>
      </WrapperHomePage>
    </>
  )
}

export default HomePage;
