import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loading({ open }) {
  return (
    <>
      <Backdrop
        data-testid="backdrop"
        sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
        open={open}
      >
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: '#146EAB',
            animationDuration: '750ms',
          }}
          size={35}
          thickness={4}
        />
      </Backdrop>
    </>
  )
}
