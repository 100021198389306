import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from 'stores/authStore'
import  routePaths  from 'routesPath'
import ProfileBox from 'components/ProfileBox'

import {
  WrapperAddFuelPage,
  ImageKid,
  WrapperImageProp,
  ImageGround,
  ImageCloud,
  ImageBox,
  WrapperButton,
  WrapperInspiration,
  ImageCloudMenu,
  ImageTextMenu,
  ELearning,
  Books
} from 'pages/AddFuel/add-fuel.style';
import ProfileNav from 'components/ProfileNav'

const AddFuelPage = () => {
  const navigate = useNavigate()

  const [fetchFuel, fuel] = useAuthStore((state) => [
    state.fetchFuel,
    state.fuel,
  ])

  useEffect(() => {
    fetchFuel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBoxClick = (e, url) => {
    e.preventDefault()
    navigate(url)
  }

  const renderBox = (title, className, point, url) => {
    return (
      <WrapperButton onClick={(e) => onBoxClick(e, url)}>
        {title === 'Books' && (
          <Books>
            <ImageBox
              className='book-box'
              alt='Book Box'
              src='/assets/images/box-book.svg'
            />
            <ImageCloudMenu
              className='book-cloud'
              alt='Book Cloud'
              src='/assets/images/cloud-book.svg'
              book={1}
            />
            <ImageTextMenu
              className='book-text'
              alt='Book'
              src='/assets/images/book.svg'
              book={1}
            />
          </Books>
        )}
        {title === 'E-Learning' && (
          <ELearning>
            <ImageBox
              className='e-learning-box'
              alt='E-Learning Box'
              src='/assets/images/box-e-learning.svg'
            />
            <ImageCloudMenu
              className='e-learning-cloud'
              alt='E-Learning Cloud'
              src='/assets/images/cloud-e-learning.svg'
              elearning={1}
            />
            <ImageTextMenu
              className='e-learning-text'
              alt='E-Learning'
              src='/assets/images/e-learning.svg'
              elearning={1}
            />
          </ELearning>
        )}
        {title === 'Inspiration Hours' && (
          <WrapperInspiration>
            <ImageBox
              className='inspiration-box'
              alt='Inspiration Hours Box'
              src='/assets/images/box-inspiration.png'
            />
            <ImageCloudMenu
              className='inspiration-cloud'
              alt='Inspiration Hours Cloud'
              src='/assets/images/cloud-inspiration.png'
              inspiration={1}
            />
            <ImageTextMenu
              className='inspiration-text'
              alt='Inspiration Hours'
              src='/assets/images/inspiration.png'
              inspiration={1}
            />
          </WrapperInspiration>
        )}
      </WrapperButton>
    )
  }

  const imageGround = ['1', '2', '3', '4'];

  return (
    <WrapperAddFuelPage>
      <ProfileBox />
      <ProfileNav />
      <WrapperImageProp>
        <ImageKid alt='Kid with box' src='/assets/images/kid-2.svg' />
        { imageGround && imageGround.map((item, index) => (
          <ImageGround
            key={index}
            alt={`ground-bg-layer-${item}`}
            src={`/assets/images/ground-bg-layer-${item}.svg`}
            layer={item}
          />
        ))}
        <ImageCloud
          alt='cloud'
          src='/assets/images/cloud-bg-left-large.svg'
          left={1}
        />
        <ImageCloud
          alt='cloud'
          src='/assets/images/cloud-bg-left-small.svg'
          left={1}
        />
        <ImageCloud
          alt='cloud'
          src='/assets/images/cloud-bg-right-large.svg'
          right={1}
        />
        <ImageCloud
          alt='cloud'
          src='/assets/images/cloud-bg-right-small.svg'
          right={1}
        />
        {renderBox(
          'Books',
          'book-box',
          fuel.book_points,
          routePaths.BOOKS
        )}
        {renderBox(
          'Inspiration Hours',
          'inspiration-box',
          fuel.inspiration_points,
          routePaths.INSPIRATIONS
        )}
        {renderBox(
          'E-Learning',
          'e-learning-box',
          fuel.elearning_points,
          routePaths.E_LEARNING
        )}
      </WrapperImageProp>
    </WrapperAddFuelPage>
  )
}

export default AddFuelPage
