import { fUTCDateToIsoStart, fUTCDateToIsoEnd } from 'utils/formatTime'
import { createHttpClient } from './me2weApi'

const pathWorkshopList = '/inspirations/getWorkshopList'
const sortList = 'created_at desc'
class Workshop {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getWorkshops(data) {
    return this.client.post(pathWorkshopList, {
      title: data.title || '',
      event_date_start: fUTCDateToIsoStart(data.event_date_start),
      event_date_end: fUTCDateToIsoEnd(data.event_date_end),
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || sortList,
      group_id: data.group_id || 1
    })
  }

  async getWorkshopById(id) {
    return this.client.post('/inspirations/getWorkshopById', { id })
  }

  async createWorkshop(data) {
    delete data.created_at
    delete data.updated_at
    delete data.id

    return this.client.post('/inspirations/createWorkshop', {
      ...data,
      event_date: fUTCDateToIsoStart(data.event_date),
    })
  }

  async updateWorkshopById(data) {
    return this.client.post('/inspirations/updateWorkshopById', {
      ...data,
      event_date: fUTCDateToIsoStart(data.event_date),
    })
  }

  async deleteWorkshopById(id) {
    return this.client.post(`/inspirations/deleteWorkshopById`, { id })
  }
  async getEmiWorkshops(data) {
    return this.client.post(pathWorkshopList, {
      title: data.title || '',
      event_date_start: fUTCDateToIsoStart(data.event_date_start),
      event_date_end: fUTCDateToIsoEnd(data.event_date_end),
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || sortList,
      group_id: data.group_id || 2
    })
  }
  async getNewWorkshops(data) {
    return this.client.post(pathWorkshopList, {
      title: data.title || '',
      event_date_start: fUTCDateToIsoStart(data.event_date_start),
      event_date_end: fUTCDateToIsoEnd(data.event_date_end),
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || sortList,
      group_id:  data.group_id
    })
  }
}

const booksApi = new Workshop()
export default booksApi
