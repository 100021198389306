import { createHttpClient } from './me2weApi'

class Reward {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getRewards(data) {
    return this.client.post('/rewards/getList', {
      name: data.name || '',
      page: data.page || 1,
      page_size: data.pageSize,
      sort: data.sort || 'created_at desc',
    })
  }

  async getRewardById(id) {
    return this.client.post('/rewards/getById', { id })
  }

  async createReward(data) {
    return this.client.post('/rewards/create', data)
  }

  async updateReward(data) {
    return this.client.post('/rewards/updateById', data)
  }

  async deleteReward(id) {
    return this.client.post(`/rewards/deleteById`, { id })
  }
}

const rewardApi = new Reward()
export default rewardApi
