import React, { useState, useEffect } from 'react'
import { useBoRewardStore } from 'stores/BackOffice/boRewardStore'
import { rowPerPageOptions } from 'constant/boPagination'
import  routePaths  from 'routesPath'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  TextField,
  Switch,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Swal from 'sweetalert2'
import ExportBtn from 'components/ExportBtn'
import rewardApi from 'api/rewardApi'

const initColumns = [
  {
    id: 'no',
    label: 'No.',
    minWidth: 30,
  },
  {
    id: 'image',
    label: 'Image',
    minWidth: 80,
  },
  {
    id: 'name_th',
    label: 'Name (TH)',
    minWidth: 100,
  },
  {
    id: 'name_en',
    label: 'Name (EN)',
    minWidth: 100,
  },
  {
    id: 'points',
    label: 'Points',
    minWidth: 50,
    align: 'center',
  },
  {
    id: 'active',
    label: 'Acive',
    minWidth: 50,
    align: 'center',
  },
  {
    id: 'action',
    label: '',
    minWidth: 80,
  },
]

const initPaging = {
  name: '',
  page: 1,
  pageSize: 5,
}

export default function BORewardPage() {
  const navigate = useNavigate()
  const [rewards, pagination, fetchRewards, deleteReward, updateReward] = useBoRewardStore(
    (state) => [
      state.rewards,
      state.pagination,
      state.fetchRewards,
      state.deleteReward,
      state.updateReward
    ]
  )
  const [paging, setPaging] = useState(initPaging)

  useEffect(() => {
    onFetchRewards(initPaging)
    return () => {}
    // eslint-disable-next-line
  }, [])
  const [exportData, setExportData] = useState([])
  const [nameSearch, setNameSearch] = useState("")

  const onFetchExport = async () => {
    const reqExport = {
      pageSize: 9999
    }
    if(nameSearch && nameSearch !== ""){
      reqExport.name = nameSearch
    }
    const res = await rewardApi.getRewards(reqExport)
    setExportData(res?.data?.data)
  }

  useEffect(() => {
    onFetchExport()
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[nameSearch])

  const onFetchRewards = async (newPaging) => {
    try {
      await fetchRewards(newPaging)
      setPaging(newPaging)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'System error.',
      })
    }
  }

  const onNameChange = (value) => {
    setNameSearch(value)
    setPaging({
      ...paging,
      name: value,
    })
  }

  const handleAddRewardClick = () => {
    navigate(routePaths.BO_REWARD_NEW)
  }

  const handleSearchClick = async () => {
    await onFetchRewards({
      ...paging,
      page: 1,
    })
  }

  const handleClearClick = async () => {
    await onFetchRewards(initPaging)
  }

  const handleEditClick = (id) => {
    navigate(`/bo/reward/${id}/edit`)
  }

  const handleDeleteClick = async (rewardId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteReward(rewardId)
        if (response) {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success').then(
            async (res) => {
              if (res.isConfirmed) {
                await onFetchRewards(initPaging)
              }
            }
          )
        } else {
          Swal.fire({
            icon: 'error',
            title: `Error`,
            text: `System error.`,
          })
        }
      }
    })
  }

  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchRewards({
      ...paging,
      page: newPage + 1,
    })
  }

  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchRewards({
      ...paging,
      page: 1,
      pageSize: +e.target.value,
    })
  }

  const toggleChange = async (e, data) => {
    e.preventDefault()
    data.active = !data.active
    const result = await updateReward(data)
    if(result) {
      await onFetchRewards(initPaging)
    }
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="mb-20"
      >
        <Grid item>
          <h2>Reward List</h2>
        </Grid>
        <Grid item md={2}>
          <Button
            data-testid="rewardlist-btn-add"
            id="rewardlist-btn-add"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleAddRewardClick()}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} md={6}>
          <TextField
            id="rewardlist-search-name"
            label="Name (TH/EN)"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#fff' }}
            value={paging.name || ''}
            onChange={(e) => onNameChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                id="rewardlist-btn-search"
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                onClick={() => handleSearchClick()}
                fullWidth
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                id="rewardlist-btn-clear"
                variant="contained"
                color="error"
                startIcon={<ClearIcon />}
                onClick={() => handleClearClick()}
                fullWidth
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={4}>
              <ExportBtn dataFile={exportData} pageId={'rewardlist'} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {initColumns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {rewards?.length > 0 && (
                <TableBody>
                  {(rewards || []).map((data, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {(pagination.page - 1) * pagination.limit + (i + 1)}
                        </TableCell>
                        <TableCell>
                          <img
                            src={data.image}
                            alt={data.name_en}
                            width="65px"
                          />
                        </TableCell>
                        <TableCell>{data.name_th}</TableCell>
                        <TableCell>{data.name_en}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {data.points}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Switch
                            data-testid={`rewardlist-sw-active-${i}`}
                            id={`rewardlist-sw-active-${i}`}
                            onChange={(e) => toggleChange(e, data)}
                            checked={data.active}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            data-testid={`rewardlist-btn-edit-${i}`}
                            id={`rewardlist-btn-edit-${i}`}
                            color="primary"
                            onClick={() => handleEditClick(data.id)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            data-testid={`rewardlist-btn-delete-${i}`}
                            id={`rewardlist-btn-delete-${i}`}
                            color="error"
                            onClick={() => handleDeleteClick(data.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={pagination.total_count}
            rowsPerPage={paging.pageSize}
            page={pagination.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>
    </>
  )
}
