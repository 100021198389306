import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Text } from 'components/Text';
import { gutter } from 'styles/variables';
import { setStorageLang, getStorageLang } from 'utils/common';

const Wrapper = styled.div`
  width: auto;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: ${gutter.xs};

  div:nth-child(1) {
    text-align: right;
  }
`;

const TextLng = styled(Text)`
  color: ${({ theme }) => theme?.colors?.font?.secondary};

  ${props => props.active && css`
    color: ${({ theme }) => theme?.colors?.font?.default};
    ${props.navbar && css`
      color: ${({ theme }) => theme?.colors?.navbar?.text};
    `}
  `}
`;

function SelectLangComponent(props) {
  const [lang, setLang] = useState();
  const { i18n } = useTranslation()

  function onSelectLang(value) {
    if (value) {
      setLang(value);
      setStorageLang(value);
      i18n.changeLanguage(value);
    }
  }

  useEffect(() => {
    if (i18n) {
      setLang(i18n.language);
    }
  }, [i18n, setLang])

  useEffect(() => {
    const lng = getStorageLang();
    if (lng) {
      setLang(lng);
    }
  }, []);

  return (
    <Wrapper>
      <TextLng
        bold={1}
        cursor={1}
        onClick={() => onSelectLang('th')}
        active={lang === 'th' ? 1 : 0}
        navbar={props && props.navbar ? 1 : 0}
      >TH</TextLng>
      <Text navbar={props && props.navbar ? 1 : 0} bold={1}>|</Text>
      <TextLng
        bold={1}
        cursor={1}
        onClick={() => onSelectLang('en')}
        active={lang === 'en' ? 1 : 0}
        navbar={props && props.navbar ? 1 : 0}
      >ENG</TextLng>
    </Wrapper>
  )
}

export default SelectLangComponent;
