import { Grid } from '@mui/material'

import './what-is-me-to-we.scss'

const WhatIsMeToWePage = () => {
  return (
    <div className="wi-container">
      <Grid container>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={10}>
          <div className="paper">
            <img
              className="banner-img"
              src="/assets/images/banner-why-exist.png"
              alt="Why PTTEP has to exist"
            />
            <p>
              มาเปิดกล่อง me to WE ... กล่องความสุข Toolbox
              ที่จะช่วยให้พวกเราทำงานมีประสิทธิภาพ และประสิทธิผลเพิ่มขึ้น
              แต่เหนื่อยใจน้อยลง และมีความสุขกับการทำงานในทุก ๆ วัน
              <br />
              <br />
              เพราะปัจจุบันโลกยุค Next Normal การดำเนินธุรกิจของ ปตท.สผ.
              ก็ต้องเจอกับความท้าทายอย่างหนักจากหลายด้าน
              และด้วยความทุ่มเทของพนักงานทุก ๆ คน ก็ย่อมเผชิญความกดดัน
              ความเครียดสะสม ที่ส่งผลให้ความสุขในการทำงานลดลงได้
              <br />
              <br />
              องค์กรชั้นนำหลายแห่งก็กำลังประสบปัญหาแบบเดียวกัน
              และได้นำเอาหลักการ <b>“Outward Mindset”</b>
              มาใช้เพื่อเพิ่มความสุขและประสิทธิภาพการทำงาน
              <br />
              <br />
              ปตท.สผ. จึงได้นำเอาหลักการของ Outward Mindset มาผสมผสานกับ Empathy
              และ EP SPIRIT
              ซึ่งเป็นวัฒนธรรมองค์กรเพื่อให้เหมาะสมกับการทำงานของพวกเรามากที่สุด
              โดยเป็นการทำงานที่มุ่งเน้นผลลัพธ์ เข้าใจและให้ความสำคัญต่อตัวเอง
              (Better me) และต่อผู้อื่น (Better WE) ที่เราเกี่ยวข้องด้วย
              เป็นการมองภาพรวม มองการทำงานเป็นทีม (One Team One Goal)
              มากกว่าจะมุ่งเน้นแค่เป้าหมายของตัวเอง
              <br />
              <br />
              สำหรับ ปตท.สผ. การเป็นองค์กรที่เติบโตอย่างยั่งยืนได้นั้น
              อาจเป็นเรื่องสำคัญ แต่เรื่องที่สำคัญยิ่งกว่า นั่นคือ พนักงานทุก ๆ
              คน ... เพราะความสุขของพนักงาน คือพลังงานขององค์กร
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={1} />
      </Grid>
    </div>
  )
}

export default WhatIsMeToWePage
