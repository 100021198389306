import styled, { css  } from 'styled-components';
import { screen, fontSize } from 'styles/variables';

export const Text = styled.div`
  color: ${({ theme }) => theme?.colors?.font?.default};
  font-size: ${fontSize.default};

  ${props => props.secondary && css`
    color: ${({ theme }) => theme?.colors?.font?.secondary};
  `}

  ${props => props.accumulatepoint && css`
    color: ${({ theme }) => theme?.colors?.accumulatePoint?.accumulatePoint};
    @media (max-width: ${screen.sm.max}) {
      font-size: 10px;
    }
  `}

  ${props => props.balancepoint && css`
    color: ${({ theme }) => theme?.colors?.accumulatePoint?.balancePoint};
    @media (max-width: ${screen.sm.max}) {
      font-size: 10px;
    }
  `}

  ${props => props.usedpoint && css`
    @media (max-width: ${screen.sm.max}) {
      font-size: 10px;
    }
  `}

  ${props => props.title && css`
    font-size: ${fontSize.title};
  `}

  ${props => props.subtitle && css`
    font-size: ${fontSize.subTitle};
  `}

  ${props => props.small && css`
    font-size: ${fontSize.small};
  `}

  ${props => props.xs && css`
    font-size: ${fontSize.xs};
  `}

  ${props => props.bold && css`
    font-weight: bold;
  `}

  ${props => props.cursor && css`
    cursor: pointer;
  `}

  ${props => props.navbar && css`
    color: ${({ theme }) => theme?.colors?.navbar?.text};
  `}

  ${props => props.withprofile && css`
    @media (max-width: ${screen.sm.max}) {
      font-size: 14px;
    }
  `}
`;
