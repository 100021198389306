import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthStore } from 'stores/authStore'
import {
  WrapperProfileNav,
  WrapperNav,
  WrapperFullName,
  ImageUser,
  WrapperChangeLang,
  LogoApp
} from 'components/ProfileNav/ProfileNav.style'
import { Text } from 'components/Text'
import SelectLangComponent from 'components/SelectLangComponent'
import StatusDialog from './Status.dialog'
import AccmulatePointComponent from 'components/AccmulatePointComponent'

function ProfileNav() {
  const { t } = useTranslation()
  const [isShowStatus, setIsShowStatus] = useState(false);

  const [user, imgProfile, fetchUserInfo] = useAuthStore((state) => [
    state.user,
    state.imgProfile,
    state.fetchUserInfo,
  ])

  const firstName =
    user?.full_name?.split(' ')?.length > 0
      ? user?.full_name?.split(' ')[0]
      : ''
  const lastName = user?.full_name?.substring(user?.full_name?.indexOf(' ') + 1)

  useEffect(() => {
    fetchUserInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrapperProfileNav>
      { isShowStatus && <StatusDialog open={isShowStatus} onCloseDialog={() => setIsShowStatus(false)} /> }
      <LogoApp src='/assets/images/me-to-we-logo.png' />
      <WrapperNav>
        <ImageUser
          alt='Profile'
          src={ imgProfile ? imgProfile : `/assets/images/default-profile-img.png` }
          circle={imgProfile}
        />
        <WrapperFullName>
          <Text navbar={1} subtitle={1} withprofile={1}>{t('welcome')}!</Text>
          <Text navbar={1} title={1} withprofile={1}>{firstName} {lastName}</Text>
          <WrapperChangeLang>
            {t('changeLanguage')} <SelectLangComponent navbar={1} />
          </WrapperChangeLang>
        </WrapperFullName>

        <AccmulatePointComponent
          accumulatePoints={user.accumulate_points}
          fullPoints={user.ful_points}
          usedPoints={user.used_points}
          balancePoints={user.balance_points}
          withProfile
          navbar
        />
      </WrapperNav>
    </WrapperProfileNav>
  )
}

export default ProfileNav;
