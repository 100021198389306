export const initColumns = [
    {
        id: 'no',
        label: 'No.',
        minWidth: 30,
      },
      {
        id: 'time',
        label: 'Time',
        minWidth: 80,
      },
      {
        id: 'quota',
        label: 'Quota',
        minWidth: 100,
      },
      {
        id: 'action',
        label: '',
        minWidth: 80,
      },
]

export const errorLabel = {
  course_id: 'Please fill in Course',
  image: 'Please fill in Image',
  title_th: 'Please fill in Title TH',
  title_en: 'Please fill in Title EN',
  instructor_th: 'Please fill in Instructor TH',
  instructor_en: 'Please fill in Instructor EN',
  points: 'Please fill in Points',
  event_date: 'Please fill in Event Date',
}
