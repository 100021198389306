import { create } from 'zustand'
import frontEndApi from 'api/communityApi'

export const useCommunityStore = create((set, get) => ({
  allPost: [],
  allPostWithPin: [],
  highlightHashtag: [],
  clearAllPost: () => set({ allPost: [] }),
  getAllPost: async (params) => {
    try {
      const response = await frontEndApi.getAllPost(params)
      if (response.status === 200) {
        if (response.data.data && response.data.data.length) {
          const res = response.data.data
          const allPostData =
            params.page === 1 ? res : get().allPost.concat(res)
          set({
            allPost: allPostData,
          })
          return true
        } else if (!response.data.pagination.total_count) {
          set({
            allPost: [],
          })
          return false
        }
      }
    } catch {}
    return false
  },
  getAllPostWithPin: async (params) => {
    try {
      const response = await frontEndApi.getAllPostWithPin(params)
      if (response.status === 200) {
        if (response.data.data && response.data.data.length) {
          const res = response.data.data
          const allPostData =
            params.page === 1 ? res : get().allPostWithPin.concat(res)
          set({
            allPostWithPin: allPostData,
          })
          return true
        } else if (!response.data.pagination.total_count) {
          set({
            allPostWithPin: [],
          })
          return false
        }
      }
    } catch {}
    return false
  },
  createPost: async (params) => {
    try {
      const response = await frontEndApi.createPost(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  editPost: async (params) => {
    try {
      const response = await frontEndApi.editPost(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  deletePost: async (params) => {
    try {
      const response = await frontEndApi.deletePost(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  likePost: async (params) => {
    try {
      const response = await frontEndApi.likePost(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  unLikePost: async (params) => {
    try {
      const response = await frontEndApi.unLikePost(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  getPostById: async (params) => {
    try {
      const response = await frontEndApi.getPostById(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  createComment: async (params) => {
    try {
      const response = await frontEndApi.createComment(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  editComment: async (params) => {
    try {
      const response = await frontEndApi.editComment(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  deleteComment: async (params) => {
    try {
      const response = await frontEndApi.deleteComment(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  likeComment: async (params) => {
    try {
      const response = await frontEndApi.likeComment(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  unLikeComment: async (params) => {
    try {
      const response = await frontEndApi.unLikeComment(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  pinPost: async (params) => {
    try {
      const response = await frontEndApi.pinPost(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  unPinPost: async (params) => {
    try {
      const response = await frontEndApi.unPinPost(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  getHighlightHashtag: async (params) => {
    try {
      const response = await frontEndApi.getHighlightHashtag(params)
      if (response.status === 200) {
        if (response.data.data && response.data.data.length) {
          const res = response.data.data
          set({
            highlightHashtag: res,
          })
          return true
        } else if (!response.data.pagination.total_count) {
          set({
            highlightHashtag: [],
          })
          return false
        }
      }
    } catch {}
    return false
  },
}))
