import { create } from 'zustand'
import workshopApi from 'api/inspirationWorkshopApi'
import courseApi from 'api/inspirationCourseApi'
import { fISODateToUTC } from 'utils/formatTime'

const initWorkshop = {
  id: null,
  course_id: null,
  created_at: '',
  updated_at: '',
  image: '',
  title_th: '',
  title_en: '',
  description_th: '',
  description_en: '',
  event_date: '',
  points: null,
  sessions: [],
}

export const useBoInspirationWorkshopStore = create((set, get) => ({
  courses: [],
  workshops: [],
  workshop: initWorkshop,
  clearWorkshop: () =>
    set({
      workshop: initWorkshop,
    }),
  fetchWorkshop: async (id) => {
    try {
      const result = await workshopApi.getWorkshopById(id)
      set({
        workshop: {
          ...result.data.data,
          event_date: fISODateToUTC(result.data.data.event_date),
        },
      })
      return true
    } catch {
      return false
    }
  },
  fetchCourses: async () => {
    try {
      const result = await courseApi.getCourses({})
      set({
        courses: result.data.data,
      })
      return true
    } catch {
      return false
    }
  },
  setWorkshop: (key, value) =>
    set({
      workshop: {
        ...get().workshop,
        [key]: value,
      },
    }),
  addSession: (session) => {
    const sessions = get().workshop?.sessions

    if (session?.id > 0) {
      const updateSession = sessions.find((s) => s.id === session.id)
      updateSession.event_time = session.event_time
      updateSession.quota = session.quota
    } else {
      sessions.push(session)
    }

    set({
      workshop: {
        ...get().workshop,
        sessions: sessions,
      },
    })
  },
  deleteSession: (session) => {
    const sessions = get().workshop?.sessions
    const index = sessions.indexOf(session)

    if (index > -1) {
      sessions.splice(index, 1)
      set({
        workshop: {
          ...get().workshop,
          sessions: sessions,
        },
      })
    }
  },
  fetchEmiCourses: async () => {
    try {
      const result = await courseApi.getEmiCourses({})
      set({
        courses: result.data.data,
      })
      return true
    } catch {
      return false
    }
  },
  fetchDynamicCourses: async (req) => {
    try {
      const result = await courseApi.getCourses({group_name: req.group_name})
      set({
        courses: result.data.data,
      })
      return true
    } catch {
      return false
    }
  },
}))
