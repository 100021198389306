import { createHttpClient } from './me2weApi'

class AuthApi {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async login(data) {
    return this.client.post('/auth/login', data)
  }

  async loginWithToken(token) {
    return this.client.post('/auth/token')
  }
}

const authApi = new AuthApi()
export default authApi
