import { create } from 'zustand'
import whitelistApi from '../api/whitelistRequestApi'
import frontEndApi from '../api/frontEndApi'
import { initPagination } from '../constant/boPagination'

export const useUserListStore = create((set, get) => ({
  userList: [],
  clearUserList: () => set({ userList: [] }),
  fetchUserList: async (name) => {
    try {
      const response = await whitelistApi.getUserByGroup({ page: 1, page_size: 10, full_name: name })
      if (response.status === 200) {
        if (response.data.data.length) {
          const listData = response.data.data
          set({
            userList: listData,
          })
          return listData
        }
      }
    } catch {}
    return false
  },
  setUserList: async (data) => {
    try {
      if (data && data.length > 0) {
        set({
          userList: data,
        })
      }
    } catch {}
    return false
  },
  transferToUser: async (userId, point) => {
    try {
      console.log('transferToUser userId: ', userId)
      const response = await frontEndApi.transferPointToUser({ id: userId, givenPoint: point })
      return response.data
    } catch {
      return false
    }
  },
  historyList: [],
  pointHistoryList: async () => {
    try {
      const response = await frontEndApi.pointHistory({})

      if (response.status === 200) {
        if (response.data.data.length) {
          const listData = response.data.data
          set({
            historyList: listData,
          })
          return listData
        }
      }
    } catch (error) {
      return error
    }
    return []
  },
  pointAudiList: [],
  pagination: initPagination,
  fetchPointLogs: async (params) => {
    let pointAudiList = []
    let paginationData = initPagination
    try {
      const response = await whitelistApi.pointAuditLogs(params)
      const { data, pagination } = response.data
      pointAudiList = data
      paginationData = pagination
    } catch (error) {
      throw new Error(error)
    } finally {
      set({
        pointAudiList: pointAudiList,
        pagination: paginationData,
      })
    }
  },
  AddPointToUser: async (email, points) => {
    try {
      const response = await whitelistApi.addPointToUser({ email: email, points: points })
      return response.data
    } catch (err) {
      return err
    }
  },
}))
