import styled, { css } from 'styled-components';
import { screen, gutter } from 'styles/variables';

export const WrapperProfileBox = styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => theme?.colors?.profile?.panel};
  backdrop-filter: blur(2px);
  border-radius: 20px;
  border: solid 2px ${({ theme }) => theme?.colors?.profile?.borderColor};
  padding: ${gutter.default};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${gutter.small};

  display: none;
  @media (max-width: ${screen.sm.max}) {
    grid-gap: ${gutter.xs};
  }

  @media (max-width: ${screen.md.max}) {
    display: grid;
  }
`;

export const WrapperProfileImage = styled.div`
  display: grid;
  align-items: center;
  align-self: center;
  grid-gap: ${gutter.xs};
  grid-row: 1/3;
`;

export const ProfileImage = styled.img`
  width: 77px;
  height: 77px;
  display: block;
  margin: 0 auto;
  ${props => props.circle && css`
    border-radius: 100%;
  `}
`;

export const WrapperFullName = styled.div`
  align-items: center;
  align-self: center;
`;

export const WrapperAccmulatePointComponent = styled.div`
  max-width: 300px;
  grid-column: 2/3;
`;

