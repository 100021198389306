/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react'
import * as DOMPurify from 'dompurify';
import { useParams, useNavigate } from 'react-router-dom'
import { useAuthStore } from 'stores/authStore'
import { useCommunityStore } from 'stores/community'
import { useLoading } from 'components/Loading/context'
import ProfileBox from 'components/ProfileBox'
import ProfileNav from 'components/ProfileNav'
import NotifyDialog from 'components/NotifyDialog'
import {
  WrapperCommunityPage,
  WrapperCommunitySection,
  WrapperUserPost,
  Avatar,
  WrapperButton,
  WrapperAllPost,
  ItemPost,
  WrapperTextPost,
  WrapperOptionComment,
  BtnLike,
  BtnComment,
  WrapperAddImg,
  WrapperImgAddImg,
  InputAddImg,
  AddImg,
  InputComment,
  WrapperSectionComment,
  WrapperImgComment,
  WrapperRemove,
  WrapperHandleActionByUser,
  WrapperEdit,
  WrapperEditAction,
  WrapperImageGrid,
  ImagePost,
  ImageComment,
  WrapperComment,
  WrapperTextLike,
  IconLike,
  WrapperTotalLike,
  WrapperImagePost,
  CommunityLandScapeLogo
} from 'pages/Community/community.style';
import { Text } from 'components/Text'
import moment from 'moment/moment'
import handleBase64img from 'utils/handleBase64img'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { useTranslation } from 'react-i18next'
import { urlify } from 'utils/common'

function CommunityByID() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { t } = useTranslation()
  const { showLoading, hideLoading } = useLoading()

  const [imgProfile] = useAuthStore((state) => [
    state.imgProfile
  ])

  const [
    getPostById,
    createComment,
    deleteComment,
    editComment,
    likePost,
    unLikePost,
    likeComment,
    unLikeComment
  ] = useCommunityStore((state) => [
    state.getPostById,
    state.createComment,
    state.deleteComment,
    state.editComment,
    state.likePost,
    state.unLikePost,
    state.likeComment,
    state.unLikeComment
  ])

  const [postById, setPostById] = useState()
  const [tempBase64, setTempBase64] = useState()
  const [base64img, setBase64img] = useState([])
  const [errImg, setErrImg] = useState()

  const [editTempBase64, setEditTempBase64] = useState()
  const [editBase64img, setEditBase64img] = useState([])
  const [commentList, setCommentList] = useState()
  const [itemEdit, setItemEdit] = useState()
  const [inputEditPost, setInputEditPost] = useState({
    post: ''
  });

  const [showLightBox, setShowLightBox] = useState(false)
  const [dataImageFullScreen, setDataImageFullScreen] = useState()
  const [isShowRemove, setIsShowRemove] = useState()
  const [commentLists, setCommentLists] = useState()
  const textInput = useRef(null)
  const formRef = useRef()


  function onChangeInputPost(e) {
    e.preventDefault()
    const { name, value } = e.target
    setInputEditPost({
      ...inputEditPost,
      [name]: value
    })
  }

  async function handleGetPostById() {
    const data = {
      id: parseInt(id, 10)
    }
    const result = await getPostById(data)
    if (result && result.data && result.data.length > 0) {
      setPostById(result.data[0])
      const { comment } = result.data[0]
      setCommentList(comment.comments)
      hideLoading()
    }
  }

  async function handleComment(e) {
    e.preventDefault()
    showLoading()
    const data = {
      text: textInput && textInput.current && textInput.current.value,
      postId: postById.id,
      level: 1,
      commentId: 0,
      images: base64img
    }
    const result = await createComment(data)
    if (result) {
      formRef.current.reset()
      setBase64img([])
      handleGetPostById()
    }
  }

  async function handleDeleteComment(item) {
    if (item && item.id) {
      const data = {
        id: item.id,
      }
      const result = await deleteComment(data)
      if (result) {
        handleGetPostById()
        setIsShowRemove(false)
      }
    }
  }

  async function handleSubmitEditPost() {
    if (itemEdit && itemEdit.id) {
      const data = {
        id: itemEdit.id,
        text: inputEditPost.post,
        images: editBase64img,
        post_id: postById.id,
      }
      const result = await editComment(data)
      if (result) {
        setEditBase64img(null)
        setItemEdit(null)
        setInputEditPost({
          post: ''
        })
        handleGetPostById()
      }
    }
  }

  function handleEdit(item) {
    setItemEdit(item)
    setInputEditPost({
      post: item.txt
    })

    if (item.images && item.images.length > 0) {
      setEditBase64img(item.images);
    }
  }

  async function handleClickLikePost(item) {
    if (item && item.id) {
      const data = {
        id: item.id
      }
      let result
      if (item.permission.like) {
        result = await likePost(data)
      } else {
        result = await unLikePost(data)
      }
      if (result) {
        handleGetPostById()
      }
    }
  }

  async function handleClickLikeComment(item, arr) {
    if (item && item.id) {
      const tempArr = arr
      const findItem = tempArr && tempArr.length > 0 && tempArr.find(el => el.id === item.id)
      findItem.permission.unlike = !findItem.permission.unlike;
      console.log('findItem : ', findItem);

      setCommentLists(tempArr)

      const data = {
        id: item.id,
        post_id: item.post_id
      }

      if (findItem.permission.unlike) {
        await likeComment(data)
      } else {
        await unLikeComment(data)
      }
    }
  }

  function renderActionButtonWithPermission(item) {
    return (
      <WrapperHandleActionByUser>
        { item.permission && item.permission.edit && <WrapperEdit onClick={() => handleEdit(item)} /> }
        { item.permission && item.permission.delete && <WrapperRemove onClick={() => setIsShowRemove(item)}/> }
      </WrapperHandleActionByUser>
    )
  }

  function renderOptionComment(item, arr) {
    return (
      <WrapperOptionComment withcomment={1}>
        <BtnLike
          onClick={() => handleClickLikeComment(item, arr)}
          >
            { item.permission && item.permission.unlike
              ? <><IconLike src='/assets/images/like-community-fill.svg' alt='Like'/>
                  <WrapperTextLike active={item.permission && item.permission.unlike}>{t('like')}</WrapperTextLike>
                </>
              : <><IconLike src='/assets/images/like-community.svg' alt='Like'/>
                  <WrapperTextLike>
                    {t('like')}
                  </WrapperTextLike>
                </>
            }
        </BtnLike>
      </WrapperOptionComment>
    )
  }

  function renderCommentList() {
    return (
      <>
      { commentLists && commentLists.map((item, index) => (
        <ItemPost key={index} comment={1}>
          <WrapperUserPost>
            <Avatar src={item.user.avatar} alt='Avatar' />
            <div>
              <Text>{item.user.name}</Text>
              <Text secondary={1} small={1}>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</Text>
            </div>
            { renderActionButtonWithPermission(item) }
          </WrapperUserPost>
          { itemEdit && itemEdit.id === item.id
            ? <>
                <WrapperSectionComment>
                  <Avatar src={imgProfile} alt='Avatar' />
                  <InputComment
                    placeholder={t('comment')}
                    ref={textInput}
                    autoComplete='off'
                    required
                    name='post'
                    value={inputEditPost && inputEditPost.post}
                    onChange={(e) => onChangeInputPost(e)}
                  />
                  { !editBase64img[0]
                    ? <WrapperAddImg>
                        <WrapperImgAddImg htmlFor='fileUpload'><AddImg src="/assets/images/add-img-community.svg" alt='Add Img' /></WrapperImgAddImg>
                        <InputAddImg
                          type='file'
                          id='fileUpload'
                          accept='.png, .jpg, .heic, .heif'
                          onChange={(e) => handleBase64img(e, setEditTempBase64, setErrImg, showLoading, hideLoading)}
                        />
                      </WrapperAddImg>
                    : <WrapperRemove fontSize="small" onClick={() => setEditBase64img([])}/>
                  }
                </WrapperSectionComment>
                { editBase64img && editBase64img[0] &&
                  <WrapperImgComment src={editBase64img[0]} />
                }

                <WrapperEditAction>
                  <WrapperButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmitEditPost()}
                  >
                    {t('edit')}
                  </WrapperButton>
                  <WrapperButton
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => setItemEdit(null)}
                  >
                    {t('cancel')}
                  </WrapperButton>
                </WrapperEditAction>
              </>
            : <>
                <WrapperTextPost>
                  { handleTextPost(item.txt) }
                  { item.images && item.images.length > 0 && item.images[0] &&
                    <ImageComment
                      src={item.images[0]}
                      onClick={() => handleImageFullScreen(item.images[0])}
                    />
                  }
                </WrapperTextPost>
                { item.total_like > 0 &&
                  <WrapperTotalLike>
                    <IconLike src='/assets/images/like-community-fill.svg' alt='Like'/> { item.total_like }
                  </WrapperTotalLike>
                }
                {renderOptionComment(item, commentList)}
              </>
          }
        </ItemPost>
      ))}
      </>
    )
  }

  function handleImageFullScreen(img) {
    if (img) {
      setDataImageFullScreen(img)
      setShowLightBox(true)
    }
  }

  function handleTextPost(text) {
    if (text) {
      return (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(
          urlify(text).replace(/#(\S*)/g, '<a class="hashtag" href="#$1" >#$1</a>'), {ALLOWED_TAGS: ['a'], ADD_ATTR: ['target']}
        ) }} />
      )
    }

    return null
  }

  useEffect(() => {
    setCommentLists(commentList)
  }, [commentList])

  useEffect(() => {
    if (id) {
      handleGetPostById()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (tempBase64) {
      setBase64img(current => [...current, tempBase64])
    }
  }, [tempBase64])


  useEffect(() => {
    if (editTempBase64) {
      setEditBase64img(current => [...current, editTempBase64])
    }
  }, [editTempBase64])

  useEffect(() => {
    if (window.location.hash) {
      navigate(`/community${window.location.hash}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash])

  return (
    <WrapperCommunityPage>
      <ProfileBox />
      <ProfileNav />
      { postById &&
        <form onSubmit={(e) => handleComment(e)} ref={formRef}>
          <WrapperCommunitySection>
            <CommunityLandScapeLogo src="/assets/images/community-landScape-logo.svg" alt="community" />
            <WrapperAllPost>
              <ItemPost>
                <WrapperUserPost>
                  <Avatar src={postById.user.avatar} alt='Avatar' />
                  <div>
                    <Text small={1}>{postById.user.name}</Text>
                    <Text secondary={1} xs={1}>{moment(postById.created_at).format('DD/MM/YYYY HH:mm')}</Text>
                  </div>
                </WrapperUserPost>
                <WrapperTextPost>
                  { handleTextPost(postById.txt) }
                  <WrapperImageGrid>
                    { postById.images && postById.images.length > 0 && postById.images.map((item, index) => (
                      <WrapperImagePost key={index} item={postById?.images?.length}>
                        <ImagePost
                          data-testid='comment-input'
                          src={item}
                          onClick={() => handleImageFullScreen(item)}
                        />
                      </WrapperImagePost>
                    ))}
                  </WrapperImageGrid>
                </WrapperTextPost>
                { postById.total_like > 0 &&
                  <WrapperTotalLike>
                    <IconLike src='/assets/images/like-community-fill.svg' alt='Like'/> { postById.total_like }
                  </WrapperTotalLike>
                }

                <WrapperOptionComment>
                  <BtnLike
                    data-testid='handle-like-post'
                    onClick={() => handleClickLikePost(postById)}
                    >
                      { postById.permission && postById.permission.unlike
                        ? <><IconLike src='/assets/images/like-community-fill.svg' alt='Like'/>
                            <WrapperTextLike active={postById.permission && postById.permission.unlike}>{t('like')}</WrapperTextLike>
                          </>
                        : <><IconLike src='/assets/images/like-community.svg' alt='Like'/>
                            <WrapperTextLike>
                              {t('like')}
                            </WrapperTextLike>
                          </>
                      }
                  </BtnLike>
                  <BtnComment onClick={() => { textInput.current.focus() }}><WrapperComment /> {t('comment')}</BtnComment>
                </WrapperOptionComment>

                { renderCommentList() }

                <WrapperSectionComment>
                  <Avatar src={imgProfile} alt='Avatar' />
                  <InputComment
                    placeholder={t('comment')}
                    ref={textInput}
                    name='text'
                    autoComplete='off'
                  />
                  { !base64img[0]
                    ? <WrapperAddImg>
                        <WrapperImgAddImg htmlFor='fileUpload'><AddImg src="/assets/images/add-img-community.svg" alt='Add Img' /></WrapperImgAddImg>
                        <InputAddImg
                          type='file'
                          id='fileUpload'
                          accept='.png, .jpg, .heic, .heif'
                          onChange={(e) => handleBase64img(e, setTempBase64, setErrImg, showLoading, hideLoading)}
                        />
                      </WrapperAddImg>
                    : <WrapperRemove fontSize="small" onClick={() => setBase64img([])}/>
                  }
                </WrapperSectionComment>
                { base64img && base64img[0] &&
                  <WrapperImgComment src={base64img && base64img[0]} />
                }
                <WrapperButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  {t('comment')}
                </WrapperButton>
              </ItemPost>
            </WrapperAllPost>
          </WrapperCommunitySection>
          { showLightBox &&
            <Lightbox
              mainSrc={dataImageFullScreen}
              onCloseRequest={() => {
                setShowLightBox(false)
                setDataImageFullScreen(null)
              }}
              reactModalProps={{
                overlay: {
                  zIndex: 100
                }
              }}
            />
          }
        </form>
      }

      <NotifyDialog
        type="error"
        bodyText={t('err_img_community')}
        okText={t('ok_text')}
        open={errImg}
        onCloseDialog={() => setErrImg(false)}
        onOkDialogClick={() => setErrImg(false)}
      />

      <NotifyDialog
        id="idRemove"
        type="confirmation"
        bodyText={t('do_you_want_remove')}
        okText={t('confirm')}
        cancelText={t('cancel')}
        open={isShowRemove}
        onCloseDialog={() => setIsShowRemove(null)}
        onCancelDialogClick={() => setIsShowRemove(null)}
        onOkDialogClick={() => handleDeleteComment(isShowRemove)}
      />
    </WrapperCommunityPage>
  )
}

export default CommunityByID;
