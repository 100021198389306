import { styled } from 'styled-components';
import { gutter } from 'styles/variables';

export const WrapperCheckInPage = styled.div`
  padding: ${gutter.large};

  #reader {
    position: fixed !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #000000c7;
    border: none !important;
    overflow: hidden;
    z-index: 1000;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important; 

    #html5-qrcode-button-camera-stop {
      display: none !important;
    }

    img {
      display: none;
    }

    @media (min-width: 992px) {
      video {
        width: 800px;
        height: 800px;
      }
    }
  }

  #btnDisableCamera {
    position: fixed !important;
    bottom: 10%;
    z-index: 1001;
  }
`;

export const HaderPage = styled.div`
  margin-top: 20px;
  font-size: 25px;
  font-weight: 600;
`;

export const WrapperSpecialPoint = styled.div`
  display: block;
  text-align: center;
  padding: 20px 0;
  border-bottom: solid 1px #9db2ce;
`;


export const WrapperButtonSpecialPoint = styled.div`
  margin-top: ${gutter.default};
`;

export const InputSpecialPoint = styled.input`
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 10px;
  border: 2px solid #efefef;
  height: 28px;
`;
