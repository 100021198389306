import { create } from 'zustand'
import userRedemptionApi from 'api/userRedemptionApi'
import { initPagination } from 'constant/boPagination'
import { fUTCDateToIsoStart, fUTCDateToIsoEnd } from 'utils/formatTime'

export const useBoUserRedemptionStore = create((set, get) => ({
  userRedemptions: [],
  pagination: initPagination,
  fetchUserRedemptions: async (params) => {
    let userRedemptionDatas = []
    let paginationData = initPagination
    try {
      const response = await userRedemptionApi.getUserRedemptions({
        ...params,
        start: fUTCDateToIsoStart(params.start),
        end: fUTCDateToIsoEnd(params.end),
      })
      const { data, pagination } = response.data
      userRedemptionDatas = data
      paginationData = pagination
    } catch {
      return false
    } finally {
      set({
        userRedemptions: userRedemptionDatas,
        pagination: paginationData,
      })
    }
    return true
  },
  batchUpdateSent: async (data) => {
    const response = await userRedemptionApi.batchUpdateSent(data)
    return response.status === 200
  },
}))
