import React, { useState, useEffect } from 'react'
import { rowPerPageOptions } from 'constant/boPagination'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import Swal from 'sweetalert2'
import { useUserListStore } from '../../../stores/userListStore'
import moment from 'moment/moment'
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography
} from '@mui/material'
const initColumns = [
  { id: 'no', label: 'No.', minWidth: 30, }, { id: 'name', label: 'Name', minWidth: 80, },
  { id: 'remark', label: 'Remark', minWidth: 80, }, { id: 'point', label: 'Point', minWidth: 100, },
  { id: 'receiver_name', label: 'Receiver Name', minWidth: 100, }, { id: 'created_at', label: 'Date', minWidth: 150, },
]
const initPaging = { full_name: '', page: 1, pageSize: 5, }
export default function BOPointTransferPage() {
  const [pointAudiList, pagination, fetchPointLogs, AddPointToUser] = useUserListStore(
    (state) => [ state.pointAudiList, state.pagination, state.fetchPointLogs, state.AddPointToUser ]
  )
  const [paging, setPaging] = useState(initPaging)
  const [isAddDialog, setIsAddDialog] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [inputData, setInputData] = useState({ email: '@pttep.com', point: 0 })
  const [error, setError] = useState({})
  const PrimaryIcon = ({ icon: IconComponent }) => <IconComponent/>
  const validateInput = async () => {
    let isError = false
    let errorObj = {}
    if (!inputData.email) {
      isError = true
      errorObj = { ...errorObj, email: 'Please fill in Email', }
    }
    if (inputData.point <= 0) {
      isError = true
      errorObj = { ...errorObj, point: 'Points must greater than 0', }
    }
    setError(errorObj)
    return isError
  }
  useEffect(() => {
    onFetchList(initPaging)
    return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onFetchList = async (newPaging) => {
    try {
      await fetchPointLogs({ ...newPaging, page_size: newPaging.pageSize })
      setPaging(newPaging)
    } catch {
      await Swal.fire({ icon: 'error', title: 'Error', text: 'System error.' })
    }
  }
  const handleCriteriaChange = (value) => {
    setPaging({ ...paging, full_name: value ? value : '' })
  }
  const handleInputChange = (name, value) => {
    setInputData({ ...inputData, [name]: value })
  }
  const actionAddDialog = () => {
    setIsAddDialog(isAddDialog === false)
    setInputData({ email: '@pttep.com', point: 0 })
  }
  const handleAddSubmit = async () => {
    setIsSubmit(true)
    try {
      const validate = await validateInput();
      if (!validate) {
        const response = await AddPointToUser(inputData?.email, inputData?.point)
        setIsAddDialog(false)
        if (response && response.data === "Success") {
          Swal.fire({ icon: 'success', title: 'Success' }).then(async (result) => {
            if (result.isConfirmed) {
              await onFetchList(paging)
            }
            setIsSubmit(false)
          })
        } else {
          await Swal.fire({ icon: 'error', title: `Error`, text: response?.message })
          setIsSubmit(false)
        }
      }
    } catch (err) {
      await Swal.fire({ icon: 'error',title: `Error`,text: `System error.` })
      setIsSubmit(false)
    }
  }
  const handleSearchClick = async () => {
    await onFetchList({...paging, page: 1})
  }
  const handleClearClick = async () => {
    await onFetchList(initPaging)
  }
  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchList({...paging, page: newPage + 1})
  }
  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchList({...paging, page: 1, pageSize: e.target.value})
  }
  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center" className="mb-20">
        <Grid item> <h2>Point Transfer Logs</h2> </Grid>
        <Grid item md={2}>
          <Button id="elearning-btn-import" fullWidth variant="contained" startIcon={<PrimaryIcon icon={AddIcon}/>} onClick={() => actionAddDialog()}> Add Point </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} md={6}>
          <TextField id="booklist-search-name" label="Full Name" size="small"
            fullWidth sx={{ backgroundColor: '#fff' }} value={paging.full_name || ''} onChange={(e) => handleCriteriaChange(e.target.value)} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button data-testid="booklist-btn-search" id="booklist-btn-search" variant="contained" color="primary"
                startIcon={<PrimaryIcon icon={SearchIcon}/>} fullWidth onClick={() => handleSearchClick()}> Search </Button>
            </Grid>
            <Grid item xs={4}>
              <Button data-testid="booklist-btn-clear" id="booklist-btn-clear" variant="contained" color="error"
                startIcon={<PrimaryIcon icon={ClearIcon}/>} fullWidth onClick={() => handleClearClick()} > Clear </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {initColumns.map((column, i) => (
                    <TableCell key={i} align={column.align} style={{ minWidth: column.minWidth }}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {pointAudiList?.length > 0 && (
                <TableBody>
                  {(pointAudiList || []).map((item, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>
                          {(pagination.page - 1) * pagination.limit + (i + 1)}
                        </TableCell>
                        <TableCell>{item.giver_name}</TableCell>
                        <TableCell>{item.remark}</TableCell>
                        <TableCell>{item.point_transfer}</TableCell>
                        <TableCell>{item.receiver_name}</TableCell>
                        <TableCell>{moment(item?.created_at).utc().format('DD/MM/YYYY HH:mm')}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination showFirstButton showLastButton rowsPerPageOptions={rowPerPageOptions} component="div" count={pagination?.total_count} rowsPerPage={paging?.pageSize}
                           page={pagination?.page - 1} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} />
        </Paper>
        <Dialog open={isAddDialog} onClose={actionAddDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth>
        <DialogTitle id="alert-dialog-title">Add point to user</DialogTitle>
        <DialogContent>
          <Paper elevation={3} sx={{ mt: 3, p: 4 }}>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} md={6}>
                <TextField id="pointform-input-email" label="Email *" size="small" fullWidth value={inputData?.email}
                           onChange={(e) => handleInputChange('email', e.target.value)}
                           error={!!error.email} helperText={error.email ? error.email : ''} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField data-testid="pointform-input-point" id="pointform-input-point" label="Point *" size="small" fullWidth
                           InputProps={{ inputMode: 'numeric', pattern: '[0-9]*', inputProps: { min: 0, max: 999 }, }}
                           value={inputData?.point || 0} onChange={(e) => handleInputChange('point', +e.target.value || 0)}
                           error={!!error.point} helperText={error.point ? error.point : ''} />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions sx={{ py: 3, justifyContent: 'center' }}>
          <Button
            id="elearning-btn-import-ok" variant="contained" sx={{ minWidth: '125px', }}
            onClick={() => handleAddSubmit()} disabled={isSubmit}> Submit </Button>
          <Button
            id="elearning-btn-import-cancel" variant="outlined" sx={{ minWidth: '125px', }}
            onClick={() => {setIsAddDialog(false)}}> Cancel </Button>
        </DialogActions>
      </Dialog>
      </Grid>
    </>
  )
}
