export const initColumns = [
    {
    id: 'no',
    label: 'No.',
    minWidth: 30,
    },
    {
    id: 'image',
    label: 'Image',
    minWidth: 80,
    },
    {
    id: 'title_th',
    label: 'Name (TH)',
    minWidth: 100,
    },
    {
    id: 'title_en',
    label: 'Name (EN)',
    minWidth: 100,
    },
    {
    id: 'event_date',
    label: 'Event Date',
    minWidth: 120,
    },
    {
    id: 'session',
    label: 'Session',
    minWidth: 150,
    },
    {
    id: 'action',
    label: '',
    minWidth: 80,
    },
]

export const initPaging = {
    title: '',
    instructor: '',
    page: 1,
    pageSize: 5,
}

export const txtProps ={
    size: 'small',
    fullWidth: true,
    sx: { backgroundColor: '#fff' },
}
