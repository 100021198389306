import React, { useEffect, useState } from 'react'
import { useBoCommunityStore } from 'stores/BackOffice/boCommunityStore'
import { rowPerPageOptions } from 'constant/boPagination'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import Swal from 'sweetalert2'

const initColumns = [
  {
    id: 'no',
    label: 'No.',
  },
  {
    id: 'txt',
    label: 'Text',
    minWidth: 250
  },
  {
    id: 'action',
    label: ''
  }
]

const initPaging = { title: '', page: 1, pageSize: 10 }

function ForbiddenWordPage() {
  const [paging, setPaging] = useState(initPaging)
  const [actionDialog, setActionDialog] = useState(false)
  const [error, setError] = useState({})
  const [inputAction, setInputAction] = useState({ word: '' })
  const [isSubmit, setIsSubmit] = useState(false)
  const [wordList, pagination, fetchWords, createWord, deleteWord] = useBoCommunityStore(
    (state) => [state.wordList, state.pagination, state.fetchWords, state.createWord, state.deleteWord]
  )
  async function validateInput() {
    let isError = false
    let errorObj = {}
    if (!inputAction.word) {
      isError = true
      errorObj = { ...errorObj, word: 'Please fill in Word', }
    }
    setError(errorObj)
    return isError
  }
  async function handleFetchSearch(newPaging) {
    try {
      await fetchWords(newPaging)
      setPaging(newPaging)
    } catch (er) { Swal.fire({ icon: 'error', title: 'Error', text: 'System error.' }) }
  }
  async function handleSearchClick() {
    await fetchWords({ ...paging, page: 1, })
  }
  async function handleClearClick() {
    setPaging({ ...paging, text: '' })
  }
  function handleOnChangeInput(value) {
    setPaging({ ...paging, text: value })
  }
  async function onPageChange(e, newPage) {
    e.preventDefault()
    await fetchWords({ ...paging, page: newPage + 1 })
  }
  async function onRowsPerPageChange(e) {
    e.preventDefault()
    await fetchWords({ ...paging, page: 1, pageSize: +e.target.value })
  }
  async function handleActionSubmit() {
    setIsSubmit(true)
    try {
      const validate = await validateInput()
      if (!validate) {
        const response = await createWord(inputAction)
        setActionDialog(false)
        if (response && response.data === "Success") {
          Swal.fire({ icon: 'success', title: 'Success' }).then(async (result) => {
            if (result.isConfirmed) {
              await handleFetchSearch(initPaging)
            }
            setIsSubmit(false)
          })
        } else {
          await Swal.fire({ icon: 'error', title: `Error`, text: response?.message })
          setIsSubmit(false)
          await handleFetchSearch(initPaging)
        }
      }
    } catch (err) {
      await Swal.fire({ icon: 'error', title: `Error`, text: `System error.` })
      setIsSubmit(false)
    }
  }
  function handleDeleteClick(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteWord({ id })
        if (response) {
          Swal.fire('Deleted!', 'Your message has been deleted.', 'success').then(
            async (res) => {
              if (res.isConfirmed) {
                await handleFetchSearch(initPaging)
              }
            }
          )
        } else {
          Swal.fire({ icon: 'error', title: `Error`, text: `System error.` })
        }
      }
    })
  }
  useEffect(() => {
    handleFetchSearch(initPaging)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
       <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center" className="mb-20">
        <Grid item><h2>Forbidden Words</h2></Grid>
        <Grid item md={2}>
          <Button
            data-testid="word-btn-add"
            id="word-btn-add"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setActionDialog(true)}
          > Add </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} md={8}>
          <TextField
            id="word-search-word"
            label="Word"
            size="small"
            fullWidth
            sx={{ backgroundColor: '#fff' }}
            value={paging.text || ''}
            onChange={(e) => handleOnChangeInput(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                data-testid="word-btn-search"
                id="word-btn-search"
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                fullWidth
                onClick={() => handleSearchClick()}
              > Search </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                data-testid="word-btn-clear"
                id="word-btn-clear"
                variant="contained"
                color="error"
                startIcon={<ClearIcon />}
                fullWidth
                onClick={() => handleClearClick()}
              > Clear </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {initColumns.map((column, i) => (
                    <TableCell key={i} align={column.align} style={{ minWidth: column.minWidth }}>
                      <Typography style={{ fontWeight: 'bold' }}>{column.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {wordList?.length > 0 && (
                <TableBody>
                  {(wordList || []).map((item, i) => {
                    return (
                      <TableRow hover key={i}>
                        <TableCell>{(pagination.page - 1) * pagination.limit + (i + 1)}</TableCell>
                        <TableCell>{item.txt}</TableCell>
                        <TableCell>
                          <IconButton
                            id={`community-forbidden-word-btn-delete-${i}`}
                            color="error"
                            onClick={() => handleDeleteClick(item.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={pagination ? pagination.total_count : 0}
            rowsPerPage={paging.pageSize}
            page={pagination ? pagination.page - 1 : 0}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>
      { actionDialog &&
        <Dialog open={actionDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth>
          <DialogTitle id="alert-dialog-title">Add Word</DialogTitle>
          <DialogContent>
            <Grid item xs={12} md={12} style={{ padding: '20px 10px' }}>
              <TextField
                data-testid="community-input-action-word"
                id="community-input-action-word"
                label="Word *"
                placeholder='Word'
                size="small"
                name="word"
                fullWidth
                onChange={(e) => {
                  setIsSubmit(false)
                  setInputAction({ ...inputAction, word: e.target.value })
                }}
                error={!!error.word}
                helperText={error.word ? error.word : ''}
              />
            </Grid>
          </DialogContent>
          <DialogActions sx={{ py: 3, justifyContent: 'center' }}>
            <Button
              id="btn-action-ok"
              variant="contained"
              sx={{ minWidth: '125px' }}
              onClick={() => handleActionSubmit()}
              disabled={isSubmit}
            > Submit </Button>
            <Button
              id="btn-action-cancel"
              variant="outlined"
              sx={{ minWidth: '125px' }}
              onClick={() => setActionDialog(false)}
            > Cancel </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

export default ForbiddenWordPage;
