import './brand-logo.scss'

const BrandLogo = (props) => {
  const position = props?.position?.toLowerCase()

  const style = {
    marginLeft: position === 'center' ? '0px' : '56px',
    justifyContent: position === 'center' ? 'center' : 'flex-start',
  }

  return (
    <div {...props} className="bl-container" style={style}>
      <div className="logo-container">
        <div className="logo-1">
          <img src="/logo.png" alt="PTTEP logo" width={50} />
        </div>
        <div className="logo-2">
          <img
            src="/assets/images/ep-enterprise-logo.png"
            alt="EP Enterprise logo"
            height={23}
            width={66}
          />
        </div>
        <div className="logo-3">SHC</div>
      </div>
    </div>
  )
}

export default BrandLogo
