import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { gutter, borderRadius, boxShadow } from 'styles/variables';
import { Text } from 'components/Text';

const Wrapper = styled.div`
  padding: ${gutter.small};
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  align-self: center;
  background: ${({ theme }) => theme?.colors?.accumulatePoint?.background};
  border: ${({ theme }) => theme?.colors?.accumulatePoint?.border};
  border-radius: ${borderRadius.large};

  ${props => props.withprofile && css`
    padding: 5px 10px;
  `}

  ${props => props.navbar && css`
    background: ${({ theme }) => theme?.colors?.navbar?.text};
    height: 100%;
    border-radius: ${borderRadius.default};
  `}
`;

const WrapperPointBar = styled.div``;

const WrapperUsedPoint = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const WrapperHeart = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  align-self: center;
  margin: 5 0;
  div:nth-child(1) {
    text-align: right;
  }
  div:nth-child(2) {
    margin: 0 2px;
  }
  div:nth-child(3) {
    text-align: left;
  }
`;

const WrapperProgressBar = styled.div`
  position: relative;
`;
const ProgressBar = styled.div`
  top: 0;
  width: 100%;
  height: 16px;
  border-radius: 16px;
  background: transparent;
  border: ${({ theme }) => theme?.colors?.accumulatePoint?.border};

  &::before {
    content: '';
    display: block;
    height: 16px;
    border-radius: 16px;
    background: ${({ theme }) => theme?.colors?.accumulatePoint?.balancePoint};
    box-shadow: ${boxShadow};

    ${props => props.percent > 0 && css`
      width: ${props.percent}%;
      max-width: 100%;
    `}

    ${porps => porps.percent < 1 && css`
      width: 0;
    `}
  }
`;

function AccmulatePointComponent(props) {
  const { t } = useTranslation();
  const {
    accumulatePoints,
    usedPoints,
    balancePoints,
    withProfile,
    navbar
  } = props;

  return (
    <Wrapper withprofile={withProfile ? 1 : 0} navbar={navbar ? 1 : 0}>
      <WrapperHeart>
        <Text accumulatepoint={1} small={1} bold={1}>{t('totalPower')}</Text>
        <div>
          <img
            src="/assets/images/heart-green.svg"
            width="16"
            alt="Heart green"
          />
        </div>
        <Text accumulatepoint={1} small={1} bold={1}>{ accumulatePoints || 0}</Text>
      </WrapperHeart>

      <WrapperPointBar>
        <WrapperProgressBar>
          <ProgressBar
            percent={100 - ((usedPoints || 1) * 100 ) / (accumulatePoints || 1)}
          />
        </WrapperProgressBar>
      </WrapperPointBar>
      <WrapperUsedPoint>
        <WrapperHeart>
          <Text small={1} balancepoint={1}>{t('balancePoint')}</Text>
          <div>
            <img
              src="/assets/images/heart-pink.svg"
              width="16"
              className="ml-2"
              alt="Heart pink"
            />
          </div>
          <Text small={1} balancepoint={1}>{balancePoints || 0}</Text>
        </WrapperHeart>
        <WrapperHeart>
          <Text small={1} usedpoint={1}>{t('usedPoint')}</Text>
          <div>
            <img
              src="/assets/images/heart-grey.svg"
              width="16"
              className="ml-2"
              alt="Heart pink"
            />
          </div>
          <Text small={1} usedpoint={1}>{usedPoints || 0}</Text>
        </WrapperHeart>
      </WrapperUsedPoint>
    </Wrapper>
  )
}

export default AccmulatePointComponent;
