import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Modal, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import whitelistRequestApi from 'api/whitelistRequestApi'
import Swal from 'sweetalert2'
import { useBoMenuStore } from 'stores/BackOffice/boMenuStore'
import Loading from 'components/Loading'
import routePaths from 'routesPath'
import dynamicsMenuApi from 'api/dynamicsMenuApi'

function ModalAddGroup(props){
    const { open, handleClose, mode, dataEdit} = props
    const [groupName, setGroupName] = useState("")
    const [linkImage, setLinkImage] = useState("")
    const [ addNewMenu] = useBoMenuStore((state) => [state.addNewMenu])
    const [waiting, setWaiting]= useState(false)
    const saveNewGroup = async () => {
        setWaiting(true)
        switch (mode){
        case "ADD": {
                const req = {
                    group_name:  groupName
                }
                const res = await whitelistRequestApi.createUserGroup(req)
                const resAdd =  await addNewMenu({groupName: groupName, icon: linkImage})
                handleClose()
                setWaiting(false)
                if(res.status === 200 && resAdd === "Success") {
                    Swal.fire({
                        icon: 'success',
                        text: 'Add new group success.'
                    }).then(() => {
                        window.location.href = routePaths.BO_WHITELIST_ADD
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: 'Can not add new group'
                    })
                }
            }
            break
        case "EDIT": {
                const req = {
                    id: dataEdit?.id,
                    title:  groupName
                }
                const res = await dynamicsMenuApi.updateMainMenu(req)
                handleClose()
                setWaiting(false)
                if(res.status === 200 ) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Update success.'
                    }).then((re) => {
                        window.location.href = routePaths.BO_WHITELIST_ADD
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: 'Can not update'
                    })
                }
            }
            break
        default: break
        }
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRaduis: '10px',
        width: 400,
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        alignText: 'center'
      };
    const BtnCloseStyle = {
        position: 'absolute',
        top: '0',
        right: '0',
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return (<>
        <Loading open={waiting} />
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <IconButton style={BtnCloseStyle} onClick={handleClose} color="primary" data-testid='close-btn'>
                    <CloseIcon />
                </IconButton>
                <h3>{(mode === "ADD" ? "Add new": "Edit") + " group menu"}</h3>
                <Grid container spacing={2} direction="row" style={{ marginBottom: "5px" }}>
                    <Grid item xs={6}>
                        <TextField
                            id="modal-input-groupname"
                            fullWidth
                            size="small"
                            label="Group name"
                            defaultValue={mode === "EDIT" && dataEdit?.name}
                            onChange={(e)=> setGroupName(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={6}>
                        <TextField
                            id="modal-input-linkimg"
                            fullWidth
                            size="small"
                            label="link icon image"
                            defaultValue={mode === "EDIT" && dataEdit?.name}
                            disabled={mode === "EDIT"}
                            onChange={(e)=> setLinkImage(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            id="modal-button-save"
                            variant="contained"
                            color='success'
                            onClick={() => saveNewGroup()}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
        </>
     )
}

export default ModalAddGroup
