import { Navigate, useParams } from 'react-router-dom'
import VersionPage from 'pages/Version'
// Web
import LandingPage from 'pages/Landing'
import HomePage from 'pages/Home'
import LoginPage from 'pages/Login'
import BookPage from 'pages/Book'
import ProfilePage from 'pages/Profile'
import AddFuelPage from 'pages/AddFuel'
import WhatIsMeToWePage from 'pages/WhatIsMeToWe'
import ELearningPage from 'pages/ELearning'
import InspirationPage from 'pages/Inspiration'
import WorkshopPage from 'pages/Inspiration/WorkshopPage'
import ContactPage from 'pages/Contact'
import PrizeCollectionPage from 'pages/PrizeCollection'
import WeTogetherPage from 'pages/WeTogether'
import EmiPage from 'pages/WeTogether/EMI'
import EmiWorkShopPage from 'pages/WeTogether/EMI/EmiWorkshopPage'
import EPSpiritPage from 'pages/WeTogether/EPSpirit'
import SDPage from 'pages/WeTogether/SD'
import CheckInPage from 'pages/Check-in'
import CommunityPage from 'pages/Community'
import CommunityByIdPage from 'pages/Community/CommunityById'
import DynamicsMenuByCourse from 'pages/DynamicsMenu/DynamicsMenuById'
import DynamicsMenuByWorkshop from 'pages/DynamicsMenu/WorkShopPage'
// Back Office
import BOBookPage from 'pages/BackOffice/Book'
import BOBookRequestPage from 'pages/BackOffice/BookRequest'
import BOELearningPage from 'pages/BackOffice/ELearning'
import BOBookFormPage from 'pages/BackOffice/Book/Form'
import BOInspirationCoursePage from 'pages/BackOffice/Inspiration/Course'
import BOInspirationAddCoursePage from 'pages/BackOffice/Inspiration/Course/AddCoursePage'
import BOInspirationEditCoursePage from 'pages/BackOffice/Inspiration/Course/EditCoursePage'
import BOInspirationWorkshopPage from 'pages/BackOffice/Inspiration/Workshop'
import BOInspirationAddWorkshopPage from 'pages/BackOffice/Inspiration/Workshop/AddWorkshopPage'
import BOInspirationEditWorkshopPage from 'pages/BackOffice/Inspiration/Workshop/EditWorkshopPage'
import BOInspirationRequestPage from 'pages/BackOffice/Inspiration/Request'
import BORewardPage from 'pages/BackOffice/Reward'
import BORewardFormPage from 'pages/BackOffice/Reward/Form'
import BORewardRedemptionPage from 'pages/BackOffice/RewardRedemption'
import BOEmiCoursePage from 'pages/BackOffice/WeTogether/EMI'
import BOEmiRequestPage from 'pages/BackOffice/WeTogether/EMI/Request'
import BOEmiWorkshopPage from 'pages/BackOffice/WeTogether/EMI/Workshop'
import BOEmiAddWorkshopPage from 'pages/BackOffice/WeTogether/EMI/Workshop/AddEmiWorkshopPage'
import BOEmiEditWorkshopPage from 'pages/BackOffice/WeTogether/EMI/Workshop/EditEmiWorkshopPage'
import BOWhitelistPage from 'pages/BackOffice/Whitelist'
import BOWhitelistFormPage from 'pages/BackOffice/Whitelist/Form'
import BOReportPage from 'pages/BackOffice/Report'
import PointTransferPage from './pages/PointTransfer'
import LeaderWorkShopPage from 'pages/WeTogether/LeaderShip/LeaderWorkshopPage'
import BOLeaderRequestPage from 'pages/BackOffice/WeTogether/LeaderShip/Request'
import BOLeaderWorkshopPage from 'pages/BackOffice/WeTogether/LeaderShip/WorkShop'
import BOLeaderAddWorkshopPage from 'pages/BackOffice/WeTogether/LeaderShip/WorkShop/AddNewWorkshopPage'
import BOLeaderEditWorkshopPage from 'pages/BackOffice/WeTogether/LeaderShip/WorkShop/EditLeaderWorkshopPage'
import BOCommunityForbiddenWordPage from 'pages/BackOffice/Community/ForbiddenWord'
import BOCommunityHashtagPage from 'pages/BackOffice/Community/Hashtag'
import BODynamicRequestPage from 'pages/BackOffice/DynamicPage/Request'
import BODynamicWorkshopPage from 'pages/BackOffice/DynamicPage/Workshop'
import BODynamicWorkshopAddPage from 'pages/BackOffice/DynamicPage/Workshop/AddCommonWorkshopPage'
import BODynamicWorkshopEditPage from 'pages/BackOffice/DynamicPage/Workshop/EditCommonWorkshopPage'
import BODynamicCoursePage from 'pages/BackOffice/DynamicPage/Course'
import BODynamicCourseAddPage from 'pages/BackOffice/DynamicPage/Course/CommonAddCoursePage'
import BODynamicCourseEditPage from 'pages/BackOffice/DynamicPage/Course/CommonEditCoursePage'
// External Link
import RedirectPage from 'pages/Redirect'

import AccessDenied from 'pages/AccessDenied'
import NotFound from 'pages/NotFound'

import { useIsAuthenticated } from '@azure/msal-react'
import { useAuthStore } from 'stores/authStore'
import Swal from 'sweetalert2'
import BOPointTransferPage from './pages/BackOffice/PointTransferLogs'
import routePaths from 'routesPath'

const GroupEmi = 'EMI'
const GroupDefault = 'DEFAULT'

const AuthRoute = ({ children }) => {
  const nowPath = window.location.pathname
  const isAuthenticated = useIsAuthenticated()
  const queryParameters = new URLSearchParams(window.location.search)
  const { workshopId } = useParams()
  const id = queryParameters.get("id")
  if (!isAuthenticated) {
    if(nowPath === '/leadership'){
      return <Navigate to={`${routePaths.LOGIN}/leadership`} replace />
    }
    else if(nowPath === '/inspirations/workshops') {
      return <Navigate to={`${routePaths.LOGIN}/inspirations/workshops?id=${id}`} replace />
    }
    else if(nowPath === '/course/workshop/'+workshopId) {
      return <Navigate to={`${routePaths.LOGIN}/course/workshop/${workshopId}`} replace />
    }
    else {
      return <Navigate to={routePaths.LOGIN} replace />
    }
  } else {
    return children
  }
}

const AdminRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()
  const [hasRole] = useAuthStore((state) => [state.hasRole])

  if (!isAuthenticated) {
    return <Navigate to={routePaths.LOGIN} replace />
  }

  if (!hasRole('ADMIN')) {
    return <Navigate to={routePaths.ACCESS_DENIED_403} replace />
  }

  return children
}

const GroupRoute = ({ children }) => {
  const [hasGroup] = useAuthStore((state) => [state.hasGroup])
  if (!hasGroup(GroupEmi)) {
    if (hasGroup(GroupDefault)){
      return <Navigate to={routePaths.ACCESS_DENIED_403} replace />
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Notice',
        text: 'Session Expire.',
        confirmButtonText: 'OK'
      })
      return <Navigate to={routePaths.LOGIN} replace />
    }
  }
  return children
}

const BoGroupRoute = ({ children }) => {
  const [hasGroup] = useAuthStore((state) => [state.hasGroup])

  if (hasGroup(GroupEmi)) {
    return <Navigate to={routePaths.BO_EMI_REQUEST} replace />
  } else if(hasGroup(GroupDefault)) {
    return <Navigate to={routePaths.BO_INSPIRATION_REQUEST} replace />
  }
  return children
}


const mockDataRoute = [
  {
    path: routePaths.BO_DYNAMIC_COURSE,
    element: (
      <AdminRoute>
        <BODynamicCoursePage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_DYNAMIC_COURSE_ADD,
    element: (
      <AdminRoute>
        <BODynamicCourseAddPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_DYNAMIC_COURSE_EDIT,
    element: (
      <AdminRoute>
        <BODynamicCourseEditPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_DYNAMIC_MENU,
    element: (
      <AdminRoute>
        <BODynamicCoursePage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_DYNAMIC_WORKSHOP,
    element: (
      <AdminRoute>
        <BODynamicWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_DYNAMIC_WORKSHOP_ADD,
    element: (
      <AdminRoute>
        <BODynamicWorkshopAddPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_DYNAMIC_WORKSHOP_EDIT,
    element: (
      <AdminRoute>
        <BODynamicWorkshopEditPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_DYNAMIC_REQUEST,
    element: (
      <AdminRoute>
        <BODynamicRequestPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  }
]

let routes = [
  {
    path: routePaths.VERSION,
    element: <VersionPage />,
  },
  // Web
  {
    path: routePaths.ROOT,
    element: <LandingPage />,
    layout: 'no_layout',
  },
  {
    path: routePaths.LOGIN,
    element: <LoginPage />,
    layout: 'no_layout',
  },
  {
    path: routePaths.LOGIN_DIRECT,
    element: <LoginPage />,
    layout: 'no_layout',
  },
  {
    path: routePaths.LOGIN_DIRECT2,
    element: <LoginPage />,
    layout: 'no_layout',
  },
  {
    path: routePaths.LOGIN_DIRECT_INS_WORKSHOP,
    element: <LoginPage />,
    layout: 'no_layout',
  },
  {
    path: routePaths.HOME,
    element: (
      <AuthRoute>
        <HomePage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.PROFILE,
    element: (
      <AuthRoute>
        <ProfilePage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.BOOKS,
    element: (
      <AuthRoute>
        <BookPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.ADDFUEL,
    element: (
      <AuthRoute>
        <AddFuelPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.WHATISMETOWE,
    element: (
      <AuthRoute>
        <WhatIsMeToWePage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.E_LEARNING,
    element: (
      <AuthRoute>
        <ELearningPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.INSPIRATIONS,
    element: (
      <AuthRoute>
        <InspirationPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.WORKSHOPS,
    element: (
      <AuthRoute>
        <WorkshopPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.CONTACT,
    element: (
      <AuthRoute>
        <ContactPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.PRIZE_COLLECTION,
    element: (
      <AuthRoute>
        <PrizeCollectionPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.TRANSFER_POINT,
    element: (
      <AuthRoute>
        <PointTransferPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.WETOGETHER,
    element: (
      <AuthRoute>
        <WeTogetherPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.EMI,
    element: (
      <AuthRoute>
        <GroupRoute>
          <EmiPage />
        </GroupRoute>
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.EMIWORKSHOP,
    element: (
      <AuthRoute>
        <GroupRoute>
          <EmiWorkShopPage />
        </GroupRoute>
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.EPSPIRIT,
    element: (
      <AuthRoute>
        <EPSpiritPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.SD,
    element: (
      <AuthRoute>
        <SDPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.CHECK_IN,
    element: (
      <AdminRoute>
        <CheckInPage />
      </AdminRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.COMMUNITY,
    element: (
      <AuthRoute>
        <CommunityPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.COMMUNITY_BY_ID,
    element: (
      <AuthRoute>
        <CommunityByIdPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.DYNAMICS_COURSE_BY_ID,
    element: (
      <AuthRoute>
        <DynamicsMenuByCourse />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.DYNAMICS_WORKSHOP_BY_ID,
    element: (
      <AuthRoute>
        <DynamicsMenuByWorkshop />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  {
    path: routePaths.LEADER_SHIP,
    element: (
      <AuthRoute>
        <LeaderWorkShopPage />
      </AuthRoute>
    ),
    layout: 'web_layout',
  },
  // Back Office
  {
    path: routePaths.BO_HOME,
    element: (
      <BoGroupRoute />
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_BOOK,
    element: (
      <AdminRoute>
        <BOBookPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_BOOK_NEW,
    element: (
      <AdminRoute>
        <BOBookFormPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_BOOK_EDIT,
    element: (
      <AdminRoute>
        <BOBookFormPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_BOOK_REQUEST,
    element: (
      <AdminRoute>
        <BOBookRequestPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_ELEARNING,
    element: (
      <AdminRoute>
        <BOELearningPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_INSPIRATION_COURSE,
    element: (
      <AdminRoute>
        <BOInspirationCoursePage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_INSPIRATION_COURSE_ADD,
    element: (
      <AdminRoute>
        <BOInspirationAddCoursePage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_INSPIRATION_COURSE_EDIT,
    element: (
      <AdminRoute>
        <BOInspirationEditCoursePage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_INSPIRATION_WORKSHOP,
    element: (
      <AdminRoute>
        <BOInspirationWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_INSPIRATION_WORKSHOP_ADD,
    element: (
      <AdminRoute>
        <BOInspirationAddWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_INSPIRATION_WORKSHOP_EDIT,
    element: (
      <AdminRoute>
        <BOInspirationEditWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_INSPIRATION_REQUEST,
    element: (
      <AdminRoute>
        <BOInspirationRequestPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_REWARD,
    element: (
      <AdminRoute>
        <BORewardPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_REWARD_NEW,
    element: (
      <AdminRoute>
        <BORewardFormPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_REWARD_EDIT,
    element: (
      <AdminRoute>
        <BORewardFormPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_REWARD_REDEMPTION,
    element: (
      <AdminRoute>
        <BORewardRedemptionPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_EMI_COURSE,
    element: (
      <AdminRoute>
        <BOEmiCoursePage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_EMI_WORKSHOP,
    element: (
      <AdminRoute>
        <BOEmiWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_EMI_WORKSHOP_ADD,
    element: (
      <AdminRoute>
        <BOEmiAddWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_EMI_WORKSHOP_EDIT,
    element: (
      <AdminRoute>
        <BOEmiEditWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_EMI_REQUEST,
    element: (
      <AdminRoute>
        <BOEmiRequestPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_LEADER_WORKSHOP,
    element: (
      <AdminRoute>
        <BOLeaderWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_LEADER_WORKSHOP_ADD,
    element: (
      <AdminRoute>
        <BOLeaderAddWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_LEADER_WORKSHOP_EDIT,
    element: (
      <AdminRoute>
        <BOLeaderEditWorkshopPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_LEADER_REQUEST,
    element: (
      <AdminRoute>
        <BOLeaderRequestPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_WHITELIST,
    element: (
      <AdminRoute>
        <BOWhitelistPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_WHITELIST_ADD,
    element: (
      <AdminRoute>
        <BOWhitelistFormPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_REPORT,
    element: (
      <AdminRoute>
        <BOReportPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_POINT_TRANSFER,
    element: (
      <AdminRoute>
        <BOPointTransferPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_COMMUNITY_FORBIDDEN_WORD,
    element: (
      <AdminRoute>
        <BOCommunityForbiddenWordPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.BO_COMMUNITY_HASHTAG,
    element: (
      <AdminRoute>
        <BOCommunityHashtagPage />
      </AdminRoute>
    ),
    layout: 'bo_layout',
  },
  {
    path: routePaths.ACCESS_DENIED_403,
    element: <AccessDenied />,
    layout: '',
  },
  {
    path: routePaths.ALL,
    element: <NotFound />,
    layout: '',
  },
  //External URL
  {
    path: routePaths.NEWNORMAL,
    element: (
      <RedirectPage url="https://pttep.sharepoint.com/_forms/default.aspx" />
    ),
    layout: '',
  },
]

routes = routes.concat(mockDataRoute)

export default routes
