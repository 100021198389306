
import './sd.scss'

const SDPage = () => {
    return (<>
        <div className="is-container">
        <div className="page-header">SD</div>
        <div>Coming Soon...</div>
        </div>
    </>)
}

export default SDPage
