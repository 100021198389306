import { createHttpClient } from './me2weApi'

class Community {
  constructor() {
    if (!this.client) {
      this.client = createHttpClient()
    }
  }

  async getAllPost(req) {
    return this.client.post('/community/post', {
      page: req.page,
      page_size: 10,
      key: req.key,
      value: req.value
    })
  }

  async getAllPostWithPin() {
    return this.client.post('/community/post/get/pin', {
      page: 1,
      page_size: 10,
    })
  }

  async createPost(req) {
    return this.client.post('/community/post/create', {
      text: req.text,
      images: req.images
    })
  }

  async editPost(req) {
    return this.client.post('/community/post/update', {
      id: req.id,
      text: req.text,
      images: req.images
    })
  }

  async deletePost(req) {
    return this.client.post('/community/post/delete', {
      id: req.id
    })
  }

  async likePost(req) {
    return this.client.post('/community/post/like', {
      id: req.id
    })
  }

  async unLikePost(req) {
    return this.client.post('/community/post/unlike', {
      id: req.id
    })
  }

  async getPostById(req) {
    return this.client.post('/community/post/get', {
      id: req.id
    })
  }

  async createComment(req) {
    return this.client.post('/community/comment/create', {
      text: req.text,
      post_id: req.postId,
      level: req.level,
      comment_id: req.commentId,
      images: req.images
    })
  }

  async editComment(req) {
    return this.client.post('/community/comment/update', {
      text: req.text,
      id: req.id,
      images: req.images,
      post_id: req.post_id
    })
  }

  async deleteComment(req) {
    return this.client.post('/community/comment/delete', {
      id: req.id
    })
  }

  async likeComment(req) {
    return this.client.post('/community/comment/like', {
      id: req.id,
      post_id: req.post_id
    })
  }

  async unLikeComment(req) {
    return this.client.post('/community/comment/unlike', {
      id: req.id,
      post_id: req.post_id
    })
  }

  async getWord(req) {
    return this.client.post('/community/word/rude', {
      page: req.page,
      page_size: req.pageSize,
      txt: req.text,
      sort: 'created_at desc'
    })
  }

  async createWord(req) {
    return this.client.post('/community/word/rude/create', {
      text: req.word
    })
  }

  async deleteWord(req) {
    return this.client.post('/community/word/rude/delete', {
      id: req.id
    })
  }

  async getHashtag(req) {
    return this.client.post('/community/hashtag', {
      page: req.page,
      page_size: req.pageSize,
      txt: req.text,
      sort: 'created_at desc'
    })
  }

  async createHashtag(req) {
    return this.client.post('/community/hashtag/highlight/create', {
      text: req.word
    })
  }

  async pinHashtag(req) {
    return this.client.post('/community/hashtag/highlight/pin', {
      id: req.id
    })
  }

  async unpinHashtag(req) {
    return this.client.post('/community/hashtag/highlight/unpin', {
      id: req.id
    })
  }

  async pinPost(req) {
    return this.client.post('/community/post/pin', {
      id: req.id
    })
  }

  async unPinPost(req) {
    return this.client.post('/community/post/unpin', {
      id: req.id
    })
  }

  async getHighlightHashtag(req) {
    return this.client.post('/community/hashtag/highlight', {
      page: 1,
      page_size: 20
    })
  }

}

const communityApi = new Community()
export default communityApi
