import React, { useState, useEffect } from 'react'
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    Button,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    Table
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import ExportBtn from 'components/ExportBtn'
import { useBoWhitelistStore } from 'stores/BackOffice/boWhiteList'
import { rowPerPageOptions } from 'constant/boPagination';
import { fDateTime, fISODateToUTC } from 'utils/formatTime';
import { useNavigate } from 'react-router-dom';
import  routePaths  from 'routesPath';
import Swal from 'sweetalert2';
import whitelistRequestApi from 'api/whitelistRequestApi';

const initColumn = [
    {
      id: 'no',
      label: 'No.',
      minWidth: 30,
    },
    {
      id: 'full_name',
      label: 'Name',
      minWidth: 190,
    },
    {
      id: 'email',
      label: 'Email',
      minWidth: 200,
    },
    {
      id: 'group_name',
      label: 'Group',
      minWidth: 120
    },
    {
        id: 'updated_at',
        label: 'Update Date',
        minWidth: 200,
    }
  ]
  const initPaging = {
    group_id: null,
    page: 1,
    page_size: 10,
  }
function WhitelistPage() {
    const navigate = useNavigate()
    const [paging, setPaging] = useState(initPaging)
    const [
      groupId,
      groupName,
      whitelist,
      pagination,
      fetchUserByGroup,
      updateUserGroup
    ] = useBoWhitelistStore(
        (state) => [
          state.groupId,
          state.groupName,
          state.whitelist,
          state.pagination,
          state.fetchUserByGroup,
          state.updateUserGroup
        ]
      )

    const [exportData, setExportData] = useState([])
    const [nameSearch, setNameSearch] = useState("")
    const [groupSearch, setGroupSearch] = useState(null)
    const [groupList,setGroupList] = useState([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>{
        fetchUserByGroup(initPaging)
        fetchUserGroupList()
        paging.group_id = groupId
        return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
      fetchExportData()
      return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[nameSearch, groupSearch])
    const fetchExportData = async () => {
      const req = {
        group_id: null,
        page_size: 9999
      }
      if(nameSearch && nameSearch !== ""){
        req.full_name = nameSearch
      }
      if(groupSearch && groupSearch !== null){
        req.group_id = groupSearch
      }
      const res = await whitelistRequestApi.getUserByGroup(req)
      setExportData(res?.data?.data)
    }
    const onPageChange = async (e, newPage) => {
        e.preventDefault()
        await fetchUserByGroup({
          ...paging,
          page: newPage + 1,
        })
      }
    const onRowsPerPageChange = async (e) => {
        e.preventDefault()
        await fetchUserByGroup({
            ...paging,
            page: 1,
            pageSize: +e.target.value,
        })
    }
    const onSetSelectGroup = async (e) => {
        e.preventDefault()
        setGroupSearch(e.target.value)
        setPaging({
            ...paging,
            group_id: e.target.value
        })
    }
    const onChangeFullName = async (e) => {
      e.preventDefault()
      setNameSearch(e.target.value)
      setPaging({
        ...paging,
        full_name: e.target.value
      })
    }
    const onSearch = async () => {
       await fetchUserByGroup({
        ...paging,
        page: 1,
      })
    }
    const onClearClick = async () => {
        await fetchUserByGroup(initPaging)
    }
    const handleAddWhitlistClick = () => {
        navigate(routePaths.BO_WHITELIST_ADD)
    }
    const handleChangeGroup = (id, gid) => {
      new Swal({
        icon: 'info',
        title: ``,
        text: `Do you want to change group of user ?`,
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      }).then(async (re)=>{
        if(re.isConfirmed){
          const req = {
            id: id,
            group_id: gid
          }
          const res = await updateUserGroup(req)
          if( res === 200 ){
            Swal.fire({
              icon: 'success',
              text: 'Change Success'
            }).then(() => {
              fetchUserByGroup(initPaging)
            })
          } else {
            Swal.fire({
              icon: 'error',
              text: 'Have problem for change group!'
            })
          }
        }
      })
    }
    const fetchUserGroupList = async () => {
      const res = await whitelistRequestApi.getUserGroupList()
      return setGroupList(res?.data)
    }
    return (<>
    <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="mb-20"
      >
        <Grid item>
          <h2>White List</h2>
        </Grid>
        <Grid item md={2}>
          <Button
            data-testid="whitelist-btn-add"
            id="whitelist-btn-add"
            fullWidth
            variant="contained"
            startIcon={<AddIcon/>}
            onClick={() => handleAddWhitlistClick()}
          >
            Add New Group Menu
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        data-testid='name-en'
                        id="whitelist-search-name"
                        label="Name (EN)"
                        size="small"
                        fullWidth
                        placeholder="Name (EN)"
                        sx={{ backgroundColor: '#fff' }}
                        onChange={(e) => onChangeFullName(e)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                        <Select
                            data-testid='group'
                            placeholder="Group"
                            id="whitelist-select-group"
                            sx={{ backgroundColor: '#fff' }}
                            value={paging.group_id}
                            onChange={(e) => onSetSelectGroup(e)}
                        >
                         { Array.isArray(groupList.data) ? (groupList.data || []).map((item, i) => {
                              return (
                                <MenuItem key={i} value={item.id}>
                                  {`${item.group_name}`}
                                </MenuItem>
                              )
                            }): (<><MenuItem>Default</MenuItem>
                              <MenuItem>Emi</MenuItem></>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                id="rewardlist-btn-search"
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                fullWidth
                onClick={() => onSearch()}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                id="rewardlist-btn-clear"
                variant="contained"
                color="error"
                startIcon={<ClearIcon />}
                fullWidth
                onClick={() => onClearClick()}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={4}>
              <ExportBtn dataFile={exportData} pageId={'whitelist'} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>Group: {groupName || 'All'}</div><br/>
      <Grid container spacing={0} className="mb-30">
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {initColumn.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
               {whitelist?.length > 0 && (
                <TableBody>
                  {(whitelist || []).map((data, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell>
                          {(pagination.page - 1) * pagination.limit + (index + 1)}
                        </TableCell>
                        <TableCell>{data.full_name}</TableCell>
                        <TableCell>{data.email}</TableCell>
                        <TableCell>
                        <FormControl fullWidth size="small" >
                          <Select
                            id="select-group"
                            defaultValue={data.user_group.id}
                            onChange={(e) => handleChangeGroup(data.id, e.target.value)}
                          >
                            { Array.isArray(groupList.data) ? (groupList.data || []).map((item, i) => {
                              return (
                                <MenuItem key={i} value={item.id}>
                                  {`${item.group_name}`}
                                </MenuItem>
                              )
                            }): (<><MenuItem>Default</MenuItem>
                              <MenuItem>Emi</MenuItem></>)
                            }
                          </Select>
                        </FormControl>
                        </TableCell>
                        <TableCell>
                        {data.updated_at ? (
                            fDateTime(fISODateToUTC(data.updated_at))
                          ) : (
                            <Typography
                              variant="h6"
                              style={{ textAlign: 'center' }}
                            >{`-`}</Typography>
                          )}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
              </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={pagination?.total_count}
            rowsPerPage={paging.page_size}
            page={pagination?.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
          </Paper>
      </Grid>
    </>);
}

export default WhitelistPage;
