import { create } from 'zustand'
import frontEndApi from 'api/frontEndApi'

export const useInspirationStore = create((set, get) => ({
  courses: [],
  workshops: [],
  courseReserved: null,
  clearCourses: () => set({ courses: [] }),
  clearWorkshops: () => set({ workshops: [] }),
  fetchInspirationCourses: async (params) => {
    try {
      const response = await frontEndApi.getInspirationCourses(params)
      if (response.status === 200) {
        if (response.data.data.length) {
          const coursesData = params.page === 1
              ? response.data.data
              : get().courses.concat(response.data.data)
          set({
            courses: coursesData,
          })
          return true
        }
      }
    } catch {}
    return false
  },
  fetchInspirationWorkshops: async (params) => {
    try {
      const response = await frontEndApi.getInspirationWorkshopList(params)
      if (response.status === 200) {
        if (response.data.data && response.data.data.workshops.length) {
          const { workshops } = response.data.data

          const workshopsData =
            params.page === 1 ? workshops : get().workshops.concat(workshops)

          set({
            workshops: workshopsData,
          })
          return true
        }
      }
    } catch {}
    return false
  },
  reserveInspiration: async (params) => {
    return frontEndApi.reserveInspiration(params)
  },
  cancelInspiration: async (reservationId) => {
    return frontEndApi.cancelInspiration(reservationId)
  },
  fetchEmiCourses: async (params) => {
    try {
      const response = await frontEndApi.getEmiCourses(params)

      if (response.status === 200) {
        if (response.data.data.length) {
          const coursesData =
            params.page === 1
              ? response.data.data
              : get().courses.concat(response.data.data)

          set({
            courses: coursesData,
          })
          return true
        }
      }
    } catch {}
    return false
  },
  fetchEminWorkshops: async (params) => {
    try {
      const response = await frontEndApi.getEmiWorkshopList(params)
      if (response.status === 200) {
        if (response.data.data && response.data.data.workshops.length) {
          const { workshops } = response.data.data

          const workshopsData =
            params.page === 1 ? workshops : get().workshops.concat(workshops)

          set({
            workshops: workshopsData,
          })
          return true
        }
      }
    } catch {}
    return false
  },
  checkInWorkShop: async (params) => {
    try {
      const response = await frontEndApi.checkInWorkShop(params)
      if (response.status === 200) {
        if (response.data) {
          return response.data
        }
      }
    } catch {}
    return false
  },
  fetchLeaderWorkshops: async (params) => {
    try {
      const response = await frontEndApi.getNewWorkshopList(params)
      if (response.status === 200) {
        if (response.data.data && response.data.data.workshops.length) {
          const { workshops } = response.data.data

          const workshopsData =
            params.page === 1 ? workshops : get().workshops.concat(workshops)

          set({
            workshops: workshopsData,
          })
          return true
        }
      }
    } catch {}
    return false
  },
  getAllWorkShop: async (params) => {
    try {
      const response = await frontEndApi.getAllWorkShop(params)
      if (response.status === 200) {
        if (response.data.data && response.data.data.workshops.length) {
          const { workshops } = response.data.data
          const workshopsData =
            params.page === 1 ? workshops : get().workshops.concat(workshops)
            set({
              workshops: workshopsData,
            })
            return true
        }
      }
    } catch {}
    return false
  },
}))
