import React, { useState, useEffect } from 'react'
import { useLoading } from 'components/Loading/context'
import { rowPerPageOptions, initPagination } from 'constant/boPagination'
import { useNavigate} from 'react-router-dom'
import courseApi from 'api/inspirationCourseApi'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  TextField,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Swal from 'sweetalert2'
import ExportBtn from 'components/ExportBtn'
import { initColumns, initPaging, txtProps, groupDefault } from './templateValCourseDy'

const DynamicCoursePage = () => {
  const navigate = useNavigate()
  let pathname = window.location.pathname;
    pathname = pathname.substring(7)
    pathname = pathname.substring(0,pathname.indexOf("/")).toUpperCase()
  const { showLoading, hideLoading } = useLoading()
  const [paging, setPaging] = useState(initPaging(pathname))
  const [data, setData] = useState({
    courses: [],
    pagination: initPagination,
  })

  useEffect(() => {
    setTimeout(()=>{
      onFetchData(initPaging(pathname))
      return () => {}
    },500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  const [exportData, setExportData] = useState([])
  const [titleSearch, setTitleSearch] = useState("")
  const [instructorSearch, setInstructorSearch] = useState("")
  useEffect(() => {
    setTimeout(()=>{
      onFetchExport()
      return () => {}
    },500)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[titleSearch, instructorSearch,pathname])
  const onFetchExport = async () => {
    const reqExport = {
      pageSize: 9999,
      group_name: pathname || groupDefault
    }
    if(titleSearch && titleSearch !== ""){
      reqExport.title = titleSearch
    }
    if(instructorSearch && instructorSearch !== ""){
      reqExport.instructor = instructorSearch
    }
    const res = await courseApi.getCourses(reqExport)
    setExportData(res?.data?.data)
    return
  }
  const onFetchData = async (newPaging) => {
    showLoading()

    let courseDatas = []
    let paginationData = initPagination

    try {
      newPaging.group_name = pathname
      const response = await courseApi.getCourses(newPaging)
      courseDatas = response.data.data
      paginationData = response.data.pagination
      setPaging(newPaging)
      return 
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      setData({
        courses: courseDatas,
        pagination: paginationData,
      })
      hideLoading()
    }
  }

  const onAddCourseClick = (e) => {
    e.preventDefault()
    window.location.href = '/bo/dm/'+ pathname.toLowerCase() +'/course/add'
  }

  const onCriteriaChange = (name, value) => {
    if(name && name === "title"){
      setTitleSearch(value)
    }
    if(name && name === "instructor"){
      setInstructorSearch(value)
    }
    setPaging({
      ...paging,
      [name]: value,
    })
  }

  const onSearchClick = async (e) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: 1,
    })
  }

  const onClearClick = async (e) => {
    e.preventDefault()
    await onFetchData(initPaging)
  }

  const onEditClick = async (e, courseId) => {
    e.preventDefault()
    navigate(`${'/bo/dm/'+ pathname.toLowerCase() +'/course/edit'}?id=${courseId}`)
  }

  const onDeleteClick = async (e, courseId) => {
    e.preventDefault()
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await courseApi.deleteCourseById(courseId)
          if (response.status === 200) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            ).then(async (res) => {
              if (res.isConfirmed) {
                await onFetchData(initPaging)
              }
            })
          } else {
            throw new Error('failed')
          }
        } catch {
          Swal.fire({
            icon: 'error',
            title: `Error`,
            text: `System error.`,
          })
        }
      }
    })
  }

  const onPageChange = async (e, newPage) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: newPage + 1,
    })
  }

  const onRowsPerPageChange = async (e) => {
    e.preventDefault()
    await onFetchData({
      ...paging,
      page: 1,
      pageSize: +e.target.value,
    })
  }
  useEffect(() => {
    setData({
      courses: [],
      pagination: initPagination,
    })
    return () => {}
  }, []);
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="mb-20"
      >
        <Grid item>
          <h2>{pathname.replaceAll("_", " ")} Course List</h2>
        </Grid>
        <Grid item md={2}>
          <Button
            id="dynamic-course-btn-add"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onAddCourseClick}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mb-30">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            id="dynamic-course-search-nane"
            label="Name (TH/EN)"
            value={paging.title || ''}
            onChange={(e) => onCriteriaChange('title', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            {...txtProps}
            id="dynamic-course-search-instructor"
            label="Instructor (TH/EN)"
            value={paging.instructor || ''}
            onChange={(e) => onCriteriaChange('instructor', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="dynamic-course-btn-search"
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<SearchIcon />}
            onClick={onSearchClick}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            id="dynamic-course-btn-clear"
            variant="contained"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={onClearClick}
          >
            Clear
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
        <ExportBtn dataFile={exportData} pageId={`dynamic-course`} />

        </Grid>
      </Grid>

      <Grid container>
        <Paper sx={{ width: '1200px', overflow: 'hidden' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {initColumns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(data.courses || []).map((item, i) => {
                  return (
                    <TableRow hover key={i}>
                      <TableCell>
                        {(data.pagination.page - 1) * data.pagination.limit +
                          (i + 1)}
                      </TableCell>
                      <TableCell>
                        <img
                          src={item.image}
                          alt={item.title_en}
                          width="65px"
                        />
                      </TableCell>
                      <TableCell>{item.title_th}</TableCell>
                      <TableCell>{item.title_en}</TableCell>
                      <TableCell>{item.instructor_th}</TableCell>
                      <TableCell>{item.instructor_en}</TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {item.description_th}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Typography
                          variant="subtitle2"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {item.description_en}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          id="inspiration-course-btn-edit"
                          color="primary"
                          onClick={(e) => onEditClick(e, item.id)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          id="inspiration-course-btn-delete"
                          color="error"
                          onClick={(e) => onDeleteClick(e, item.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={rowPerPageOptions}
            component="div"
            count={data.pagination.total_count}
            rowsPerPage={paging.pageSize}
            page={data.pagination.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Paper>
      </Grid>
    </>
  )
}

export default DynamicCoursePage
