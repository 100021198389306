import { styled } from 'styled-components'
import { zIndex } from 'styles/variables';
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

export const WrapperFooter = styled(BottomNavigation)`
  background: ${({ theme }) => theme?.colors?.footer?.default} !important;
  position: fixed;
  bottom: 0;
  height: 56px;
  z-index: ${zIndex.footer};
`;

export const WrpperBottomNavigationAction = styled(BottomNavigationAction)`
  color: ${({ theme }) => theme?.colors?.footer?.text} !important;
  img {
    filter: brightness(0) invert(1);
  }
`;
