import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBookStore } from 'stores/bookStore'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid } from '@mui/material'

import SearchField from 'components/SearchField'
import CustomButton from 'components/CustomButton'
import HeartPoint from 'components/HeartPoint'
import NotifyDialog from 'components/NotifyDialog'
import Loading from 'components/Loading'

import './book.scss'
import ProfileBox from 'components/ProfileBox'
import ProfileNav from 'components/ProfileNav'

export default function BookPage() {
  const { t, i18n } = useTranslation()
  const [books, clearBooks, fetchBooks, requestBook] = useBookStore((state) => [
    state.books,
    state.clearBooks,
    state.fetchBooks,
    state.requestBook,
  ])

  const [hasMoreData, setHasMoreData] = useState(true)
  const [selectBook, setSelectBook] = useState(null)

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [isShowSuccessDialog, setIsShowSuccessDialog] = useState(false)
  const [isShowErrorDialog, setIsShowErrorDialog] = useState(false)

  const [paging, setPaging] = useState({
    title: '',
    page: 0,
    pageSize: 10,
  })

  useEffect(() => {
    clearBooks()
    onFetchBooks(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFetchBooks = async (isClearPage = false) => {
    const newPaging = {
      ...paging,
      page: isClearPage ? 1 : paging.page + 1,
      sort: 'created_at desc',
    }

    const result = await fetchBooks(newPaging)
    setHasMoreData(result)
    setPaging(newPaging)
  }

  const onSearchClick = async (e) => {
    e.preventDefault()
    clearBooks()
    await onFetchBooks(true)
  }

  const onGetThisBookClick = (e, book) => {
    e.preventDefault()
    setSelectBook(book)
    setIsShowConfirmDialog(true)
  }

  const onConfirmDialogClick = async (e) => {
    e.preventDefault()
    setIsShowConfirmDialog(false)
    const result = await requestBook(selectBook?.id)

    if (result) {
      clearBooks()
      await onFetchBooks(true)
      setIsShowSuccessDialog(true)
    } else {
      setIsShowErrorDialog(true)
    }
  }

  return (
    <>
      <div className="book-container">
        <ProfileBox />
        <ProfileNav />
        <div className="page-header">{t('books')}</div>

        <SearchField
          id="btnSearch"
          onChange={(e) => setPaging({ ...paging, title: e.target.value })}
          onClick={onSearchClick}
        />

        <InfiniteScroll
          className='wrapper-book'
          dataLength={books.length}
          next={onFetchBooks}
          hasMore={hasMoreData}
          loader={<Loading open />}
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>- {t('dataNotFound')} -</b>
          //   </p>
          // }
        >
          {(books || []).map((item, i) => {
            return (
              <div key={i} className="b-box">
                <img src={item.image} className="b-img" alt={item.title_en} />
                <div className="b-box-content">
                  <div className="b-title">
                    {item[`title_${i18n.language}`]}
                  </div>
                  <div className="b-desc">
                    {item[`description_${i18n.language}`]}
                  </div>
                  <HeartPoint size="sm" point={item.points} />
                  <Grid container>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={7}>
                      <CustomButton
                        id="btnGetBook"
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={item.requested}
                        onClick={(e) => onGetThisBookClick(e, item)}
                      >
                        {t(item.requested ? 'youGotThis' : 'getThisBook')}
                      </CustomButton>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )
          })}
        </InfiniteScroll>

        <NotifyDialog
          id="dialogConfirmRequestBook"
          type="confirmation"
          title={t('doYouWant')}
          bodyText={t('requestForBook')}
          okText={t('confirm')}
          cancelText={t('cancel')}
          iconImgPath={selectBook?.image || '-'}
          open={isShowConfirmDialog}
          onCloseDialog={() => setIsShowConfirmDialog(false)}
          onCancelDialogClick={() => setIsShowConfirmDialog(false)}
          onOkDialogClick={onConfirmDialogClick}
        />

        <NotifyDialog
          type="success"
          title={t('requestSentSuccessfully')}
          bodyText={t('pleaseWaitForReply')}
          okText={t('ok_text')}
          iconImgPath={selectBook?.image || '-'}
          open={isShowSuccessDialog}
          onCloseDialog={() => setIsShowSuccessDialog(false)}
          onOkDialogClick={() => setIsShowSuccessDialog(false)}
        />

        <NotifyDialog
          type="error"
          title={t('requestSentFail')}
          bodyText={t('systemError')}
          okText={t('ok_text')}
          open={isShowErrorDialog}
          onCloseDialog={() => setIsShowErrorDialog(false)}
          onOkDialogClick={() => setIsShowErrorDialog(false)}
        />
      </div>
    </>
  )
}
