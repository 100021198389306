const config = {
  env: process.env.NODE_ENV || '',
  me2we: {
    apiBaseUrl: process.env.REACT_APP_ME2WE_API_BASE_URL || '',
  },
  externalUrl: {
    eLearningLogin: 'https://www.architeck.net/login/index.php',
    newNormal: 'https://pttep.sharepoint.com/teams/PTTEPNEWNORMAL101',
  },
  web: {
    inspirationDayBeforeCancel: 5,
  },
  secretKey: process.env.REACT_APP_SECRET_KEY || '',
}

export default config
