import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInspirationStore } from 'stores/inspirationStore'
import { fISODateToUTC } from 'utils/formatTime'
import { useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import CustomButton from 'components/CustomButton'
import HeartPoint from 'components/HeartPoint'
import Loading from 'components/Loading'
import NotifyDialog from 'components/NotifyDialog'
import config from 'config'

import ProfileBox from 'components/ProfileBox'
import ProfileNav from 'components/ProfileNav'

import './workshop.scss'

const EmiWorkShopPage = () => {
  const  location = useLocation()
  const searchParams = new URLSearchParams(location?.['search'])
  const { t, i18n } = useTranslation()
  const [
    workshops,
    clearWorkshops,
    fetchEminWorkshops,
    reserveInspiration,
    cancelInspiration,
  ] = useInspirationStore((state) => [
    state.workshops,
    state.clearWorkshops,
    state.fetchEminWorkshops,
    state.reserveInspiration,
    state.cancelInspiration,
  ])

  const [hasMoreData, setHasMoreData] = useState(true)
  const [paging, setPaging] = useState({
    page: 0,
    pageSize: 5,
  })
  const [selectData, setSelectData] = useState({
    courseId: 0,
    workshopId: 0,
    sessionId: 0,
    reservationId: 0,
    quota: 0,
    totalReserved: 0,
  })

  const [isShowJoinConfirmDialog, setIsShowJoinConfirmDialog] = useState(false)
  const [isShowJoinSuccessDialog, setIsShowJoinSuccessDialog] = useState(false)
  const [isShowCancelConfirmDialog, setIsShowCancelConfirmDialog] =
    useState(false)
  const [isShowCancelSuccessDialog, setIsShowCancelSuccessDialog] =
    useState(false)
  const [isShowWarningDialog, setIsShowWarningDialog] = useState(false)
  const [isShowErrorDialog, setIsShowErrorDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    clearWorkshops()
    onFetchInspirationWorkshops(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFetchInspirationWorkshops = async (isClearPage = false) => {
    const newPaging = {
      ...paging,
      courseId: +searchParams.get('id'),
      page: isClearPage ? 1 : paging.page + 1,
      sort: 'created_at desc',
    }

    const result = await fetchEminWorkshops(newPaging)
    setHasMoreData(result)
    setPaging(newPaging ? 1 : 0)
  }

  const onJoinClick = (e, sessionItem) => {
    e.preventDefault()

    const {
      course_id,
      workshop_id,
      id,
      reservation_id,
      quota,
      total_reserved,
    } = sessionItem

    setSelectData({
      courseId: course_id,
      workshopId: workshop_id,
      sessionId: id,
      reservationId: reservation_id,
      quota: quota,
      totalReserved: total_reserved,
    })

    const seatFull = total_reserved >= quota

    if (seatFull) {
      setIsShowWarningDialog(true)
    } else {
      setIsShowJoinConfirmDialog(true)
    }
  }

  const onConfirmJoinDialogClick = async (e) => {
    e.preventDefault()
    setIsShowJoinConfirmDialog(false)
    setIsShowWarningDialog(false)

    const { courseId, workshopId, sessionId } = selectData

    try {
      await reserveInspiration({
        courseId,
        workshopId,
        sessionId,
      })
      clearWorkshops()
      await onFetchInspirationWorkshops(true)
      setIsShowJoinSuccessDialog(true)
      setErrorMessage('')
    } catch (err) {
      const errMsg = i18n.t(`error.INSPIRATION_RESERVE_OUT_OF_TIME`)
      setErrorMessage(errMsg)
      setIsShowErrorDialog(true)
    }
  }

  const onCancelClick = (e, sessionItem) => {
    e.preventDefault()
    setSelectData({
      courseId: sessionItem.course_id,
      workshopId: sessionItem.workshop_id,
      sessionId: sessionItem.id,
      reservationId: sessionItem.reservation_id,
      quota: sessionItem.quota,
      totalReserved: sessionItem.total_reserved,
    })
    setIsShowCancelConfirmDialog(true)
  }

  const onConfirmCancelDialogClick = async (e) => {
    e.preventDefault()
    setIsShowCancelConfirmDialog(false)

    const { reservationId } = selectData
    try {
      await cancelInspiration(reservationId)
      clearWorkshops()
      await onFetchInspirationWorkshops(true)
      setIsShowCancelSuccessDialog(true)
      setErrorMessage('')
    } catch (err) {
      const errMsg = i18n.t(`error.INSPIRATION_CANCEL_OUT_OF_TIME`)
      setErrorMessage(errMsg)
      setIsShowErrorDialog(true)
    }
  }

  const checkDisableButton = (eventDate, isReserve, isCourseReserve) => {
    const nextDate = new Date()
    nextDate.setDate(nextDate.getDate() + config.web.inspirationDayBeforeCancel)
    nextDate.setHours(0, 0, 0, 0)

    const nextDateResetTime = new Date(nextDate)
    const eventDateResetTime = new Date(
      fISODateToUTC(eventDate).setHours(0, 0, 0, 0)
    )
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0))

    if (currentDate > eventDateResetTime) {
      return true
    } else if (isReserve && isCourseReserve) {
      return nextDateResetTime >= eventDateResetTime
    } else {
      return isCourseReserve
    }
  }

  return (
    <Fragment>
      <div className="is-container">
        <ProfileBox />
        <ProfileNav />
      </div>
      <div className="ws-container" data-testid='emi-workshop'>
        <div className="c-label-sl">{t('oneSession')}</div>
        <InfiniteScroll
          dataLength={workshops.length}
          next={onFetchInspirationWorkshops}
          hasMore={hasMoreData}
          loader={<Loading open />}
        >
          {(workshops || []).map((item, i) => {
            return (
              <div key={i} className="card-container">
                <div className="card-img">
                  <img src={item.image} alt="Workshop A1" />
                </div>
                <div className="card-content">
                  <div className="c-title-container">
                    <div className="c-title">
                      {item[`title_${i18n.language}`]}
                    </div>
                    <HeartPoint point={item.points} size="sm" />
                  </div>
                  {/* <div className="c-sub-title">"New Me"</div> */}
                  <div className="c-detail">
                    <p>{item[`description_${i18n.language}`]}</p>
                  </div>
                  <div className="c-sub-title">
                  </div>

                  {(item.sessions || []).map((sessionItem, rIndex) => {
                    const disableBtn = checkDisableButton(
                      item.event_date,
                      sessionItem.reserved,
                      item.workshop_reserved
                    )

                    return (
                      <div key={rIndex} className="c-period">
                        <div className="c-time-round">
                          {`${sessionItem.event_time} ${t('limit')} `}
                          <b>{`(${sessionItem.total_reserved}/${sessionItem.quota})`}</b>
                        </div>
                        <CustomButton
                          id="btnJoinOrCancel"
                          variant="contained"
                          color={sessionItem.reserved ? 'error' : 'primary'}
                          onClick={async (e) => {
                            if (sessionItem.reserved) {
                              onCancelClick(e, sessionItem)
                            } else {
                              onJoinClick(e, sessionItem)
                            }
                          }}
                          disabled={disableBtn}
                        >
                          {sessionItem.reserved ? t('cancel') : t('participate')}
                        </CustomButton>
                      </div>
                    )
                  })}

                  <div className="c-remark">
                    {t('cancelBeforeDay').replace(
                      '{0}',
                      config.web.inspirationDayBeforeCancel
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </InfiniteScroll>

        <div id="all-dialog">
          <NotifyDialog
            type="confirmation"
            title={t('doYouWant')}
            bodyText={t('wantToJoin')}
            okText={t('confirm')}
            cancelText={t('cancel')}
            open={isShowJoinConfirmDialog}
            onCloseDialog={() => setIsShowJoinConfirmDialog(false)}
            onCancelDialogClick={() => setIsShowJoinConfirmDialog(false)}
            onOkDialogClick={onConfirmJoinDialogClick}
          />

          <NotifyDialog
            type="success"
            title={t('registerSuccess')}
            bodyText={t('adminSentMailConfirm')}
            okText={t('confirm')}
            open={isShowJoinSuccessDialog}
            onCloseDialog={() => setIsShowJoinSuccessDialog(false)}
            onOkDialogClick={() => setIsShowJoinSuccessDialog(false)}
          />

          <NotifyDialog
            type="confirmation"
            title={t('doYouWant')}
            bodyText={t('cancelOrNot')}
            okText={t('confirm')}
            cancelText={t('cancel')}
            open={isShowCancelConfirmDialog}
            onCloseDialog={() => setIsShowCancelConfirmDialog(false)}
            onCancelDialogClick={() => setIsShowCancelConfirmDialog(false)}
            onOkDialogClick={onConfirmCancelDialogClick}
          />

          <NotifyDialog
            type="success"
            title={t('cancelSuccess')}
            bodyText={t('cancelTransactionSuccess')}
            okText={t('confirm')}
            open={isShowCancelSuccessDialog}
            onCloseDialog={() => setIsShowCancelSuccessDialog(false)}
            onOkDialogClick={() => setIsShowCancelSuccessDialog(false)}
          />

          <NotifyDialog
            type="warning"
            title={t('sorrySeatExceed')}
            bodyText={t('seatExceed')?.replace(
              '{0}',
              selectData.totalReserved - selectData.quota + 1
            )}
            okText={t('confirm')}
            cancelText={t('cancel')}
            open={isShowWarningDialog}
            onCloseDialog={() => setIsShowWarningDialog(false)}
            onOkDialogClick={onConfirmJoinDialogClick}
            onCancelDialogClick={() => setIsShowWarningDialog(false)}
          />

          <NotifyDialog
            type="error"
            title={t('requestSentFail')}
            bodyText={errorMessage || t('systemError')}
            okText={t('confirm')}
            open={isShowErrorDialog}
            onCloseDialog={() => setIsShowErrorDialog(false)}
            onOkDialogClick={() => setIsShowErrorDialog(false)}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default EmiWorkShopPage
