import { Link } from 'react-router-dom'
import { Container } from '@mui/material'
import routePaths from 'routesPath'

const NotFound = () => {
  return (
    <Container maxWidth="lg" style={{ textAlign: 'center' }}>
      <h2>404 - Page Not Found!</h2>
      <p>
        <Link id="btnBackToHome" to={routePaths.HOME}>
          Back to Home page
        </Link>
      </p>
    </Container>
  )
}

export default NotFound
