import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.scss'
import './i18n'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { LoadingProvider } from './components/Loading/context'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <LoadingProvider>
      <App />
    </LoadingProvider>
  </MsalProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
