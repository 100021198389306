import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { Grid, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'

import { useBoInspirationWorkshopStore } from 'stores/BackOffice/boInspirationWorkshop'

const errorLabel = {
  event_time: 'Please fill in Event Time',
  quota: 'Please fill in Quota',
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export default function SessionDialog({
  open,
  session,
  onSaveClick,
  onCloseClick,
}) {
  const [error, setError] = useState({})
  const [addSession] = useBoInspirationWorkshopStore((state) => [
    state.addSession,
  ])

  const [currentSession, setCurrentSession] = useState({
    event_time: '',
    quota: '',
  })

  useEffect(() => {
    setCurrentSession(session)
  }, [session])

  const onTextChange = (key, value) => {
    setCurrentSession({
      ...currentSession,
      [key]: value,
    })
  }

  const onCloseDialog = () => {
    setError({})
    onCloseClick()
  }

  const onSave = () => {
    if (!validateInput()) {
      addSession(currentSession)
      onSaveClick()
    }
  }

  const validateInput = () => {
    let isError = false
    let newError = {}

    Object.entries(currentSession).forEach((fields) => {
      const errorText = errorLabel[fields[0]]
      if ((!fields[1] || fields[1] === '') && errorText && errorText !== '') {
        newError = {
          ...newError,
          [fields[0]]: errorLabel[fields[0]],
        }
        isError = true
      }
    })

    setError(newError)
    return isError
  }

  return (
    <div>
      <BootstrapDialog
        fullWidth
        aria-labelledby="session-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Add Session
          {onCloseDialog ? (
            <IconButton
              id="session-dialog-btn-close"
              aria-label="close"
              onClick={onCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="session-dialog-input-time"
                label="Time"
                size="small"
                fullWidth
                value={currentSession?.event_time || ''}
                onChange={(e) => onTextChange('event_time', e.target.value)}
                error={!!error.event_time}
                helperText={error.event_time}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="session-dialog-input-quota"
                label="Quota"
                size="small"
                type="number"
                fullWidth
                value={currentSession?.quota || ''}
                onChange={(e) => onTextChange('quota', +e.target.value)}
                error={!!error.quota}
                helperText={error.quota}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '16px' }}>
          <Button
            id="session-dialog-btn-save"
            variant="contained"
            autoFocus
            onClick={() => onSave()}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
