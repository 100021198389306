import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import NotifyDialog from 'components/NotifyDialog'
import  routePaths  from 'routesPath'
import { useAuthStore } from 'stores/authStore'

import './main-layout.scss'

export default function TopBar({ toggleSidebar }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { instance } = useMsal()
  const [clearAuth] = useAuthStore((state) => [state.clearAuth])
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)

  const onLogoutClick = (e) => {
    e.preventDefault()
    setIsShowConfirmDialog(true)
  }

  const onConfirmDialogClick = async (e) => {
    e.preventDefault()
    await instance.logout({
      onRedirectNavigate: (url) => {
        return false
      },
    })
    clearAuth()
    navigate(routePaths.LOGIN)
  }

  const onMouseDownFireEvent = (e) => {
    e.preventDefault()
  }

  return (
    <div className="bo-topbar-container">
      <Box>
        <AppBar position="static" color="primary">
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              id="icToggle"
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar}
              onMouseDown={onMouseDownFireEvent}
            >
              <MenuIcon />
            </IconButton>

            <Button
              id="btnLogout"
              sx={{ textTransform: 'capitalize' }}
              variant="text"
              color="inherit"
              startIcon={<PowerSettingsNewIcon />}
              onClick={onLogoutClick}
            >
              <Typography variant="body1">Logout</Typography>
            </Button>
          </Toolbar>
        </AppBar>
      </Box>

      <NotifyDialog
        id="dialogAskToConfirmClose"
        type="confirmation"
        title={t('doYouWant')}
        bodyText={t('logout')}
        okText={t('confirm')}
        cancelText={t('cancel')}
        open={isShowConfirmDialog}
        onCloseDialog={() => setIsShowConfirmDialog(false)}
        onCancelDialogClick={() => setIsShowConfirmDialog(false)}
        onOkDialogClick={onConfirmDialogClick}
      />
    </div>
  )
}
