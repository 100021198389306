import defaultTheme from 'styles/themes/default/theme'
import blueTheme from 'styles/themes/blue/theme'
import greenTheme from 'styles/themes/green/theme'
import yellowTheme from 'styles/themes/yellow/theme'
import darkTheme from 'styles/themes/dark/theme'

const themeList = [
  'default',
  'blue',
  'green',
  'dark'
];

function getThemeName(themeName) {
  if (themeName && themeList.includes(themeName)) {
     return themeName;
  }
  return null;
}

export function checkTypeTheme(themeName) {
  if (getThemeName(themeName)) {
    switch (themeName) {
      case 'default':
        return 'light';
      case 'blue':
        return 'blue';
      case 'green':
        return 'green';
      default:
        return 'dark';
    }
  }

  return 'yellow';
}

export function checkRenderTheme(themeName) {
  if (getThemeName(themeName)) {
    switch (themeName) {
      case 'default':
        return defaultTheme;
      case 'blue':
          return blueTheme;
      case 'green':
          return greenTheme;
      default:
        return darkTheme;
    }
  }

  return yellowTheme;
}
