import { create } from 'zustand'
import frontEndApi from 'api/frontEndApi'

export const useBookStore = create((set, get) => ({
  books: [],
  clearBooks: () => set({ books: [] }),
  fetchBooks: async (params) => {
    try {
      const response = await frontEndApi.getBooks(params)

      if (response.status === 200) {
        if (response.data.data.length) {
          const booksData =
            params.page === 1
              ? response.data.data
              : get().books.concat(response.data.data)

          set({
            books: booksData,
          })
          return true
        }
      }
    } catch {}
    return false
  },
  requestBook: async (bookId) => {
    try {
      const response = await frontEndApi.requestBook({ bookId })
      return response.status === 200
    } catch {
      return false
    }
  },
}))
