export const arrTemplate = (titleN,groupN) => [
    {
        title: titleN + "Course",
        path_str: `/bo/dm/${groupN}/course`
    },
    {
        title: titleN + "Course Add",
        path_str: `/bo/dm/${groupN}/course/add`
    },
    {
        title: titleN + "Course Edit",
        path_str: `/bo/dm/${groupN}/course/edit`
    },
    {
        title: titleN + "Workshop",
        path_str: `/bo/dm/${groupN}/workshop`
    },
    {
        title: titleN + "Workshop Add",
        path_str: `/bo/dm/${groupN}/workshop/add`
    },
    {
        title: titleN + "Workshop Edit",
        path_str: `/bo/dm/${groupN}/workshop/edit`
    },
    {
        title: titleN + "Request",
        path_str: `/bo/dm/${groupN}/request`
    },
]
