import { create } from 'zustand'
import frontEndApi from 'api/dynamicsMenuApi'

export const useDynamicsMenuStore = create((set, get) => ({
  allMenuLists: [],
  menuListById: null,
  clearAllMenuLists: () => set({ allMenuLists: [] }),
  getAllMenuLists: async () => {
    try {
      const response = await frontEndApi.getMenuLists()
      if (response.status === 200) {
        if (response.data.data && response.data.data.length) {
          const res = response.data.data
          set({
            allMenuLists: res,
          })
          return res
        }
      }
    } catch {}
    return false
  }
}))
