/* eslint-disable no-unused-vars */
import { useNavigate, useLocation } from 'react-router';
import  routePaths  from 'routesPath'
import { useLoading } from 'components/Loading/context'
import CourseForm from './CourseForm'
import courseApi from 'api/inspirationCourseApi'
import Swal from 'sweetalert2'

const groupEmi = 'EMI'

const AddCoursePage = () => {
  const history = useNavigate()
  const location = useLocation()
  const { showLoading, hideLoading } = useLoading()
  const searchParams = new URLSearchParams(location.search);
  const groupName = searchParams.get('group_name')

  const handleSave = async (inputData) => {
    showLoading()
    try {
      const response = await courseApi.createCourse(inputData)
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: groupName === groupEmi ? 'Add Emi Course success' : 'Add Inspiration Hour Course success',
        }).then((result) => {
          if (result.isConfirmed) {
            if(groupName === groupEmi) {
              history(routePaths.BO_EMI_COURSE)
            } else {
              history(routePaths.BO_INSPIRATION_COURSE)
            }
          }
        })
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: `Error`,
        text: `System error.`,
      })
    } finally {
      hideLoading()
    }
  }

  return <CourseForm mode="Add" onSave={handleSave} />
}

export default AddCoursePage
