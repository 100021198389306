import React, { useState, useEffect } from 'react'

import  routePaths  from 'routesPath'
import { useTranslation } from 'react-i18next'

import BrandLogo from 'components/BrandLogo'
import { useAuthStore } from 'stores/authStore'
import NotifyDialog from 'components/NotifyDialog'

// Scss
import './login.scss'
import { Grid } from '@mui/material'

import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { loginRequest } from '../../authConfig'
import { callMsPhotoGraph } from 'graph'
import { getStorageLang } from 'utils/common'
import { useParams } from 'react-router-dom'

const LoginPage = () => {
  const { workshopId } = useParams()
  const { i18n } = useTranslation()
  const { instance, accounts } = useMsal()
  const [
    idToken,
    setAccessToken,
    setIdToken,
    setUserImg,
    loginAD,
    updateUserAvatar
  ] = useAuthStore(
    (state) => [
      state.idToken,
      state.setAccessToken,
      state.setIdToken,
      state.setUserImg,
      state.loginAD,
      state.updateUserAvatar
    ]
  )

  const { t } = useTranslation()

  const [isShowWarningDialog, setIsShowWarningDialog] = useState(false)
  const isAuthenticated = useIsAuthenticated()

  const lng = getStorageLang();

  useEffect(() => {
    if (i18n) {
      i18n.changeLanguage(lng);
    }
  }, [i18n, lng])

  const onMouseDownFireEvent = (event) => {
    event.preventDefault()
  }

  const onLoginClick = async (event) => {
    event.preventDefault()

    instance.loginRedirect(loginRequest).catch((e) => {
      // console.log('redirect error: ', e)
    })
  }

  const sendImageProfile = async (img)  =>{
    if (img) {
      await updateUserAvatar(img)
      .catch((err) => {
        console.log('err: ', err)
      })
    }
  }

  const loginWithAD = () => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then(async (response) => {
        setAccessToken(response.accessToken)
        setIdToken(response.idToken)

        await callMsPhotoGraph(response.accessToken)
          .then((res) => {
            setUserImg(res)
            sendImageProfile(res)
          })
          .catch((err) => {
            console.log('err: ', err)
          })

        // call backend login with token
        // const nowPath = window.location.pathname
        // const checkPath = nowPath.includes('/leadership')
        // const result = await loginAD()
        // if (result) {
        //   if(checkPath){
        //     window.location.href = routePaths.LEADER_SHIP;
        //   }else {
        //     window.location.href = routePaths.HOME;
        //   }
        // }
      })
      .catch((err) => {
        console.log('err loginWithAD: ', err)
      })
  }

  async function loginApp() {
    const nowPath = window.location.pathname
    const result = await loginAD()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get("id")
    if (result) {
      if(nowPath.includes('/leadership')){
        window.location.href = routePaths.LEADER_SHIP;
      }
      else if(nowPath.includes('/inspirations/workshops')){
        window.location.href = routePaths.WORKSHOPS + '?id=' + id;
      }
      else if(nowPath.includes('/course/workshop/'+workshopId)){
        window.location.href = routePaths.DYNAMICS_WORKSHOP_BY_ID.substring(0,17)+workshopId.toString();
      }
      else {
        window.location.href = routePaths.HOME;
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      loginWithAD()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated && idToken) {
      loginApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, idToken])

  console.log('isAuthenticated : ', isAuthenticated);

  return (
    <div className="li-container-bg">
      <form className="li-container">
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <img
              className="logo"
              src="/assets/images/me-to-we-logo.png"
              alt="me-to-we"
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <div className="title">{t('welcome')}</div>
            <div className="sub-title mt-20">{t('letsGetStart')}</div>
          </Grid>
        </Grid>

        <div className="mt-90" />

        <br />

        <Grid container>
          <Grid item xs={12} sm={3} md={4}></Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <div>
              <button
                id="btnLogin"
                className="wm-btn"
                onClick={onLoginClick}
                onMouseDown={onMouseDownFireEvent}
              >
                {t('login')}
              </button>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={4}></Grid>
        </Grid>

        <div className="mt-60" />

        <BrandLogo position="center" />

        <NotifyDialog
          id="dialogWarning"
          type="warning"
          title={t('invalidData')}
          bodyText={t('pleaseCorrectData')}
          okText={t('ok')}
          open={isShowWarningDialog}
          onCloseDialog={() => setIsShowWarningDialog(false)}
          onOkDialogClick={() => setIsShowWarningDialog(false)}
        />
      </form>
    </div>
  )
}

export default LoginPage
