import Button from '@mui/material/Button'
import styled, { css } from 'styled-components'

const CustomButton = styled(Button)`
  font-weight: 700;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;

  ${props => props.color === 'primary' && css`
    background: ${({ theme}) => theme?.colors?.primary?.default};
  `}

  ${props => props.center && css`
    display: block;
    margin: 0 auto;
  `}
`;

export default CustomButton
