import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import { gutter } from 'styles/variables';
import  routePaths  from 'routesPath'

const Wrapper = styled.div`
  background-image: url('/assets/images/bg-main-layout-2.png');
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-size: cover;
  text-align: center;
  h2 {
    margin-top: 0;
    padding-top: ${gutter.large};
  }
`;

const BackToHome = styled(Link)`
  text-decoration: none;
  background: ${({ theme}) => theme?.colors?.primary?.default};
  color: #fff;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
`;

const AccessDenied = () => {
  return (
    <Wrapper>
      <h2>Access Denied</h2>
      <BackToHome to={routePaths.HOME}>Back to Home page</BackToHome>
    </Wrapper>
  )
}

export default AccessDenied
