import styled, { css } from 'styled-components';
import { screen, gutter } from 'styles/variables';

export const WrapperAddFuelPage = styled.div`
  position: relative;
  padding: ${gutter.large};
  overflow: hidden;
  min-height: calc(100vh - 115px);
`;

export const WrapperImageProp = styled.div``;

export const ImageKid = styled.img`
  position: absolute;
  bottom: 5%;
  right: 15%;
  width: 35%;
  z-index: 4;

  @media (width: 1368px), (max-height: 912px) { // Surface Pro 7 landscape
    width: 30%;
    bottom: 15%;
    right: 2.5%;
  }

  @media (min-width: ${screen.lg.max}) {
    bottom: 12.5%;
    right: 10%;
    width: 30%;
  }

  @media (max-width: ${screen.md.max}) {
    bottom: 5%;
    right: 15%;
    width: 50%;
  }

  @media (max-width: ${screen.sm.max}) {
    width: 60%;
    right: -5%;
    bottom: 4%;
    width: 55%;
  }

  @media (width: 720px), (height: 540px) { // Surface Duo landscape
    bottom: 3%;
    right: 2.5%;
    width: 35%;
  }

  @media (width: 540px), (height: 720px) { // Surface Duo 
    bottom: 10%;
    right: 2.5%;
    width: 35%;
  }

  @media (width: 912px), (height: 1368px) { // Surface Pro 7
    width: 55%;
    bottom: 3.5%;
    right: 2.5%;
  }

`;

export const ImageCloud = styled.img`
  position: absolute;
  bottom: 15%;
  z-index: 1;

  @media (min-width: ${screen.lg.max}) {
    bottom: 22%;
  }

  @media (max-width: ${screen.md.max}) {
    bottom: 17%;
  }

  @media (max-width: ${screen.sm.max}) {
    bottom: 10%;
    width: 50%;
  }
  
  ${props => props.left && css`
    left: 0;
  `}

  ${props => props.right && css`
    right: 0;
  `}

  @media (width: 720px), (height: 540px) { // Surface Duo landscape
    width: 35%;
  }

  @media (width: 912px), (height: 1368px) { // Surface Pro 7
    bottom: 10%;
  }
`;

export const ImageGround = styled.img`
  position: absolute;
  ${props => props.layer === '1' && css`
    width: 100%;
    bottom: 6.5%;
    left: 0;
    z-index: 2;

    @media (min-width: ${screen.lg.max}) {
      bottom: 12.5%;
    }

    @media (max-width: ${screen.md.max}) {
      bottom: 12%;
    }

    @media (max-width: ${screen.sm.max}) {
      bottom: 8%;
    }

    @media (width: 720px), (height: 540px) { // Surface Duo landscape
      bottom: 5%;
    }

    @media (width: 912px), (height: 1368px) { // Surface Pro 7
      bottom: 10%;
    }
  `}

  ${props => props.layer === '2' && css`
    bottom: -15%;
    left: -30%;
    width: 100%;
    z-index: 3;
    @media (min-width: ${screen.lg.max}) {
      bottom: -15%;
      left: -30%;
    }

    @media (max-width: ${screen.md.max}) {
      bottom: -5%;
      left: -10%;
    }

    @media (max-width: ${screen.sm.max}) {
      left: -10%;
      bottom: -2.5%;
    }

    @media (width: 720px), (height: 540px) { // Surface Duo landscape
      bottom: -15%;
    }

    @media (width: 912px), (height: 1368px) { // Surface Pro 7
      bottom: 0%;
    }
  `}

  ${props => props.layer === '3' && css`
    bottom: -10%;
    right: -30%;
    width: 100%;
    z-index: 5;
    @media (min-width: ${screen.lg.max}) {
      bottom: -5%;
      right: -30%;
    }

    @media (max-width: ${screen.md.max}) {
      bottom: 0%;
      right: -30%;
    }

    @media (max-width: ${screen.sm.max}) {
      right: -20%;
      bottom: 0;
    }

    @media (width: 720px), (height: 540px) { // Surface Duo landscape
      bottom: -15%;
    }

    @media (width: 912px), (height: 1368px) { // Surface Pro 7
      bottom: 0%;
    }
  `}

  ${props => props.layer === '4' && css`
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 6;

    @media (width: 720px), (height: 540px) { // Surface Duo landscape
      bottom: -5%;
    }
  `}
`;

export const WrapperButton = styled.div`
  display: block;
`;

export const ImageBox = styled.img``;

export const ImageCloudMenu = styled.img`
  position: absolute;

  ${props => props.inspiration && css`
    left: -25%;
    top: -100%;
  `}

  ${props => props.elearning && css`
    left: -15%;
    top: -90%;
  `}

  ${props => props.book && css`
    left: 17%;
    top: -40%;
  `}
`;

export const ImageTextMenu = styled.img`
  position: absolute;

  ${props => props.inspiration && css`
    left: -10%;
    top: -100%;
  `}

  ${props => props.elearning && css`
    left: 5%;
    top: -80%;
  `}

  ${props => props.book && css`
    left: 40%;
    top: -30%;
  `}
`;

export const WrapperInspiration = styled.div`
  position: absolute;
  z-index: 5;
  bottom: 15%;
  left: 30%;
  z-index: 6;
  cursor: pointer;

  @media (min-width: ${screen.lg.max}) {
    bottom: 15%;
    left: 35%;
  }

  @media (max-width: ${screen.md.max}) {
    bottom: 15%;
    left: 25%;
  }

  @media (width: 1368px), (max-height: 912px) { // Surface Pro 7 landscape
    bottom: 25%;
    left: 25%;
  }

  @media (max-width: ${screen.sm.max}) {
    bottom: 3.5%;
    left: 20%;
  }

  @media (width: 720px), (height: 540px) { // Surface Duo landscape
    bottom: 0%;
    left: 30%;
  }

  @media (width: 540px), (height: 720px) { // Surface Duo
    bottom: 2.5%;
    left: 20%;
  }

  &:hover {
    .inspiration-box {
      width: 137px;
    }
    .e-learning-box {
      width: 174px;
    }
    .inspiration-text {
      width: 168px;
    }
  }
`;

export const ELearning = styled.div`
  position: absolute;
  z-index: 6;
  bottom: 35%;
  right: 40%;
  cursor: pointer;

  @media (max-width: ${screen.md.max}) {
    bottom: 37%;
    right: 30%;
  }

  @media (max-width: ${screen.sm.max}) {
    bottom: 30%;
    right: 20vw;
  }

  @media (width: 720px), (height: 540px) { // Surface Duo landscape
    bottom: 15%;
    right: 25%;
  }

  &:hover {
    .e-learning-box {
      width: 174px;
    }

    .e-learning-text {
      width: 133px;
    }
  }
`;

export const Books = styled.div`
  position: absolute;
  z-index: 6;
  bottom: 45%;
  left: 25%;
  cursor: pointer;

  @media (min-width: ${screen.lg.max}) {
    bottom: 45%;
    left: 30%;
  }

  @media (max-width: ${screen.md.max}) {
    bottom: 45%;
    left: 15%;
  }

  @media (width: 912px), (height: 1368px) { // Surface Pro 7
    bottom: 50%;
    left: 15%;
  }
  
  @media (width: 1368px), (max-height: 912px) { // Surface Pro 7 landscape
    bottom: 40%;
    left: 5%;
  }

  @media (max-width: ${screen.sm.max}) {
    bottom: 47.5%;
    left: 4.5%;
  }

  @media (width: 720px), (height: 540px) { // Surface Duo landscape
    bottom: 15%;
    left: 5%;
  }

  @media (width: 540px), (height: 720px) { // Surface Duo 
    bottom: 37.5%;
    left: 10%;
  }
  
  &:hover {
    .book-box {
      width: 154px;
    }

    .book-text {
      width: 100px;
    }
  }
`;
