import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRewardStore } from 'stores/rewardStore'
import { useAuthStore } from 'stores/authStore'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid } from '@mui/material'

import SearchField from 'components/SearchField'
import ProfileBox from 'components/ProfileBox'
import CustomButton from 'components/CustomButton'
import NotifyDialog from 'components/NotifyDialog'
import Loading from 'components/Loading'

import './prize-collection.scss'
import ProfileNav from 'components/ProfileNav'

export default function PrizeCollectionPage() {
  const { t, i18n } = useTranslation()
  const [user, fetchUserInfo] = useAuthStore((state) => [
    state.user,
    state.fetchUserInfo,
  ])
  const [rewards, clearRewards, fetchRewards, redeemReward] = useRewardStore(
    (state) => [
      state.rewards,
      state.clearRewards,
      state.fetchRewards,
      state.redeemReward,
    ]
  )
  const [hasMoreData, setHasMoreData] = useState(true)
  const [selectReward, setSelectReward] = useState(null)

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const [isShowSuccessDialog, setIsShowSuccessDialog] = useState(false)
  const [isShowErrorDialog, setIsShowErrorDialog] = useState(false)
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)

  const [paging, setPaging] = useState({ title: '', page: 0, pageSize: 20 })

  useEffect(() => {
    clearRewards()
    onFetchReward(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFetchReward = async (isClearPage = false) => {
    const newPaging = {
      ...paging,
      page: isClearPage ? 1 : paging.page + 1,
      sort: 'created_at desc',
    }

    const result = await fetchRewards(newPaging)
    setHasMoreData(result)
    setPaging(newPaging)
  }

  const onSearchClick = async (e) => {
    e.preventDefault()
    clearRewards()
    await onFetchReward(true)
  }

  const onRedeemClick = (e, reward) => {
    e.preventDefault()
    setSelectReward(reward)
    setIsShowConfirmDialog(true)
  }

  const onConfirmDialogClick = async (e) => {
    e.preventDefault()
    setIsBtnDisabled(true)
    setIsShowConfirmDialog(false)
    const result = await redeemReward(selectReward?.id)

    if (result) {
      clearRewards()
      await Promise.all([fetchUserInfo(), onFetchReward(true)])
      setIsShowSuccessDialog(true)
      setIsBtnDisabled(false)
    } else {
      setIsShowErrorDialog(true)
      setIsBtnDisabled(false)
    }
  }

  return (
    <div className="prize-collection-container">
      <ProfileBox />
      <ProfileNav />
      <SearchField
        id="btnSearch"
        onChange={(e) => setPaging({ ...paging, title: e.target.value })}
        onClick={onSearchClick}
      />

      <InfiniteScroll
        className='wrapper-prize-collection'
        dataLength={rewards.length}
        next={onFetchReward}
        hasMore={hasMoreData}
        loader={<Loading open />}
        // endMessage={
        //   <p style={{ textAlign: 'center' }}>
        //     <b>- {t('dataNotFound')} -</b>
        //   </p>
        // }
      >
        {(rewards || []).map((item, i) => {
          const disableRedeemBtn = isBtnDisabled ? isBtnDisabled : user.balance_points < item.points
          return (
            <div key={i} className="pc-box-container">
              <div className="pc-image-container">
                <img src={item.image} className="pc-image" alt="Gift Voucher" />
              </div>
              <div className="pc-gift-content">
                <div className="pc-gift-title">
                  {item[`name_${i18n.language}`]}
                </div>
                <div className="pc-gift-point">
                  <div>{item.points}</div>
                  <img
                    src={'/assets/images/heart-pink.svg'}
                    alt="Point"
                    className="point-image"
                  />
                  <div className="secondary-text">{`/ ${t('piece')}`}</div>
                </div>

                <Grid container>
                  <Grid item xs={8}>
                    <CustomButton
                      id="btnRedeem"
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={disableRedeemBtn}
                      onClick={(e) => onRedeemClick(e, item)}
                    >
                      {t('redeem')}
                    </CustomButton>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </div>
            </div>
          )
        })}
      </InfiniteScroll>

      <NotifyDialog
        id="dialogAskToConfirmRedeem"
        type="confirmation"
        title={t('doYouWant')}
        bodyText={t('redeemThisPrize')}
        okText={t('confirm')}
        cancelText={t('cancel')}
        iconImgPath={selectReward?.image || '-'}
        open={isShowConfirmDialog}
        onCloseDialog={() => setIsShowConfirmDialog(false)}
        onCancelDialogClick={() => setIsShowConfirmDialog(false)}
        onOkDialogClick={onConfirmDialogClick}
      />

      <NotifyDialog
        id="dialogConfirmRedeem"
        type="success"
        title={t('requestSentSuccessfully')}
        bodyText={t('adminDoingDelivery')}
        okText={t('ok_text')}
        iconImgPath={selectReward?.image || '-'}
        open={isShowSuccessDialog}
        onCloseDialog={() => setIsShowSuccessDialog(false)}
        onOkDialogClick={() => setIsShowSuccessDialog(false)}
      />

      <NotifyDialog
        id="dialogError"
        type="error"
        title={t('requestSentFail')}
        bodyText={t('systemError')}
        okText={t('confirm')}
        open={isShowErrorDialog}
        onCloseDialog={() => setIsShowErrorDialog(false)}
        onOkDialogClick={() => setIsShowErrorDialog(false)}
      />
    </div>
  )
}
