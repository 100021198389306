const variables = {
  typography: {
    fontFamily: [
      'Poppins',
      'IBMPlexSansThai',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  }
}

export default variables;
