import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import routePaths from 'routesPath'
import {
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from '@mui/material'

import MenuBookIcon from '@mui/icons-material/MenuBook'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import CastForEducationIcon from '@mui/icons-material/CastForEducation'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import StarsIcon from '@mui/icons-material/Stars';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction'
import RedeemIcon from '@mui/icons-material/Redeem'
import InventoryIcon from '@mui/icons-material/Inventory'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import ReportIcon from '@mui/icons-material/Summarize'
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

import './main-layout.scss'
import { useAuthStore } from 'stores/authStore'
import { useBoMenuStore } from 'stores/BackOffice/boMenuStore'

export default  function SideBar({ isOpen, onMenuItemClick, toggle, dataList }) {
  const defaultGroup = 'DEFAULT'
  const emiGroup = 'EMI'
  const admin = 'ADMIN'
  const { user, hasRole } =  useAuthStore()
  const { list, fetchMenu } = useBoMenuStore()
  const [listMenu, setListMenu] = useState([])
  const userInfo = Object.assign({}, user.user_group)
  const iconProps = {
    style: { color: '#0b2b92' },
    fontSize: 'small',
  }
  let menuList = [
    {
      id: 98,
      open: false,
      name: 'Book',
      to: null,
      icon: <MenuBookIcon {...iconProps} />,
      group_name: '',
      role_permission: '',
      isDevide: true,
      childen: [
        {
          name: 'Book Master',
          to: routePaths.BO_BOOK,
          icon: <MenuBookIcon {...iconProps} />,
          group_name: '',
          role_permission: ''
        },
        {
          name: 'Book Request',
          to: routePaths.BO_BOOK_REQUEST,
          icon: <LibraryBooksIcon {...iconProps} />,
          group_name: '',
          role_permission: '',
        },
      ]
    },
    {
      id: 100,
      open: false,
      name: 'E-Learning',
      to: routePaths.BO_ELEARNING,
      icon: <CastForEducationIcon {...iconProps} />,
      group_name: '',
      role_permission: '',
      isDevide: true,
    },
    {
      id: 9,
      open: false,
      name: 'Inspiration',
      to: null,
      icon: <TipsAndUpdatesIcon {...iconProps} />,
      group_name: defaultGroup,
      role_permission: admin,
      isDevide: true,
      childen: [
        {
          name: 'Inspiration Course',
          to: routePaths.BO_INSPIRATION_COURSE,
          icon: <TipsAndUpdatesIcon {...iconProps} />,
          group_name: defaultGroup,
          role_permission: admin
        },
        {
          name: 'Inspiration Workshop',
          to: routePaths.BO_INSPIRATION_WORKSHOP,
          icon: <EmojiObjectsIcon {...iconProps} />,
          group_name: defaultGroup,
          role_permission: admin
        },
        {
          name: 'Inspiration Request',
          to: routePaths.BO_INSPIRATION_REQUEST,
          icon: <BatchPredictionIcon {...iconProps} />,
          group_name: defaultGroup,
          role_permission: admin,
        },
      ]
    },
    {
      id: 3,
      open: false,
      name: 'Emi ',
      to: null,
      icon: <TipsAndUpdatesIcon {...iconProps} />,
      group_name: emiGroup,
      role_permission: admin,
      isDevide:true,
      childen: [
        {
          name: 'Emi Course',
          to: routePaths.BO_EMI_COURSE,
          icon: <TipsAndUpdatesIcon {...iconProps} />,
          group_name: emiGroup,
          role_permission: admin
        },
        {
          name: 'Emi Workshop',
          to: routePaths.BO_EMI_WORKSHOP,
          icon: <EmojiObjectsIcon {...iconProps} />,
          group_name: emiGroup,
          role_permission: admin
        },
        {
          name: 'Emi Request',
          to: routePaths.BO_EMI_REQUEST,
          icon: <BatchPredictionIcon {...iconProps} />,
          group_name: emiGroup,
          role_permission: admin,
        },
      ]
    },
    {
      id: 99,
      open: false,
      name: 'Leadership',
      to: null,
      icon: <TipsAndUpdatesIcon {...iconProps} />,
      group_name: emiGroup,
      role_permission: admin,
      isDevide:true,
      childen: [
        {
          name: 'Leader Workshop',
          to: routePaths.BO_LEADER_WORKSHOP,
          icon: <EmojiObjectsIcon {...iconProps} />,
          group_name: emiGroup,
          role_permission: admin
        },
        {
          name: 'Leader Request',
          to: routePaths.BO_LEADER_REQUEST,
          icon: <BatchPredictionIcon {...iconProps} />,
          group_name: emiGroup,
          role_permission: admin,
        },
      ]
    },
    {
      id: 4,
      open: false,
      name: 'Reward ',
      to: null,
      icon: <TipsAndUpdatesIcon {...iconProps} />,
      group_name: emiGroup,
      role_permission: admin,
      isDevide:true,
      childen: [
        {
          name: 'Reward Master',
          to: routePaths.BO_REWARD,
          icon: <RedeemIcon {...iconProps} />,
          group_name: '',
          role_permission: ''
        },
        {
          name: 'Reward Redemption',
          to: routePaths.BO_REWARD_REDEMPTION,
          icon: <InventoryIcon {...iconProps} />,
          group_name: '',
          role_permission: '',
          isDevide: true
        },
      ]
    },
    {
      id: 5,
      open: false,
      name: 'White List',
      to: routePaths.BO_WHITELIST,
      icon: <FactCheckIcon {...iconProps} />,
      group_name: defaultGroup,
      role_permission: admin,
      isDevide: true
    },
    {
      id: 6,
      open: false,
      name: 'Point Transfer',
      to: routePaths.BO_POINT_TRANSFER,
      icon: <StarsIcon {...iconProps} />,
      group_name: defaultGroup,
      role_permission: admin,
      isDevide: true
    },
    {
      id: 7,
      open: false,
      name: 'Report',
      to: routePaths.BO_REPORT,
      icon: <ReportIcon {...iconProps} />,
      group_name: defaultGroup,
      role_permission: admin,
      isDevide: true
    },
    {
      id: 8,
      open: false,
      name: 'Community ',
      to: null,
      icon: <ReportIcon {...iconProps} />,
      group_name: defaultGroup,
      role_permission: admin,
      isDevide:true,
      childen: [
        {
          name: 'Forbidden Words',
          to: routePaths.BO_COMMUNITY_FORBIDDEN_WORD,
          icon: <ReportIcon {...iconProps} />,
          group_name: defaultGroup,
          role_permission: admin
        },
        {
          name: 'Hashtag',
          to: routePaths.BO_COMMUNITY_HASHTAG,
          icon: <ReportIcon {...iconProps} />,
          group_name: defaultGroup,
          role_permission: admin
        }
      ]
    },
  ]
  menuList  = menuList.concat(list)
  const dataForset = menuList
  useEffect(()=>{
    fetchMenu()
    setListMenu(dataForset)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const handleClick = (id) => {
    setListMenu(menuList?.map((item) =>
        item?.id === id ? { ...item, open: !item?.open } : item
      )
    )
  };
  useEffect(() => {
    return () => {}
  }, []);
  return (
    <div className="bo-sidebar-container">
      {isOpen && (
        <ClickAwayListener onClickAway={toggle}>
          <MenuList
            name="menu"
            id="composition-menu"
            className="bo-sidebar-open"
          >
            {Array.isArray(menuList) && menuList?.map((menu, i) => {
              return (
                <div key={i}>
                  <div>
                    { (hasRole(admin) && userInfo.group_name === defaultGroup) ? <>
                      <MenuItem
                          key={menu?.id}
                          component={RouterLink}
                          to={menu?.to}
                          onClick={() => handleClick(menu?.id)}
                        >
                        <ListItemIcon>{menu?.icon || <TipsAndUpdatesIcon {...iconProps} />}</ListItemIcon>
                        <ListItemIcon>{menu?.name}</ListItemIcon>
                        {menu?.childen && <div className='iconCollapse'>
                          {!listMenu?.find(item => item?.id === menu?.id)?.open ? <ExpandMore />: <ExpandLess />}
                        </div>}
                      </MenuItem>
                      </> :
                      (menu?.group_name === '' || menu?.group_name === userInfo?.group_name) && <>
                        <MenuItem
                          key={menu?.id}
                          component={RouterLink}
                          to={menu?.to}
                          onClick={() => handleClick(menu?.id)}
                        >
                          <ListItemIcon>{menu?.icon}</ListItemIcon>
                          <ListItemText>{menu?.name}</ListItemText>
                          {menu?.childen && <div className='iconCollapse'>
                          {!listMenu?.find(item => item?.id === menu?.id)?.open ? <ExpandMore />: <ExpandLess />}
                        </div>}
                        </MenuItem>
                      </>}
                    </div>
                        <Collapse className='colllapse-container'  in={listMenu?.find(item => item?.id === menu?.id)?.open} timeout="auto" unmountOnExit>
                          {Array.isArray(menu?.childen) && menu?.childen?.map((child)=> {
                            return (
                              <div key={i}>
                                <MenuItem
                                  disablePadding
                                  component={RouterLink}
                                  to={child?.to}
                                >
                                  <ListItemIcon></ListItemIcon>
                                  <ListItemText>{child?.name}</ListItemText>
                                </MenuItem>
                            </div>
                            )
                          })}
                          </Collapse>
                     {menu?.isDevide && <Divider />}
                </div>
              )
            })}
          </MenuList>
        </ClickAwayListener>
      )}
    </div>
  )
}
