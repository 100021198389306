export const initColumnsEmi = [
    {
      id: 'no',
      label: 'No.',
      minWidth: 30,
    },
    {
      id: 'session_th',
      label: 'Course (TH)',
      minWidth: 100,
    },
    {
      id: 'session_en',
      label: 'Course (EN)',
      minWidth: 100,
    },
    {
      id: 'event_date',
      label: 'Event Date',
      minWidth: 120,
    },
    {
      id: 'round',
      label: 'Event Time',
      minWidth: 100,
    },
    {
      id: 'usename',
      label: 'User Name',
      minWidth: 120,
    },
    {
      id: 'user_email',
      label: 'User Email',
      minWidth: 120,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
    },
    {
      id: 'approvescore',
      label: 'Approve Score',
      minWidth: 100,
    },
    {
      id: 'request_date',
      label: 'Request Date',
      minWidth: 150,
    },
  ]
export const initPaging = {
    user_name: '',
    user_email: '',
    course_title: '',
    workshop_title: '',
    event_date: '',
    request_date_start: '',
    request_date_end: '',
    page: 1,
    pageSize: 5,
  }
export const txtProps = {
    size: 'small',
    fullWidth: true,
    sx: { backgroundColor: '#fff' },
  }
